/* stylelint-disable */
@charset 'UTF-8';

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: 'Source Sans Pro', sans-serif;
}

body .p-component {
  font-family: 'Source Sans Pro', 'Helvetica Neue', sans-serif;
  font-size: toRem(13px);
}

body .p-corner-all {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

body .p-corner-top {
  border-radius: 3px 0 0 0;
}

body .p-corner-bottom {
  border-radius: 0 0 3px 0;
}

body .p-corner-left {
  border-radius: 0 0 0 3px;
}

body .p-corner-right {
  border-radius: 0 3px 0 0;
}

body .p-corner-none {
  border-radius: 0;
}

body a {
  color: var(--cap-primary-color);
  text-decoration: none;
}

body a:hover {
  color: var(--cap-primary-color);
}

body a:active {
  color: var(--cap-primary-color);
}

body .p-reset {
  line-height: normal;
}

body .p-disabled, body .p-component:disabled {
  opacity: 0.5;
  background: var(--third-color);
  filter: alpha(opacity=50);
  box-shadow: 0 2px 4px 0 var(--third-color);
}

body .pi {
  font-size: 1.25em;
}

body {
  /* Validations */
}

body .p-inputtext {
  font-size: toRem(14px);
  color: var(--primary-text);
  background: $cap-white;
  padding: 0.429em;
  border: 1px solid #a6a6a6;
  -moz-transition: border-color 0.2s;
  -o-transition: border-color 0.2s;
  -webkit-transition: border-color 0.2s;
  transition: border-color 0.2s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

body .p-radiobutton {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  width: 20px;
  height: 20px;
}

body .p-radiobutton .p-radiobutton-box {
  border: 1px solid #a6a6a6;
  background-color: $cap-white;
  width: 20px;
  height: 20px;
  text-align: center;
  position: relative;
  -moz-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

body .p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: var(--text-toast);
}

body .p-radiobutton .p-radiobutton-box.p-focus {
  border-color: var(--cap-primary-color);
  background-color: $cap-white;
  color: var(--cap-primary-color);
  -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
  -moz-box-shadow: 0 0 0 0.2em #8dcdff;
  box-shadow: 0 0 0 0.2em #8dcdff;
}

body .p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: var(--cap-primary-color);
  background-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-radiobutton .p-radiobutton-box.p-highlight .p-radiobutton-icon {
  background-color: $cap-white;
}

body .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: var(--cap-primary-color);
  background-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-radiobutton .p-radiobutton-box.p-highlight.p-focus {
  border-color: var(--cap-primary-color);
  background-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  background: transparent;
  width: 10px;
  height: 10px;
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -5px;
  margin-top: -5px;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

body .p-radiobutton .p-radiobutton-box .p-radiobutton-icon:before {
  display: none;
}

body .p-radiobutton-label {
  margin: 0 0 0 0.5em;
}

body .p-inputswitch {
  width: 3em;
  height: 1.75em;
}

body .p-inputswitch .p-inputswitch-slider {
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
  border-radius: 30px;
  background: #cccccc;
}

body .p-inputswitch .p-inputswitch-slider:before {
  background-color: #fff;
  height: 1.25em;
  width: 1.25em;
  left: 0.25em;
  bottom: 0.25em;
  border-radius: 50%;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

body .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  -webkit-transform: translateX(1.25em);
  -ms-transform: translateX(1.25em);
  transform: translateX(1.25em);
}

body .p-inputswitch.p-inputswitch-focus .p-inputswitch-slider {
  background: #b7b7b7;
}

body .p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
  background-color: #b7b7b7;
}

body .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background-color: var(--cap-primary-color);
}

body .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background-color: var(--cap-primary-color);
}

body .p-inputswitch.p-inputswitch-checked.p-inputswitch-focus .p-inputswitch-slider {
  background: var(--cap-primary-color);
}

body .p-autocomplete .p-autocomplete-input {
  padding: 0.429em;
}

body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container {
  padding: 0.2145em 0.429em;
}

body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container:not(.p-disabled):hover {
  border-color: var(--text-toast);
}

body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
  border-color: var(--cap-primary-color);
  outline: 0 none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-input-token {
  margin: 0;
  padding: 0.2145em 0;
  color: var(--primary-text);
}

body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-input-token input {
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: toRem(14px);
  padding: 0;
  margin: 0;
}

body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-token {
  font-size: toRem(14px);
  padding: 0.2145em 0.429em;
  margin: 0 0.286em 0 0;
  background: var(--cap-primary-color);
  color: $cap-white;
}

body .p-autocomplete-panel {
  padding: 0;
  border: 1px solid #c8c8c8;
  background-color: $cap-white;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-autocomplete-panel .p-autocomplete-items {
  padding: 0;
}

body .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-list-item {
  margin: 0;
  padding: 0.429em 0.857em;
  border: 0 none;
  color: var(--primary-text);
  background-color: transparent;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

body .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-list-item.p-highlight {
  color: $cap-white;
  background-color: var(--cap-primary-color);
}

body .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-group {
  padding: 0.429em 0.857em;
  background-color: #d8dae2;
  color: var(--primary-text);
}

body .p-fluid .p-autocomplete .p-autocomplete-dropdown.p-button {
  width: 2.357em;
}

body .p-fluid .p-autocomplete.p-autocomplete-multiple.p-autocomplete-dd .p-autocomplete-multiple-container {
  border-right: 0 none;
  width: calc(100% - 2.357em);
}

body .p-fluid .p-autocomplete.p-autocomplete-dd .p-inputtext {
  border-right: 0 none;
  width: calc(100% - 2.357em);
}

body .p-chips > ul.p-inputtext {
  padding: 0.2145em 0.429em;
  display: inline-block;
}

body .p-chips > ul.p-inputtext:not(.p-disabled).p-focus {
  outline: 0 none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .p-chips > ul.p-inputtext .p-chips-input-token {
  padding: 0.2145em 0;
}

body .p-chips > ul.p-inputtext .p-chips-input-token input {
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: toRem(14px);
  padding: 0;
  margin: 0;
  color: var(--primary-text);
}

body .p-chips > ul.p-inputtext .p-chips-input-token input:hover {
  border: 0 none;
}

body .p-chips > ul.p-inputtext .p-chips-input-token input:focus {
  border: 0 none;
}

body .p-chips > ul.p-inputtext .p-chips-token {
  font-size: toRem(14px);
  padding: 0.2145em 0.429em;
  margin: 0 0.286em 0 0;
  background: var(--cap-primary-color);
  color: $cap-white;
}

body .p-multiselect {
  background: $cap-white;
  border: 1px solid #a6a6a6;
  -moz-transition: border-color 0.2s;
  -o-transition: border-color 0.2s;
  -webkit-transition: border-color 0.2s;
  transition: border-color 0.2s;
}

body .p-multiselect:not(.p-disabled):hover {
  border-color: var(--text-toast);
}

body .p-multiselect:not(.p-disabled).p-focus {
  border-color: var(--cap-primary-color);
  outline: 0 none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .p-multiselect .p-multiselect-label {
  padding: 0.429em;
  padding-right: 2em;
  color: var(--primary-text);
}

body .p-multiselect .p-multiselect-trigger {
  background-color: $cap-white;
  width: 2em;
  line-height: 2em;
  text-align: center;
  padding: 0;
  color: #848484;
}

body .p-multiselect-panel {
  padding: 0;
  border: 1px solid #c8c8c8;
  background-color: $cap-white;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-multiselect-panel .p-multiselect-header {
  padding: 0.429em 0.857em 0.429em 0.857em;
  border-bottom: 1px solid #eaeaea;
  color: var(--primary-text);
  background-color: $cap-white;
  margin: 0;
}

body .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container {
  float: none;
  width: 70%;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0;
}

body .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
  padding: 0.429em;
  padding-right: 2em;
}

body .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
  color: var(--cap-primary-color);
  top: 50%;
  margin-top: -0.5em;
  right: 0.5em;
  left: auto;
}

body .p-multiselect-panel .p-multiselect-header .p-checkbox {
  margin-right: 0.5em;
  float: none;
  vertical-align: middle;
}

body .p-multiselect-panel .p-multiselect-header .p-multiselect-close {
  color: #848484;
  top: 50%;
  margin-top: -0.5em;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}

body .p-multiselect-panel .p-multiselect-header .p-multiselect-close:hover {
  color: var(--primary-text);
}

body .p-multiselect-panel .p-multiselect-items {
  padding: 0;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  margin: 0;
  padding: 0.429em 0.857em;
  border: 0 none;
  color: var(--primary-text);
  background-color: transparent;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: var(--primary-text);
  background-color: transparent;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: var(--primary-text);
  background-color: #eaeaea;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-disabled .p-checkbox-box {
  cursor: auto;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-disabled .p-checkbox-box:hover {
  border: 1px solid #a6a6a6;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-disabled .p-checkbox-box.p-highlight:hover {
  border-color: var(--cap-primary-color);
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
  position: static;
  display: inline-block;
  vertical-align: middle;
  margin: 0 0.5em 0 0;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item label {
  display: inline-block;
  vertical-align: middle;
}

body .p-listbox {
  padding: 0;
  min-width: 12em;
  background: $cap-white;
  border: 1px solid #a6a6a6;
}

body .p-listbox .p-checkbox {
  margin: 0 0.5em 0 0;
}

body .p-listbox .p-listbox-header {
  padding: 0.429em 0.857em 0.429em 0.857em;
  border-bottom: 1px solid #eaeaea;
  color: var(--primary-text);
  background-color: $cap-white;
  margin: 0;
}

body .p-listbox .p-listbox-header .p-listbox-filter-container {
  width: calc(100% - (0.857em + 0.857em + 0.5em));
}

body .p-listbox .p-listbox-header .p-listbox-filter-container input {
  padding: 0.429em;
  padding-right: 2em;
}

body .p-listbox .p-listbox-header .p-listbox-filter-container .p-listbox-filter-icon {
  top: 50%;
  left: auto;
  margin-top: -0.5em;
  right: 0.5em;
  color: var(--cap-primary-color);
}

body .p-listbox .p-listbox-list {
  background-color: $cap-white;
}

body .p-listbox .p-listbox-list .p-listbox-item {
  margin: 0;
  padding: 0.429em 0.857em;
  border: 0 none;
  color: var(--primary-text);
  background-color: transparent;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

body .p-listbox .p-listbox-list .p-listbox-item.p-highlight {
  color: $cap-white;
  background-color: var(--cap-primary-color);
}

body .p-listbox .p-listbox-list .p-listbox-item .p-checkbox {
  position: static;
  display: inline-block;
  vertical-align: middle;
  margin: 0 0.5em 0 0;
}

body .p-listbox .p-listbox-list .p-listbox-item label {
  display: inline-block;
  vertical-align: middle;
}

body .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
  color: var(--primary-text);
  background-color: #eaeaea;
}

body .p-listbox.p-disabled .p-checkbox-box:not(.p-disabled):not(.p-highlight):hover {
  border: 1px solid #a6a6a6;
}

body .p-listbox .p-listbox-footer {
  padding: 0.429em 0.857em 0.429em 0.857em;
  border-top: 1px solid #eaeaea;
  color: var(--primary-text);
  background-color: $cap-white;
}

body .p-editor-container .p-editor-toolbar {
  border: 1px solid #c8c8c8;
  background-color: #f4f4f4;
}

body .p-editor-container .p-editor-content {
  border: 1px solid #c8c8c8;
}

body .p-editor-container .p-editor-content .ql-editor {
  background-color: $cap-white;
  color: var(--primary-text);
}

body .p-editor-container .ql-picker.ql-expanded .ql-picker-label {
  color: var(--primary-text);
}

body .p-editor-container .ql-stroke {
  stroke: var(--primary-text);
}

body .p-editor-container .ql-picker-label {
  color: var(--primary-text);
}

body .p-editor-container .ql-snow.ql-toolbar button:hover,
body .p-editor-container .ql-snow .ql-toolbar button:hover,
body .p-editor-container .ql-snow.ql-toolbar button.ql-active,
body .p-editor-container .ql-snow .ql-toolbar button.ql-active,
body .p-editor-container .ql-snow.ql-toolbar .ql-picker-label:hover,
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-label:hover,
body .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-label.ql-active,
body .p-editor-container .ql-snow.ql-toolbar .ql-picker-item:hover,
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-item:hover,
body .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: var(--cap-primary-color);
}

body .p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
body .p-editor-container .ql-snow .ql-toolbar button:hover .ql-stroke,
body .p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
body .p-editor-container .ql-snow .ql-toolbar button.ql-active .ql-stroke,
body .p-editor-container .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
body .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
body .p-editor-container .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
body .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
body .p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
body .p-editor-container .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
body .p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
body .p-editor-container .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
body .p-editor-container .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
body .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
body .p-editor-container .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
body .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: var(--cap-primary-color);
}

body .p-rating a {
  text-align: center;
  display: inline-block;
  color: var(--primary-text);
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}

body .p-rating a.p-rating-cancel {
  color: #e4018d;
}

body .p-rating a .p-rating-icon {
  font-size: toRem(20px);
}

body .p-rating:not(.p-disabled):not(.p-rating-readonly) a:hover {
  color: var(--cap-primary-color);
}

body .p-rating:not(.p-disabled):not(.p-rating-readonly) a.p-rating-cancel:hover {
  color: #b5019f;
}

body .p-spinner .p-spinner-input {
  padding-right: 2.429em;
}

body .p-spinner .p-spinner-button {
  width: 2em;
}

body .p-spinner .p-spinner-button.p-spinner-up {
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
}

body .p-spinner .p-spinner-button.p-spinner-down {
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

body .p-spinner .p-spinner-button .p-spinner-button-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -0.5em;
  margin-left: -0.5em;
  width: 1em;
}

body .p-fluid .p-spinner .p-spinner-input {
  padding-right: 2.429em;
}

body .p-fluid .p-spinner .p-spinner-button {
  width: 2em;
}

body .p-fluid .p-spinner .p-spinner-button .p-spinner-button-icon {
  left: 50%;
}

body .p-slider {
  background-color: #c8c8c8;
  border: 0 none;
}

body .p-slider.p-slider-horizontal {
  height: 0.286em;
}

body .p-slider.p-slider-horizontal .p-slider-handle {
  top: 50%;
  margin-top: -0.5715em;
}

body .p-slider.p-slider-vertical {
  width: 0.286em;
}

body .p-slider.p-slider-vertical .p-slider-handle {
  left: 50%;
  margin-left: -0.5715em;
}

body .p-slider .p-slider-handle {
  height: 1.143em;
  width: 1.143em;
  background-color: $cap-white;
  border: 2px solid #666;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  -moz-transition: border-color 0.2s;
  -o-transition: border-color 0.2s;
  -webkit-transition: border-color 0.2s;
  transition: border-color 0.2s;
}

body .p-slider .p-slider-range {
  background-color: var(--cap-primary-color);
}

body .p-slider:not(.p-disabled) .p-slider-handle:hover {
  background-color: 2px solid #666;
  border: 2px solid var(--cap-primary-color);
}

body .p-datepicker {
  padding: 0.857em;
  min-width: 20em;
  background-color: $cap-white;
  color: var(--primary-text);
  border: 1px solid #a6a6a6;
}

body .p-datepicker:not(.p-datepicker-inline) {
  border: 1px solid #c8c8c8;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-datepicker:not(.p-disabled) .p-datepicker-header .p-datepicker-prev:hover,
body .p-datepicker:not(.p-disabled) .p-datepicker-header .p-datepicker-next:hover {
  color: var(--cap-primary-color);
}

body .p-datepicker:not(.p-disabled) .p-datepicker-header .p-datepicker-prev:focus,
body .p-datepicker:not(.p-disabled) .p-datepicker-header .p-datepicker-next:focus {
  outline: 0 none;
  color: var(--cap-primary-color);
}

body .p-datepicker:not(.p-disabled) table td a:not(.p-highlight):not(.p-highlight):hover {
  background-color: #eaeaea;
}

body .p-datepicker:not(.p-disabled) .p-monthpicker a.p-monthpicker-month:not(.p-highlight):hover {
  background-color: #eaeaea;
}

body .p-datepicker .p-datepicker-header {
  padding: 0.429em 0.857em 0.429em 0.857em;
  background-color: $cap-white;
  color: var(--primary-text);
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

body .p-datepicker .p-datepicker-header .p-datepicker-prev,
body .p-datepicker .p-datepicker-header .p-datepicker-next {
  cursor: pointer;
  top: 0;
  color: #a6a6a6;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}

body .p-datepicker .p-datepicker-header .p-datepicker-title {
  margin: 0;
  padding: 0;
  line-height: 1;
}

body .p-datepicker .p-datepicker-header .p-datepicker-title select {
  margin-top: -0.35em;
  margin-bottom: 0;
}

body .p-datepicker table {
  font-size: toRem(14px);
  margin: 0.857em 0 0 0;
}

body .p-datepicker table th {
  padding: 0.5em;
}

body .p-datepicker table td > a,
body .p-datepicker table td > span {
  display: block;
  text-align: center;
  color: var(--primary-text);
  padding: 0.5em;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

body .p-datepicker table td > a.p-highlight,
body .p-datepicker table td > span.p-highlight {
  color: $cap-white;
  background-color: var(--cap-primary-color);
}

body .p-datepicker table td > a {
  cursor: pointer;
}

body .p-datepicker table td.p-datepicker-today > a, body .p-datepicker table td.p-datepicker-today > span {
  background-color: #d0d0d0;
  color: var(--primary-text);
}

body .p-datepicker table td.p-datepicker-today > a.p-highlight, body .p-datepicker table td.p-datepicker-today > span.p-highlight {
  color: $cap-white;
  background-color: var(--cap-primary-color);
}

body .p-datepicker .p-datepicker-buttonbar {
  border-top: 1px solid #d8dae2;
}

body .p-datepicker .p-timepicker {
  border: 0 none;
  border-top: 1px solid #d8dae2;
  padding: 0.857em;
}

body .p-datepicker .p-timepicker a {
  color: var(--primary-text);
  font-size: 1.286em;
}

body .p-datepicker .p-timepicker a:hover {
  color: var(--cap-primary-color);
}

body .p-datepicker .p-timepicker span {
  font-size: 1.286em;
}

body .p-datepicker .p-monthpicker .p-monthpicker-month {
  color: var(--primary-text);
}

body .p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
  color: $cap-white;
  background-color: var(--cap-primary-color);
}

body .p-datepicker.p-datepicker-timeonly {
  padding: 0;
}

body .p-datepicker.p-datepicker-timeonly .p-timepicker {
  border-top: 0 none;
}

body .p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
  border-right: 1px solid #d8dae2;
  padding-right: 0.857em;
  padding-left: 0.857em;
  padding-top: 0;
  padding-bottom: 0;
}

body .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
  padding-left: 0;
}

body .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
  padding-right: 0;
  border-right: 0 none;
}

body .p-fileupload .p-fileupload-buttonbar {
  background-color: #f4f4f4;
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  color: var(--primary-text);
  border-bottom: 0 none;
}

body .p-fileupload .p-fileupload-buttonbar .p-button {
  margin-right: 8px;
}

body .p-fileupload .p-fileupload-content {
  background-color: $cap-white;
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  color: var(--primary-text);
}

body .p-fileupload .p-progressbar {
  top: 0;
}

body .p-fileupload-choose:not(.p-disabled):hover {
  background-color: var(--cap-primary-color);
  color: $cap-white;
  border-color: var(--cap-primary-color);
}

body .p-fileupload-choose:not(.p-disabled):active {
  background-color: var(--cap-primary-color);
  color: $cap-white;
  border-color: var(--cap-primary-color);
}

body .p-fileupload-choose.p-focus {
  outline: 0 none;
  outline-offset: 0px;
}

body .p-password-panel {
  padding: 12px;
  background-color: $cap-white;
  color: var(--primary-text);
  border: 1px solid #c8c8c8;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-inputgroup .p-inputgroup-addon {
  border-color: #a6a6a6;
  background-color: #eaeaea;
  color: #848484;
  padding: 0.429em;
  min-width: 2em;
}

body .p-inputgroup .p-inputgroup-addon:first-child {
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

body .p-inputgroup .p-inputgroup-addon:last-child {
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

body .p-inputgroup .p-inputgroup-addon.p-inputgroup-addon-checkbox {
  position: relative;
}

body .p-inputgroup .p-inputgroup-addon.p-inputgroup-addon-checkbox .p-checkbox {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -10px;
  margin-top: -10px;
}

body .p-inputgroup .p-inputgroup-addon.p-inputgroup-addon-radiobutton {
  position: relative;
}

body .p-inputgroup .p-inputgroup-addon.p-inputgroup-addon-radiobutton .p-radiobutton {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -10px;
  margin-top: -10px;
}

body .p-inputgroup .p-button:first-child {
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

body .p-inputgroup .p-button:last-child {
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

body .p-fluid .p-inputgroup .p-button {
  width: auto;
}

body .p-fluid .p-inputgroup .p-button.p-button-icon-only {
  width: 2.357em;
}

body ::-webkit-input-placeholder {
  color: #666;
}

body :-moz-placeholder {
  color: #666;
}

body ::-moz-placeholder {
  color: #666;
}

body :-ms-input-placeholder {
  color: #666;
}

body .p-fluid .p-button-icon-only {
  width: 2.357em;
}

body .p-togglebutton {
  background-color: #dadada;
  border: 1px solid #dadada;
  color: var(--primary-text);
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s;
}

body .p-togglebutton .p-button-icon-left {
  color: #666;
}

body .p-togglebutton:not(.p-disabled):not(.p-highlight):hover {
  background-color: #c8c8c8;
  border-color: #c8c8c8;
  color: var(--primary-text);
}

body .p-togglebutton:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left {
  color: var(--text-toast);
}

body .p-togglebutton:not(.p-disabled):not(.p-highlight).p-focus {
  background-color: #c8c8c8;
  border-color: #c8c8c8;
  color: var(--primary-text);
  outline: 0 none;
}

body .p-togglebutton:not(.p-disabled):not(.p-highlight).p-focus .p-button-icon-left {
  color: var(--text-toast);
}

body .p-togglebutton.p-highlight {
  background-color: var(--cap-primary-color);
  border-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-togglebutton.p-highlight .p-button-icon-left {
  color: $cap-white;
}

body .p-togglebutton.p-highlight:hover {
  background-color: var(--cap-primary-color);
  border-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-togglebutton.p-highlight:hover .p-button-icon-left {
  color: $cap-white;
}

body .p-togglebutton.p-highlight.p-focus {
  background-color: var(--cap-primary-color);
  border-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-togglebutton.p-highlight.p-focus .p-button-icon-left {
  color: $cap-white;
}

body .p-selectbutton .p-button {
  background-color: #dadada;
  border: 1px solid #dadada;
  color: var(--primary-text);
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s;
}

body .p-selectbutton .p-button .p-button-icon-left {
  color: #666;
}

body .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
  background-color: #c8c8c8;
  border-color: #c8c8c8;
  color: var(--primary-text);
}

body .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left {
  color: var(--text-toast);
}

body .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight).p-focus {
  background-color: #c8c8c8;
  border-color: #c8c8c8;
  color: var(--primary-text);
  outline: 0 none;
}

body .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight).p-focus .p-button-icon-left {
  color: var(--text-toast);
}

body .p-selectbutton .p-button.p-highlight {
  background-color: var(--cap-primary-color);
  border-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-selectbutton .p-button.p-highlight .p-button-icon-left {
  color: $cap-white;
}

body .p-selectbutton .p-button.p-highlight:not(.p-disabled):hover {
  background-color: var(--cap-primary-color);
  border-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-selectbutton .p-button.p-highlight:not(.p-disabled):hover .p-button-icon-left {
  color: $cap-white;
}

body .p-selectbutton .p-button.p-highlight.p-focus {
  background-color: var(--cap-primary-color);
  border-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-selectbutton .p-button.p-highlight.p-focus .p-button-icon-left {
  color: $cap-white;
}

body .p-selectbutton .p-button:first-child {
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

body .p-selectbutton .p-button:last-child {
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

@media (max-width: 640px) {
  body .p-buttonset:not(.p-splitbutton) .p-button {
    margin-bottom: 1px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
  }
}

body .p-splitbutton.p-buttonset .p-button {
  border: 1px solid transparent;
}

body .p-splitbutton.p-buttonset .p-menu {
  min-width: 100%;
}

body .p-widget-content p {
  line-height: 1.5;
  margin: 0;
}

body .p-panel {
  padding: 0;
  border: 0 none;
}

body .p-panel .p-panel-titlebar {
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  background-color: #f4f4f4;
  color: var(--primary-text);
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -moz-border-radius-bottomleft: 0;
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
  -moz-border-radius-bottomright: 0;
  -webkit-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0;
}

body .p-panel .p-panel-titlebar .p-panel-title {
  vertical-align: middle;
  font-weight: 700;
}

body .p-panel .p-panel-titlebar .p-panel-titlebar-icon {
  margin: 0;
  position: relative;
  font-size: toRem(14px);
  color: #848484;
  border: 1px solid transparent;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}

body .p-panel .p-panel-titlebar .p-panel-titlebar-icon:hover {
  color: var(--primary-text);
}

body .p-panel .p-panel-content {
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  background-color: $cap-white;
  color: var(--primary-text);
  padding: 0.571em 1em;
  border-top: 0 none;
}

body .p-panel .p-panel-footer {
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  background-color: $cap-white;
  color: var(--primary-text);
  border-top: 0 none;
  margin: 0;
}

body .p-fieldset {
  border: 1px solid #c8c8c8;
  background-color: $cap-white;
  color: var(--primary-text);
}

body .p-fieldset .p-fieldset-legend a {
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  color: var(--primary-text);
  background-color: #f4f4f4;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}

body .p-fieldset .p-fieldset-legend a .p-fieldset-toggler {
  float: none;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.5em;
  color: #848484;
}

body .p-fieldset .p-fieldset-legend a .p-fieldset-legend-text {
  padding: 0;
}

body .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:hover {
  background-color: #dbdbdb;
  border: 1px solid #dbdbdb;
  color: var(--primary-text);
}

body .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:hover .p-fieldset-toggler {
  color: var(--primary-text);
}

body .p-fieldset .p-fieldset-content {
  padding: 0;
}

body .p-tabview.p-tabview-top, body .p-tabview.p-tabview-bottom, body .p-tabview.p-tabview-left, body .p-tabview.p-tabview-right {
  border: 0 none;
}

body .p-tabview.p-tabview-top .p-tabview-nav, body .p-tabview.p-tabview-bottom .p-tabview-nav, body .p-tabview.p-tabview-left .p-tabview-nav, body .p-tabview.p-tabview-right .p-tabview-nav {
  padding: 0;
  background: transparent;
  border: 0 none;
}

body .p-tabview.p-tabview-top .p-tabview-nav li, body .p-tabview.p-tabview-bottom .p-tabview-nav li, body .p-tabview.p-tabview-left .p-tabview-nav li, body .p-tabview.p-tabview-right .p-tabview-nav li {
  border: 1px solid #c8c8c8;
  background-color: #f4f4f4;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}

body .p-tabview.p-tabview-top .p-tabview-nav li a, body .p-tabview.p-tabview-bottom .p-tabview-nav li a, body .p-tabview.p-tabview-left .p-tabview-nav li a, body .p-tabview.p-tabview-right .p-tabview-nav li a {
  float: none;
  display: inline-block;
  color: var(--primary-text);
  padding: 0.571em 1em;
  font-weight: 700;
}

body .p-tabview.p-tabview-top .p-tabview-nav li a .p-tabview-left-icon, body .p-tabview.p-tabview-bottom .p-tabview-nav li a .p-tabview-left-icon, body .p-tabview.p-tabview-left .p-tabview-nav li a .p-tabview-left-icon, body .p-tabview.p-tabview-right .p-tabview-nav li a .p-tabview-left-icon {
  margin-right: 0.5em;
}

body .p-tabview.p-tabview-top .p-tabview-nav li a .p-tabview-right-icon, body .p-tabview.p-tabview-bottom .p-tabview-nav li a .p-tabview-right-icon, body .p-tabview.p-tabview-left .p-tabview-nav li a .p-tabview-right-icon, body .p-tabview.p-tabview-right .p-tabview-nav li a .p-tabview-right-icon {
  margin-left: 0.5em;
}

body .p-tabview.p-tabview-top .p-tabview-nav li .p-tabview-close, body .p-tabview.p-tabview-bottom .p-tabview-nav li .p-tabview-close, body .p-tabview.p-tabview-left .p-tabview-nav li .p-tabview-close, body .p-tabview.p-tabview-right .p-tabview-nav li .p-tabview-close {
  color: #848484;
  margin: 0 0.5em 0 0;
  vertical-align: middle;
}

body .p-tabview.p-tabview-top .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover, body .p-tabview.p-tabview-bottom .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover, body .p-tabview.p-tabview-left .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover, body .p-tabview.p-tabview-right .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover {
  background-color: #dbdbdb;
  border: 1px solid #dbdbdb;
}

body .p-tabview.p-tabview-top .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover a, body .p-tabview.p-tabview-bottom .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover a, body .p-tabview.p-tabview-left .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover a, body .p-tabview.p-tabview-right .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover a {
  color: var(--primary-text);
}

body .p-tabview.p-tabview-top .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-close, body .p-tabview.p-tabview-bottom .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-close, body .p-tabview.p-tabview-left .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-close, body .p-tabview.p-tabview-right .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-close {
  color: var(--primary-text);
}

body .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight, body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-highlight, body .p-tabview.p-tabview-left .p-tabview-nav li.p-highlight, body .p-tabview.p-tabview-right .p-tabview-nav li.p-highlight {
  background-color: var(--cap-primary-color);
  border: 1px solid var(--cap-primary-color);
}

body .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight a, body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-highlight a, body .p-tabview.p-tabview-left .p-tabview-nav li.p-highlight a, body .p-tabview.p-tabview-right .p-tabview-nav li.p-highlight a {
  color: $cap-white;
}

body .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight .p-tabview-close, body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-highlight .p-tabview-close, body .p-tabview.p-tabview-left .p-tabview-nav li.p-highlight .p-tabview-close, body .p-tabview.p-tabview-right .p-tabview-nav li.p-highlight .p-tabview-close {
  color: $cap-white;
}

body .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight:hover, body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-highlight:hover, body .p-tabview.p-tabview-left .p-tabview-nav li.p-highlight:hover, body .p-tabview.p-tabview-right .p-tabview-nav li.p-highlight:hover {
  border: 1px solid var(--cap-primary-color);
  background-color: var(--cap-primary-color);
}

body .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight:hover a, body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-highlight:hover a, body .p-tabview.p-tabview-left .p-tabview-nav li.p-highlight:hover a, body .p-tabview.p-tabview-right .p-tabview-nav li.p-highlight:hover a {
  color: $cap-white;
}

body .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight:hover a .p-tabview-left-icon, body .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight:hover a .p-tabview-right-icon, body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-highlight:hover a .p-tabview-left-icon, body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-highlight:hover a .p-tabview-right-icon, body .p-tabview.p-tabview-left .p-tabview-nav li.p-highlight:hover a .p-tabview-left-icon, body .p-tabview.p-tabview-left .p-tabview-nav li.p-highlight:hover a .p-tabview-right-icon, body .p-tabview.p-tabview-right .p-tabview-nav li.p-highlight:hover a .p-tabview-left-icon, body .p-tabview.p-tabview-right .p-tabview-nav li.p-highlight:hover a .p-tabview-right-icon {
  color: $cap-white;
}

body .p-tabview.p-tabview-top .p-tabview-nav li.p-tabview-selected a, body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-tabview-selected a, body .p-tabview.p-tabview-left .p-tabview-nav li.p-tabview-selected a, body .p-tabview.p-tabview-right .p-tabview-nav li.p-tabview-selected a {
  cursor: pointer;
}

body .p-tabview.p-tabview-top .p-tabview-nav {
  margin-bottom: -1px;
}

body .p-tabview.p-tabview-top .p-tabview-nav li {
  margin-right: 2px;
}

body .p-tabview.p-tabview-bottom .p-tabview-nav {
  margin-top: -1px;
}

body .p-tabview.p-tabview-bottom .p-tabview-nav li {
  margin-right: 2px;
}

body .p-tabview.p-tabview-left .p-tabview-nav {
  margin-right: -px;
}

body .p-tabview.p-tabview-left .p-tabview-nav li {
  margin-bottom: 2px;
}

body .p-tabview.p-tabview-right .p-tabview-nav {
  margin-right: -1px;
}

body .p-tabview.p-tabview-right .p-tabview-nav li {
  margin-bottom: 2px;
}

body .p-tabview .p-tabview-panels {
  background-color: $cap-white;
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  color: var(--primary-text);
}

body .p-tabview .p-tabview-panels .p-tabview-panel {
  padding: 0;
}

body .p-toolbar {
  background-color: #f4f4f4;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
}

body .p-toolbar button {
  vertical-align: middle;
}

body .p-toolbar .p-toolbar-separator {
  vertical-align: middle;
  color: #848484;
  margin: 0 0.5em;
}

body .p-card {
  background-color: $cap-white;
  color: var(--primary-text);
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
}

body .p-paginator {
  background-color: #f4f4f4;
  border: 1px solid #c8c8c8;
  padding: 0;
}

body .p-paginator .p-paginator-first,
body .p-paginator .p-paginator-prev,
body .p-paginator .p-paginator-next,
body .p-paginator .p-paginator-last {
  color: #848484;
  height: 2.286em;
  min-width: 2.286em;
  border: 0 none;
  line-height: 2.286em;
  padding: 0;
  margin: 0;
  vertical-align: top;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

body .p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
body .p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
body .p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
body .p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
  background-color: #e0e0e0;
  color: var(--primary-text);
}

body .p-paginator .p-paginator-current {
  color: #848484;
  height: 2.286em;
  min-width: 2.286em;
  line-height: 2.286em;
}

body .p-paginator .p-dropdown .p-dropdown-trigger,
body .p-paginator .p-dropdown .p-dropdown-label {
  color: #848484;
}

body .p-paginator .p-dropdown:hover .p-dropdown-trigger,
body .p-paginator .p-dropdown:hover .p-dropdown-label {
  color: var(--primary-text);
}

body .p-paginator .p-paginator-first:before {
  position: relative;
  top: 1px;
}

body .p-paginator .p-paginator-prev:before {
  position: relative;
}

body .p-paginator .p-paginator-next:before {
  position: relative;
  top: 1px;
}

body .p-paginator .p-paginator-last:before {
  position: relative;
  top: 1px;
}

body .p-paginator .p-paginator-pages {
  vertical-align: top;
  display: inline-block;
  padding: 0;
}

body .p-paginator .p-paginator-pages .p-paginator-page {
  color: #848484;
  height: 2.286em;
  min-width: 2.286em;
  border: 0 none;
  line-height: 2.286em;
  padding: 0;
  margin: 0;
  vertical-align: top;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

body .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  background-color: #e0e0e0;
  color: var(--primary-text);
}

body .p-paginator .p-dropdown {
  margin-left: 0.5em;
  min-width: auto;
}

body .p-datatable .p-datatable-caption,
body .p-datatable .p-datatable-summary {
  background-color: #f4f4f4;
  color: var(--primary-text);
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  text-align: center;
}

body .p-datatable .p-datatable-caption {
  border-bottom: 0 none;
  font-weight: 700;
}

body .p-datatable .p-datatable-summary {
  border-top: 0 none;
  font-weight: 700;
}

body .p-datatable .p-datatable-thead > tr > th {
  padding: 0.571em 0.857em;
  border: 1px solid #c8c8c8;
  font-weight: 700;
  color: var(--primary-text);
  background-color: #f4f4f4;
}

body .p-datatable .p-datatable-tbody > tr > td {
  padding: 0.571em 0.857em;
}

body .p-datatable .p-datatable-tfoot > tr > td {
  padding: 0.571em 0.857em;
  border: 1px solid #c8c8c8;
  font-weight: 700;
  color: var(--primary-text);
  background-color: $cap-white;
}

body .p-datatable .p-sortable-column .p-sortable-column-icon {
  color: #848484;
}

body .p-datatable .p-sortable-column:not(.p-highlight):hover {
  color: var(--primary-text);
  text-decoration: underline;
}

body .p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
  color: var(--primary-text);
}

body .p-datatable .p-sortable-column:not(.p-highlight) {
 i {
   color: $cap-table-sort-icon-color;
 }
}

body .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: $cap-white;
}

body .p-datatable .p-editable-column input {
  font-size: toRem(14px);
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
}

body .p-datatable .p-editable-column input:focus {
  outline: 1px solid var(--cap-primary-color);
  outline-offset: 2px;
}

body .p-datatable .p-datatable-tbody > tr {
  background-color: $cap-white;
  color: var(--primary-text);
}

body .p-datatable .p-datatable-tbody > tr > td {
  background-color: inherit;
  border: 1px solid #c8c8c8;
}

body .p-datatable .p-datatable-tbody > tr.p-highlight {
  background-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-datatable .p-datatable-tbody > tr.p-highlight a {
  color: $cap-white;
}

body .p-datatable .p-datatable-tbody > tr.p-contextmenu-selected {
  background-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
  -webkit-box-shadow: inset 0 -2px 0 0 var(--cap-primary-color);
  -moz-box-shadow: inset 0 -2px 0 0 var(--cap-primary-color);
  box-shadow: inset 0 -2px 0 0 var(--cap-primary-color);
}

body .p-datatable .p-datatable-tbody > tr:nth-child(even) {
  background-color: #f9f9f9;
}

body .p-datatable .p-datatable-tbody > tr:nth-child(even).p-highlight {
  background-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-datatable .p-datatable-tbody > tr:nth-child(even).p-highlight a {
  color: $cap-white;
}

body .p-datatable .p-datatable-tbody > tr:nth-child(even).p-contextmenu-selected {
  background-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr.p-selectable-row:not(.p-highlight):not(.p-contextmenu-selected):hover {
  cursor: pointer;
  background-color: #eaeaea;
  color: var(--primary-text);
}

body .p-datatable .p-column-resizer-helper {
  background-color: var(--cap-primary-color);
}

@media screen and (max-width: 40em) {
  body .p-datatable.p-datatable-responsive .p-paginator-top {
    border-bottom: 1px solid #c8c8c8;
  }

  body .p-datatable.p-datatable-responsive .p-paginator-bottom {
    border-top: 1px solid #c8c8c8;
  }

  body .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td {
    border: 0 none;
  }
}

body .p-datagrid .p-datagrid-header {
  background-color: #f4f4f4;
  color: var(--primary-text);
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  font-weight: 700;
  border-bottom: 0 none;
}

body .p-datagrid .p-datagrid-content {
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  background-color: $cap-white;
  color: var(--primary-text);
  padding: 0.571em 1em;
}

body .p-datagrid .p-datagrid-footer {
  background-color: $cap-white;
  color: var(--primary-text);
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  border-top: 0 none;
}

body .p-datalist .p-datalist-header {
  background-color: #f4f4f4;
  color: var(--primary-text);
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  font-weight: 700;
  border-bottom: 0 none;
}

body .p-datalist .p-datalist-content {
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  background-color: $cap-white;
  color: var(--primary-text);
  padding: 0.571em 1em;
}

body .p-datalist .p-datalist-footer {
  background-color: $cap-white;
  color: var(--primary-text);
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  border-top: 0 none;
}

body .p-datascroller .p-datascroller-header {
  background-color: #f4f4f4;
  color: var(--primary-text);
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  font-weight: 700;
  border-bottom: 0 none;
}

body .p-datascroller .p-datascroller-content {
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  background-color: $cap-white;
  color: var(--primary-text);
  padding: 0.571em 1em;
}

body .p-datascroller .p-datascroller-footer {
  background-color: $cap-white;
  color: var(--primary-text);
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  border-top: 0 none;
}

body .p-virtualscroller .p-virtualscroller-header {
  background-color: #f4f4f4;
  color: var(--primary-text);
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  font-weight: 700;
  border-bottom: 0 none;
}

body .p-virtualscroller .p-virtualscroller-content {
  border: 1px solid #c8c8c8;
  background-color: $cap-white;
  color: var(--primary-text);
}

body .p-virtualscroller .p-virtualscroller-content .p-virtualscroller-list li {
  border-bottom: 1px solid #c8c8c8;
}

body .p-virtualscroller .p-virtualscroller-footer {
  background-color: $cap-white;
  color: var(--primary-text);
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  border-top: 0 none;
}

body .p-dataview .p-dataview-header {
  background-color: #f4f4f4;
  color: var(--primary-text);
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  font-weight: 700;
  border-bottom: 0 none;
}

body .p-dataview .p-dataview-content {
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  background-color: $cap-white;
  color: var(--primary-text);
  padding: 0.571em 1em;
}

body .p-dataview .p-dataview-footer {
  background-color: $cap-white;
  color: var(--primary-text);
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  border-top: 0 none;
}

body .fc th {
  background-color: #f4f4f4;
  border: 1px solid #c8c8c8;
  color: var(--primary-text);
  padding: 0.571em 1em;
}

body .fc td.p-widget-content {
  background-color: $cap-white;
  border: 1px solid #c8c8c8;
  color: var(--primary-text);
}

body .fc td.fc-head-container {
  border: 1px solid #c8c8c8;
}

body .fc .fc-row {
  border-right: 1px solid #c8c8c8;
}

body .fc .fc-event {
  background-color: var(--cap-primary-color);
  border: 1px solid var(--cap-primary-color);
  color: $cap-white;
}

body .fc .fc-toolbar .fc-button {
  color: $cap-white;
  background-color: var(--cap-primary-color);
  border: 1px solid var(--cap-primary-color);
  font-size: toRem(14px);
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

body .fc .fc-toolbar .fc-button:enabled:hover {
  background-color: var(--cap-primary-color);
  color: $cap-white;
  border-color: var(--cap-primary-color);
}

body .fc .fc-toolbar .fc-button .fc-icon-chevron-left {
  font-family: 'PrimeIcons' !important;
  text-indent: 0;
}

body .fc .fc-toolbar .fc-button .fc-icon-chevron-left:before {
  content: '';
}

body .fc .fc-toolbar .fc-button .fc-icon-chevron-right {
  font-family: 'PrimeIcons' !important;
  text-indent: 0;
}

body .fc .fc-toolbar .fc-button .fc-icon-chevron-right:before {
  content: '';
}

body .fc .fc-toolbar .fc-button:focus {
  outline: 0 none;
  outline-offset: 0px;
  -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
  -moz-box-shadow: 0 0 0 0.2em #8dcdff;
  box-shadow: 0 0 0 0.2em #8dcdff;
}

body .fc .fc-toolbar .fc-button-group .fc-button {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

body .fc .fc-toolbar .fc-button-group .fc-button:first-child {
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

body .fc .fc-toolbar .fc-button-group .fc-button:last-child {
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

body .fc .fc-divider {
  background-color: #f4f4f4;
  border: 1px solid #c8c8c8;
}

body .p-fluid .fc .fc-toolbar .fc-button {
  width: auto;
}

body .p-picklist .p-picklist-buttons button {
  font-size: toRem(16px);
}

body .p-picklist .p-picklist-caption {
  background-color: #f4f4f4;
  color: var(--primary-text);
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  font-weight: 700;
  border-bottom: 0 none;
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
}

body .p-picklist .p-picklist-filter-container {
  padding: 0.429em 0.857em 0.429em 0.857em;
  border: 1px solid #c8c8c8;
  color: var(--primary-text);
  background-color: $cap-white;
  margin: 0;
  border-bottom: 0 none;
}

body .p-picklist .p-picklist-filter-container input.p-picklist-filter {
  width: 100%;
  padding-right: 2em;
  text-indent: 0;
}

body .p-picklist .p-picklist-filter-container .p-picklist-filter-icon {
  top: 50%;
  margin-top: -0.5em;
  left: auto;
  right: 1.357em;
  color: var(--cap-primary-color);
}

body .p-picklist .p-picklist-buttons {
  padding: 0.571em 1em;
}

body .p-picklist .p-picklist-list {
  border: 1px solid #c8c8c8;
  background-color: $cap-white;
  color: var(--primary-text);
  padding: 0;
}

body .p-picklist .p-picklist-list .p-picklist-item {
  padding: 0.429em 0.857em;
  margin: 0;
  border: 0 none;
  color: var(--primary-text);
  background-color: transparent;
}

body .p-picklist .p-picklist-list .p-picklist-item:not(.p-highlight):hover {
  background-color: #eaeaea;
  color: var(--primary-text);
}

body .p-picklist .p-picklist-list .p-picklist-item.p-highlight {
  background-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-picklist .p-picklist-list .p-picklist-droppoint-highlight {
  background-color: var(--cap-primary-color);
}

@media (max-width: 40em) {
  body .p-picklist.p-picklist-responsive .p-picklist-buttons {
    padding: 0.571em 1em;
  }
}

body .p-orderlist .p-orderlist-controls {
  padding: 0.571em 1em;
}

body .p-orderlist .p-orderlist-controls button {
  font-size: toRem(16px);
}

body .p-orderlist .p-orderlist-caption {
  background-color: #f4f4f4;
  color: var(--primary-text);
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  font-weight: 700;
  border-bottom: 0 none;
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
}

body .p-orderlist .p-orderlist-filter-container {
  padding: 0.429em 0.857em 0.429em 0.857em;
  border: 1px solid #c8c8c8;
  color: var(--primary-text);
  background-color: $cap-white;
  margin: 0;
  border-bottom: 0 none;
}

body .p-orderlist .p-orderlist-filter-container input.p-inputtext {
  width: 100%;
  padding-right: 2em;
  text-indent: 0;
}

body .p-orderlist .p-orderlist-filter-container .p-orderlist-filter-icon {
  top: 50%;
  margin-top: -0.5em;
  left: auto;
  right: 1.357em;
  color: var(--cap-primary-color);
}

body .p-orderlist .p-orderlist-list {
  border: 1px solid #c8c8c8;
  background-color: $cap-white;
  color: var(--primary-text);
  padding: 0;
}

body .p-orderlist .p-orderlist-list .p-orderlist-item {
  padding: 0.429em 0.857em;
  margin: 0;
  border: 0 none;
  color: var(--primary-text);
  background-color: transparent;
}

body .p-orderlist .p-orderlist-list .p-orderlist-item:not(.p-highlight):hover {
  background-color: #eaeaea;
  color: var(--primary-text);
}

body .p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
  background-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-orderlist .p-orderlist-list .p-orderlist-droppoint-highlight {
  background-color: var(--cap-primary-color);
}

body .p-tree {
  border: 1px solid #c8c8c8;
  background-color: $cap-white;
  color: var(--primary-text);
  padding: 0.571em 1em;
}

body .p-tree .p-tree-container {
  padding: 0.286em;
  margin: 0;
}

body .p-tree .p-tree-container .p-treenode {
  padding: 0.143em 0;
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content {
  padding: 0;
  border: 1px solid transparent;
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
  vertical-align: middle;
  display: inline-block;
  float: none;
  margin: 0 0.143em 0 0;
  color: #848484;
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
  vertical-align: middle;
  display: inline-block;
  margin: 0 0.143em 0 0;
  color: #848484;
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-label {
  margin: 0;
  vertical-align: middle;
  display: inline-block;
  padding: 0.286em;
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-label.p-highlight {
  background-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
  margin: 0 0.5em 0 0;
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox .p-checkbox-icon {
  margin: 1px 0 0 0;
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable .p-treenode-label:not(.p-highlight):hover {
  background-color: #eaeaea;
  color: var(--primary-text);
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content > span {
  line-height: inherit;
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover {
  background-color: #eaeaea;
  color: var(--primary-text);
}

body .p-tree .p-tree-container .p-treenode-droppoint.p-treenode-droppoint-active {
  background-color: var(--cap-primary-color);
}

body .p-tree.p-tree-horizontal {
  padding-left: 0;
  padding-right: 0;
}

body .p-tree.p-tree-horizontal .p-treenode .p-treenode-content {
  border: 1px solid #c8c8c8;
  background-color: $cap-white;
  color: var(--primary-text);
  padding: 0.571em 1em;
}

body .p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight {
  background-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-checkbox .p-icon {
  color: var(--cap-primary-color);
}

body .p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-label:not(.p-highlight):hover {
  background-color: inherit;
  color: inherit;
}

body .p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
  background-color: #eaeaea;
  color: var(--primary-text);
}

body .p-tree .p-tree-filter-container .p-tree-filter {
  width: 100%;
  padding-right: 2em;
}

body .p-tree .p-tree-filter-container .p-tree-filter-icon {
  top: 50%;
  left: auto;
  margin-top: -0.5em;
  right: 0.8em;
  color: var(--cap-primary-color);
}

body .p-organizationchart .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-highlight):hover {
  background-color: #eaeaea;
  color: var(--primary-text);
}

body .p-organizationchart .p-organizationchart-node-content.p-highlight {
  background-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-organizationchart .p-organizationchart-node-content.p-highlight .p-node-toggler i {
  color: #00325a;
}

body .p-organizationchart .p-organizationchart-line-down {
  background-color: #c8c8c8;
}

body .p-organizationchart .p-organizationchart-line-left {
  border-right: 1px solid #c8c8c8;
  border-color: #c8c8c8;
}

body .p-organizationchart .p-organizationchart-line-top {
  border-top: 1px solid #c8c8c8;
  border-color: #c8c8c8;
}

body .p-organizationchart .p-organizationchart-node-content {
  border: 1px solid #c8c8c8;
  background-color: $cap-white;
  color: var(--primary-text);
  padding: 0.571em 1em;
}

body .p-organizationchart .p-organizationchart-node-content .p-node-toggler {
  bottom: -0.7em;
  margin-left: -0.46em;
  color: #848484;
}

body .p-carousel {
  padding: 0;
}

body .p-carousel .p-carousel-header {
  background-color: #f4f4f4;
  color: var(--primary-text);
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  margin: 0;
  font-weight: 700;
}

body .p-carousel .p-carousel-header .p-carousel-header-title {
  padding: 0;
}

body .p-carousel .p-carousel-viewport {
  border: 1px solid #c8c8c8;
  background-color: $cap-white;
  color: var(--primary-text);
  padding: 0;
}

body .p-carousel .p-carousel-viewport .p-carousel-items .p-carousel-item {
  border: 1px solid #c8c8c8;
}

body .p-carousel .p-carousel-footer {
  background-color: #f4f4f4;
  color: var(--primary-text);
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  margin: 0;
}

body .p-carousel .p-carousel-button {
  color: #848484;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}

body .p-carousel .p-carousel-button.p-carousel-next-button:before {
  content: '';
}

body .p-carousel .p-carousel-button.p-carousel-prev-button:before {
  content: '';
}

body .p-carousel .p-carousel-button:not(.p-disabled):hover {
  color: var(--primary-text);
}

body .p-carousel .p-carousel-page-links {
  margin: 0.125em 0.5em;
}

body .p-carousel .p-carousel-page-links .p-carousel-page-link {
  color: #848484;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}

body .p-carousel .p-carousel-page-links .p-carousel-page-link:hover {
  color: var(--primary-text);
}

body .p-treetable .p-treetable-caption,
body .p-treetable .p-treetable-summary {
  background-color: #f4f4f4;
  color: var(--primary-text);
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  text-align: center;
}

body .p-treetable .p-treetable-caption {
  border-bottom: 0 none;
  font-weight: 700;
}

body .p-treetable .p-treetable-summary {
  border-top: 0 none;
  font-weight: 700;
}

body .p-treetable .p-treetable-thead > tr > th {
  padding: 0.571em 0.857em;
  border: 1px solid #c8c8c8;
  font-weight: 700;
  color: var(--primary-text);
  background-color: #f4f4f4;
}

body .p-treetable .p-treetable-tbody > tr > td {
  padding: 0.571em 0.857em;
}

body .p-treetable .p-treetable-tfoot > tr > td {
  padding: 0.571em 0.857em;
  border: 1px solid #c8c8c8;
  font-weight: 700;
  color: var(--primary-text);
  background-color: $cap-white;
}

body .p-treetable .p-sortable-column .p-sortable-column-icon {
  color: #848484;
}

body .p-treetable .p-sortable-column:not(.p-highlight):hover {
  background-color: #e0e0e0;
  color: var(--primary-text);
}

body .p-treetable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
  color: var(--primary-text);
}

body .p-treetable .p-sortable-column.p-highlight {
  background-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: $cap-white;
}

body .p-treetable .p-editable-column input {
  font-size: toRem(14px);
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
}

body .p-treetable .p-editable-column input:focus {
  outline: 1px solid var(--cap-primary-color);
  outline-offset: 2px;
}

body .p-treetable .p-treetable-tbody > tr {
  background-color: $cap-white;
  color: var(--primary-text);
}

body .p-treetable .p-treetable-tbody > tr > td {
  background-color: inherit;
  border: 1px solid #c8c8c8;
}

body .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
  color: #848484;
  vertical-align: middle;
}

body .p-treetable .p-treetable-tbody > tr > td .p-treetable-chkbox {
  vertical-align: middle;
  margin-right: 0.5em;
}

body .p-treetable .p-treetable-tbody > tr.p-highlight {
  background-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-treetable .p-treetable-tbody > tr.p-highlight > td {
  background-color: inherit;
  border: 1px solid #c8c8c8;
}

body .p-treetable .p-treetable-tbody > tr.p-highlight > td .p-treetable-toggler {
  color: $cap-white;
}

body .p-treetable .p-treetable-tbody > tr.p-contextmenu-selected {
  background-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-treetable .p-treetable-tbody > tr.p-contextmenu-selected > td {
  background-color: inherit;
  border: 1px solid #c8c8c8;
}

body .p-treetable .p-treetable-tbody > tr.p-contextmenu-selected > td .p-treetable-toggler {
  color: $cap-white;
}

body .p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):not(.p-contextmenu-selected):hover {
  cursor: pointer;
  background-color: #eaeaea;
  color: var(--primary-text);
}

body .p-treetable .p-column-resizer-helper {
  background-color: var(--cap-primary-color);
}

body .p-messages {
  padding: 1em;
  margin: 1em 0;
}

body .p-messages.p-messages-info {
  background-color: var(--info-color);
  border: 0 none;
  color: var(--text-toast);
}

body .p-messages.p-messages-info .p-messages-icon {
  color: var(--text-toast);
}

body .p-messages.p-messages-info .p-messages-close {
  color: var(--text-toast);
}

body .p-messages.p-messages-success {
  background-color: var(--success-color);
  border: 0 none;
  color: var(--text-toast);
}

body .p-messages.p-messages-success .p-messages-icon {
  color: var(--text-toast);
}

body .p-messages.p-messages-success .p-messages-close {
  color: var(--text-toast);
}

body .p-messages.p-messages-warn {
  background-color: var(--warning-color);
  border: 0 none;
  color: var(--text-toast);
}

body .p-messages.p-messages-warn .p-messages-icon {
  color: var(--text-toast);
}

body .p-messages.p-messages-warn .p-messages-close {
  color: var(--text-toast);
}

body .p-messages.p-messages-error {
  background-color: var(--confirm-color);
  border: 0 none;
  color: var(--text-toast);
}

body .p-messages.p-messages-error .p-messages-icon {
  color: var(--text-toast);
}

body .p-messages.p-messages-error .p-messages-close {
  color: var(--text-toast);
}

body .p-messages .p-messages-close {
  top: 0.25em;
  right: 0.5em;
  font-size: 1.5em;
}

body .p-messages .p-messages-icon {
  font-size: 2em;
}

body .p-message {
  padding: 0.429em;
  margin: 0;
}

body .p-message.p-message-info {
  background-color: var(--info-color);
  border: 0 none;
  color: var(--text-toast);
}

body .p-message.p-message-info .p-message-icon {
  color: var(--text-toast);
}

body .p-message.p-message-success {
  background-color: var(--success-color);
  border: 0 none;
  color: var(--text-toast);
}

body .p-message.p-message-success .p-message-icon {
  color: var(--text-toast);
}

body .p-message.p-message-warn {
  background-color: var(--warning-color);
  border: 0 none;
  color: var(--text-toast);
}

body .p-message.p-message-warn .p-message-icon {
  color: var(--text-toast);
}

body .p-message.p-message-error {
  background-color: var(--confirm-color);
  border: 0 none;
  color: var(--text-toast);
}

body .p-message.p-message-error .p-message-icon {
  color: var(--text-toast);
}

body .p-message .p-message-icon {
  font-size: 1.25em;
}

body .p-message .p-message-text {
  font-size: 1em;
}

body .p-growl {
  top: 70px;
}

body .p-growl .p-growl-item-container {
  margin: 0 0 1em 0;
  opacity: 0.9;
  filter: alpha(opacity=90);
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-growl .p-growl-item-container .p-growl-item {
  padding: 1em;
}

body .p-growl .p-growl-item-container .p-growl-item .p-growl-message {
  margin: 0 0 0 4em;
}

body .p-growl .p-growl-item-container .p-growl-item .p-growl-image {
  font-size: 2.571em;
}

body .p-growl .p-growl-item-container.p-growl-message-info {
  background-color: var(--info-color);
  border: 0 none;
  color: var(--text-toast);
}

body .p-growl .p-growl-item-container.p-growl-message-info .p-growl-image {
  color: var(--text-toast);
}

body .p-growl .p-growl-item-container.p-growl-message-success {
  background-color: var(--success-color);
  border: 0 none;
  color: var(--text-toast);
}

body .p-growl .p-growl-item-container.p-growl-message-success .p-growl-image {
  color: var(--text-toast);
}

body .p-growl .p-growl-item-container.p-growl-message-warn {
  background-color: var(--warning-color);
  border: 0 none;
  color: var(--text-toast);
}

body .p-growl .p-growl-item-container.p-growl-message-warn .p-growl-image {
  color: var(--text-toast);
}

body .p-growl .p-growl-item-container.p-growl-message-error {
  background-color: var(--confirm-color);
  border: 0 none;
  color: var(--text-toast);
}

body .p-growl .p-growl-item-container.p-growl-message-error .p-growl-image {
  color: var(--text-toast);
}

body .p-toast .p-toast-message {
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  margin: 0 0 1em 0;
}

body .p-toast .p-toast-message.p-toast-message-info {
  background-color: var(--info-color);
  border: 0 none;
  color: var(--text-toast);
}

body .p-toast .p-toast-message.p-toast-message-info .p-toast-close-icon {
  color: var(--text-toast);
}

body .p-toast .p-toast-message.p-toast-message-success {
  background-color: var(--success-color);
  border: 0 none;
  color: var(--text-toast);
}

body .p-toast .p-toast-message.p-toast-message-success .p-toast-close-icon {
  color: var(--text-toast);
}

body .p-toast .p-toast-message.p-toast-message-warn {
  background-color: var(--warning-color);
  border: 0 none;
  color: var(--text-toast);
}

body .p-toast .p-toast-message.p-toast-message-warn .p-toast-close-icon {
  color: var(--text-toast);
}

body .p-toast .p-toast-message.p-toast-message-error {
  background-color: var(--confirm-color);
  border: 0 none;
  color: var(--text-toast);
}

body .p-toast .p-toast-message.p-toast-message-error .p-toast-close-icon {
  color: var(--text-toast);
}

body .p-component-overlay {
  background-color: rgba(0, 0, 0, 0.4);
}

body .p-overlaypanel {
  background-color: $cap-white;
  color: var(--primary-text);
  padding: 0;
  border: 1px solid #c8c8c8;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

body .p-overlaypanel .p-overlaypanel-content {
  padding: 0.571em 1em;
}

body .p-overlaypanel .p-overlaypanel-close {
  background-color: var(--cap-primary-color);
  color: $cap-white;
  width: 1.538em;
  height: 1.538em;
  line-height: 1.538em;
  text-align: center;
  position: absolute;
  top: -0.769em;
  right: -0.769em;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}

body .p-overlaypanel .p-overlaypanel-close:hover {
  background-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-overlaypanel .p-overlaypanel-close .p-overlaypanel-close-icon {
  line-height: inherit;
}

body .p-overlaypanel:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: $cap-white;
}

body .p-overlaypanel:before {
  border-color: rgba(200, 200, 200, 0);
  border-bottom-color: #c8c8c8;
}

body .p-overlaypanel.p-overlaypanel-flipped:after {
  border-top-color: $cap-white;
}

body .p-overlaypanel.p-overlaypanel-flipped:before {
  border-top-color: #c8c8c8;
}

body .p-dialog {
  padding: 0;
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-dialog .p-dialog-titlebar {
  border: 1px solid #c8c8c8;
  background-color: #f4f4f4;
  color: var(--primary-text);
  padding: 1em;
  font-weight: 700;
  border-bottom: 0 none;
}

body .p-dialog .p-dialog-titlebar .p-dialog-title {
  margin: 0;
  float: none;
}

body .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon {
  color: #848484;
  border: 0 none;
  padding: 0;
  margin-left: 0.5em;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}

body .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon:hover {
  color: var(--primary-text);
}

body .p-dialog .p-dialog-content {
  background-color: $cap-white;
  color: var(--primary-text);
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
}

body .p-dialog .p-dialog-footer {
  border: 1px solid #c8c8c8;
  background-color: $cap-white;
  color: var(--primary-text);
  padding: 0.571em 1em;
  margin: 0;
  text-align: right;
  position: relative;
  top: -1px;
}

body .p-dialog .p-dialog-footer button {
  margin: 0 0.5em 0 0;
  width: auto;
}

body .p-dialog.p-confirm-dialog .p-dialog-content {
  padding: 1.5em;
}

body .p-dialog.p-confirm-dialog .p-dialog-content > span {
  float: none;
  display: inline-block;
  vertical-align: middle;
  line-height: 14px;
  margin: 0;
}

body .p-dialog.p-confirm-dialog .p-dialog-content > span.p-icon {
  margin-right: 0.35em;
  font-size: toRem(16px);
}

body .p-sidebar {
  background-color: $cap-white;
  color: var(--primary-text);
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-sidebar .p-sidebar-close {
  color: #848484;
}

body .p-sidebar .p-sidebar-close:hover {
  color: var(--primary-text);
}

body .p-tooltip {
  .p-tooltip-arrow {
    left: 1px;
  }

  .p-tooltip-text {
    background-color: $cap-black;
    color: $cap-white;
    padding: 0.429em;
    box-shadow: 1px 1px 1px rgb(0 0 0 / 4%)
  }

  &.cap-sidebar-tooltip .p-tooltip-text {
    color: var(--text-color-1);
    font-weight: 600;
  }
}

body .p-tooltip.p-tooltip-right .p-tooltip-arrow {
  border-right-color: $cap-black;
}

body .p-tooltip.p-tooltip-left .p-tooltip-arrow {
  border-left-color: $cap-black;
}

body .p-tooltip.p-tooltip-top .p-tooltip-arrow {
  border-top-color: $cap-black;
}

body .p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  border-bottom-color: $cap-black;
}

body .p-lightbox {
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-lightbox .p-lightbox-caption {
  border: 1px solid #c8c8c8;
  background-color: #f4f4f4;
  color: var(--primary-text);
  padding: 0.571em 1em;
  font-weight: 700;
}

body .p-lightbox .p-lightbox-caption .p-lightbox-caption-text {
  color: var(--primary-text);
  margin: 0;
}

body .p-lightbox .p-lightbox-caption .p-lightbox-close {
  padding: 0;
  color: #848484;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}

body .p-lightbox .p-lightbox-caption .p-lightbox-close:hover {
  color: var(--primary-text);
}

body .p-lightbox .p-lightbox-content-wrapper {
  overflow: hidden;
  background-color: $cap-white;
  color: var(--primary-text);
  border: 0 none;
  padding: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-nav-left, body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-nav-right {
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  font-size: 3em;
  color: $cap-white;
  margin-top: -0.5em;
}

body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-nav-left:hover, body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-nav-right:hover {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}

body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-content.p-lightbox-loading ~ a {
  display: none;
}

body .p-breadcrumb {
  background-color: $cap-white;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
}

body .p-breadcrumb ul li .p-menuitem-link {
  color: var(--primary-text);
  margin: 0;
}

body .p-breadcrumb ul li.p-breadcrumb-chevron {
  margin: 0 0.5em 0 0.5em;
  color: #848484;
}

body .p-breadcrumb ul li:first-child a {
  color: #848484;
  margin: 0;
}

body .p-breadcrumb ul li .p-menuitem-icon {
  color: #848484;
}

body .p-steps {
  position: relative;
}

body .p-steps .p-steps-item {
  background-color: transparent;
  text-align: center;
}

body .p-steps .p-steps-item .p-menuitem-link {
  display: inline-block;
  text-align: center;
  background-color: transparent;
  overflow: hidden;
}

body .p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  color: var(--primary-text);
  background-color: $cap-white;
  border: 1px solid #c8c8c8;
  position: relative;
  top: 16px;
  margin-bottom: 14px;
  width: 28px;
  height: 28px;
  font-size: toRem(16px);
  line-height: 24px;
  text-align: center;
}

body .p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  display: block;
  margin-top: 6px;
  color: #848484;
}

body .p-steps .p-steps-item.p-highlight .p-steps-number {
  background: var(--cap-primary-color);
  color: $cap-white;
}

body .p-steps .p-steps-item.p-highlight .p-steps-title {
  font-weight: 700;
  color: var(--primary-text);
}

body .p-steps .p-steps-item:last-child .p-menuitem-link {
  display: block;
}

body .p-steps:before {
  content: ' ';
  border-top: 1px solid #c8c8c8;
  width: 100%;
  top: 45%;
  left: 0;
  display: block;
  position: absolute;
}

body .p-menu .p-menuitem-link,
body .p-menubar .p-menuitem-link,
body .p-tieredmenu .p-menuitem-link,
body .p-contextmenu .p-menuitem-link,
body .p-megamenu .p-menuitem-link,
body .p-slidemenu .p-menuitem-link {
  padding: 0.714em 0.857em;
  color: var(--primary-text);
  font-weight: normal;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

body .p-menu .p-menuitem-link .p-menuitem-text,
body .p-menubar .p-menuitem-link .p-menuitem-text,
body .p-tieredmenu .p-menuitem-link .p-menuitem-text,
body .p-contextmenu .p-menuitem-link .p-menuitem-text,
body .p-megamenu .p-menuitem-link .p-menuitem-text,
body .p-slidemenu .p-menuitem-link .p-menuitem-text {
  color: var(--primary-text);
}

body .p-menu .p-menuitem-link .p-menuitem-icon,
body .p-menubar .p-menuitem-link .p-menuitem-icon,
body .p-tieredmenu .p-menuitem-link .p-menuitem-icon,
body .p-contextmenu .p-menuitem-link .p-menuitem-icon,
body .p-megamenu .p-menuitem-link .p-menuitem-icon,
body .p-slidemenu .p-menuitem-link .p-menuitem-icon {
  color: var(--primary-text);
  margin-right: 0.5em;
}

body .p-menu .p-menuitem-link:hover,
body .p-menubar .p-menuitem-link:hover,
body .p-tieredmenu .p-menuitem-link:hover,
body .p-contextmenu .p-menuitem-link:hover,
body .p-megamenu .p-menuitem-link:hover,
body .p-slidemenu .p-menuitem-link:hover {
  background-color: #eaeaea;
}

body .p-menu .p-menuitem-link:hover .p-menuitem-text,
body .p-menubar .p-menuitem-link:hover .p-menuitem-text,
body .p-tieredmenu .p-menuitem-link:hover .p-menuitem-text,
body .p-contextmenu .p-menuitem-link:hover .p-menuitem-text,
body .p-megamenu .p-menuitem-link:hover .p-menuitem-text,
body .p-slidemenu .p-menuitem-link:hover .p-menuitem-text {
  color: var(--primary-text);
}

body .p-menu .p-menuitem-link:hover .p-menuitem-icon,
body .p-menubar .p-menuitem-link:hover .p-menuitem-icon,
body .p-tieredmenu .p-menuitem-link:hover .p-menuitem-icon,
body .p-contextmenu .p-menuitem-link:hover .p-menuitem-icon,
body .p-megamenu .p-menuitem-link:hover .p-menuitem-icon,
body .p-slidemenu .p-menuitem-link:hover .p-menuitem-icon {
  color: var(--primary-text);
}

body .p-menu {
  padding: 0;
  background-color: $cap-white;
  border: 1px solid #c8c8c8;
}

body .p-menu .p-menuitem {
  margin: 0;
}

body .p-menu.p-shadow {
  border: 1px solid #c8c8c8;
  -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-menu .p-submenu-header {
  margin: 0;
  padding: 0.714em 0.857em;
  color: var(--primary-text);
  background-color: #f4f4f4;
  font-weight: 700;
  border: 0 none;
}

body .p-menu .p-menu-separator {
  border: 1px solid #c8c8c8;
  border-width: 1px 0 0 0;
}

body .p-menubar {
  padding: 0;
  background-color: $cap-white;
  border: 1px solid #c8c8c8;
}

body .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
  padding: 0.714em 0.857em;
}

body .p-menubar .p-menubar-root-list > .p-menu-separator {
  border: 1px solid #c8c8c8;
  border-width: 0 0 0 1px;
}

body .p-menubar .p-submenu-list {
  padding: 0;
  background-color: $cap-white;
  border: 1px solid #c8c8c8;
  -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-menubar .p-submenu-list .p-menuitem {
  margin: 0;
}

body .p-menubar .p-submenu-list .p-menu-separator {
  border: 1px solid #c8c8c8;
  border-width: 1px 0 0 0;
}

body .p-menubar .p-menuitem {
  margin: 0;
}

body .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background-color: #eaeaea;
}

body .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: var(--primary-text);
}

body .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, body .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: var(--primary-text);
}

body .p-contextmenu {
  padding: 0;
  background-color: $cap-white;
  border: 1px solid #c8c8c8;
  -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-contextmenu .p-submenu-list {
  padding: 0;
  background-color: $cap-white;
  border: 1px solid #c8c8c8;
  -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-contextmenu .p-menuitem {
  margin: 0;
}

body .p-contextmenu .p-menuitem .p-menuitem-link .p-submenu-icon {
  right: 0.429em;
}

body .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background-color: #eaeaea;
}

body .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: var(--primary-text);
}

body .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, body .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: var(--primary-text);
}

body .p-contextmenu .p-menu-separator {
  border: 1px solid #c8c8c8;
  border-width: 1px 0 0 0;
}

body .p-tieredmenu {
  padding: 0;
  background-color: $cap-white;
  border: 1px solid #c8c8c8;
}

body .p-tieredmenu .p-submenu-list {
  padding: 0;
  background-color: $cap-white;
  border: 1px solid #c8c8c8;
  -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-tieredmenu .p-menuitem {
  margin: 0;
}

body .p-tieredmenu .p-menuitem .p-menuitem-link .p-submenu-icon {
  right: 0.429em;
}

body .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background-color: #eaeaea;
}

body .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: var(--primary-text);
}

body .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, body .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: var(--primary-text);
}

body .p-tieredmenu .p-menu-separator {
  border: 1px solid #c8c8c8;
  border-width: 1px 0 0 0;
}

body .p-slidemenu {
  padding: 0;
  background-color: $cap-white;
  border: 1px solid #c8c8c8;
}

body .p-slidemenu .p-submenu-list {
  padding: 0;
  background-color: $cap-white;
  border: 0 none;
}

body .p-slidemenu .p-menuitem {
  margin: 0;
}

body .p-slidemenu .p-menuitem .p-menuitem-link .p-submenu-icon {
  right: 0.429em;
}

body .p-slidemenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background-color: #eaeaea;
}

body .p-slidemenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: var(--primary-text);
}

body .p-slidemenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, body .p-slidemenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: var(--primary-text);
}

body .p-slidemenu .p-slidemenu-backward {
  margin: 0;
  padding: 0.571em 1em;
  color: var(--primary-text);
  background-color: #f4f4f4;
  font-weight: 700;
  border: 0 none;
}

body .p-slidemenu .p-slidemenu-backward:hover {
  background-color: #dbdbdb;
  color: var(--primary-text);
}

body .p-slidemenu .p-menu-separator {
  border: 1px solid #c8c8c8;
  border-width: 1px 0 0 0;
}

body .p-tabmenu {
  border: 0 none;
}

body .p-tabmenu .p-tabmenu-nav {
  padding: 0;
  background: transparent;
  border-bottom: 1px solid #c8c8c8;
}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
  position: static;
  border: 1px solid #c8c8c8;
  background-color: #f4f4f4;
  margin-right: 2px;
  margin-bottom: -1px;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  color: var(--primary-text);
  padding: 0.571em 1em;
}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-text {
  color: var(--primary-text);
  margin-right: 0.5em;
}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
  color: #848484;
  margin-right: 0.5em;
}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover {
  background-color: #dbdbdb;
  border: 1px solid #dbdbdb;
}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link .p-menuitem-text {
  color: var(--primary-text);
}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link .p-menuitem-icon {
  color: var(--primary-text);
}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight {
  background-color: var(--cap-primary-color);
  border: 1px solid var(--cap-primary-color);
}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link .p-menuitem-text {
  color: $cap-white;
}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link .p-menuitem-icon {
  color: $cap-white;
}

body .p-megamenu {
  padding: 0;
  background-color: $cap-white;
  border: 1px solid #c8c8c8;
}

body .p-megamenu .p-megamenu-submenu-header {
  margin: 0;
  padding: 0.571em 1em;
  color: var(--primary-text);
  background-color: #f4f4f4;
  font-weight: 700;
  border: 0 none;
}

body .p-megamenu .p-megamenu-panel {
  padding: 0;
  background-color: $cap-white;
  border: 1px solid #c8c8c8;
  -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-megamenu .p-menuitem {
  margin: 0;
}

body .p-megamenu .p-menuitem .p-menuitem-link .p-submenu-icon {
  right: 0.429em;
}

body .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background-color: #eaeaea;
}

body .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: var(--primary-text);
}

body .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, body .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: var(--primary-text);
}

body .p-megamenu.p-megamenu-vertical .p-megamenu-root-list > .p-menuitem > .p-menuitem-link > .p-submenu-icon {
  right: 0.429em;
}

body .p-panelmenu .p-icon {
  position: static;
}

body .p-panelmenu .p-panelmenu-header {
  padding: 0;
}

body .p-panelmenu .p-panelmenu-header > a {
  border: 1px solid #c8c8c8;
  background-color: #f4f4f4;
  color: var(--primary-text);
  padding: 0.714em 0.857em;
  font-weight: 700;
  position: static;
  font-size: toRem(14px);
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}

body .p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
  color: #848484;
}

body .p-panelmenu .p-panelmenu-header:not(.p-highlight) > a:hover {
  outline: 0 none;
  border: 1px solid #dbdbdb;
  background-color: #dbdbdb;
  color: var(--primary-text);
}

body .p-panelmenu .p-panelmenu-header:not(.p-highlight) > a:hover .p-panelmenu-icon {
  color: var(--primary-text);
}

body .p-panelmenu .p-panelmenu-header.p-highlight > a {
  border: 1px solid var(--cap-primary-color);
  background-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-panelmenu .p-panelmenu-header.p-highlight > a .p-panelmenu-icon {
  color: $cap-white;
}

body .p-panelmenu .p-panelmenu-header.p-highlight > a:hover {
  outline: 0 none;
  border: 1px solid var(--cap-primary-color);
  background-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-panelmenu .p-panelmenu-header.p-highlight > a:hover .p-panelmenu-icon {
  color: $cap-white;
}

body .p-panelmenu .p-panelmenu-panel {
  margin-top: 2px;
}

body .p-panelmenu .p-panelmenu-panel:first-child {
  margin-top: 0;
}

body .p-panelmenu .p-panelmenu-content {
  padding: 0;
  background-color: $cap-white;
  border: 1px solid #c8c8c8;
  margin-top: 0;
  position: static;
  border-top: 0 none;
}

body .p-panelmenu .p-panelmenu-content .p-menuitem {
  margin: 0;
}

body .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  padding: 0.714em 0.857em;
  color: var(--primary-text);
}

body .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:hover {
  background-color: #eaeaea;
}

body .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:hover .p-menuitem-icon, body .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:hover .p-panelmenu-icon {
  color: var(--primary-text);
}

body .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:hover .p-menuitem-text {
  color: var(--primary-text);
}

body .p-progressbar {
  border: 0 none;
  height: 24px;
  background-color: #eaeaea;
}

body .p-progressbar .p-progressbar-value {
  border: 0 none;
  margin: 0;
  background: var(--cap-primary-color);
}

body .p-progressbar .p-progressbar-label {
  margin-top: 0;
  color: var(--primary-text);
  line-height: 24px;
}

body .p-galleria .p-galleria-nav-prev,
body .p-galleria .p-galleria-nav-next {
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  position: absolute;
  color: #848484;
}

body .p-galleria .p-galleria-nav-prev:hover,
body .p-galleria .p-galleria-nav-next:hover {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}

body .p-galleria .p-galleria-nav-prev {
  font-size: toRem(16px);
}

body .p-galleria .p-galleria-nav-next {
  font-size: toRem(16px);
}

body .p-terminal {
  background-color: $cap-white;
  color: var(--primary-text);
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
}

body .p-terminal .p-terminal-input {
  font-size: toRem(14px);
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  height: 16px;
}

body .p-terminal .p-terminal-command {
  height: 16px;
}

body .p-inplace {
  min-height: 2.357em;
}

body .p-inplace .p-inplace-display {
  padding: 0.429em;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}

body .p-inplace .p-inplace-display:not(.p-disabled):hover {
  background-color: #eaeaea;
  color: var(--primary-text);
}

body .p-fluid .p-inplace.p-inplace-closable .p-inplace-content > :first-child {
  display: inline-block;
  width: calc(100% - 2.357em);
}


.p-datatable {
  position: relative;
}

.p-datatable table {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}

.p-datatable .p-datatable-header,
.p-datatable .p-datatable-caption,
.p-datatable .p-datatable-footer {
  text-align: center;
  padding: .5em .75em;
  box-sizing: border-box;
}

.p-datatable .p-datatable-caption,
.p-datatable .p-datatable-header {
  border-bottom: 0 none;
}

.p-datatable .p-datatable-footer {
  border-top: 0 none;
}

.p-datatable thead th, .p-datatable tfoot td {
  text-align: center;
}

.p-datatable thead tr {
  border-width: 0;
}

.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tfoot > tr > td,
.p-datatable .p-datatable-data > tr > td {
  border-color: inherit;
  box-sizing: border-box;
  padding: .25em .5em;
  border-width: 1px;
  border-style: solid;
}


.p-datatable.p-datatable-resizable .p-datatable-thead > tr > th,
.p-datatable.p-datatable-resizable .p-datatable-tfoot > tr > td,
.p-datatable.p-datatable-resizable .p-datatable-data > tr > td {
  overflow: hidden;
}

.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tfoot > tr > td {
  font-weight: normal;
}

.p-datatable tbody {
  outline: 0;
}

.p-datatable .p-sortable-column {
  cursor: pointer;
}

.p-datatable .p-sortable-column-icon {
  display: inline-block;
  margin-left: .125em;
}

.p-datatable tr.p-highlight {
  cursor: pointer;
}

/* Scrollable */
.p-datatable-scrollable-body {
  overflow: auto;
  overflow-anchor: none;
  min-height: 0%;
}

.p-datatable-scrollable-header,
.p-datatable-scrollable-footer {
  overflow: hidden;
}

.p-datatable-scrollable .p-datatable-scrollable-header,
.p-datatable-scrollable .p-datatable-scrollable-footer {
  position: relative;
  border: 0 none;
}

.p-datatable-scrollable .p-datatable-scrollable-header td {
  font-weight: normal;
}

.p-datatable-scrollable-body .p-datatable-data,
.p-datatable-scrollable-body .p-datatable-data > tr:first-child {
  border-top-color: transparent;
}

.p-datatable .p-datatable-data tr.p-state-hover,
.p-datatable .p-datatable-data tr.p-highlight {
  border-color: inherit;
  font-weight: inherit;
  cursor: pointer;
}

.p-datatable .p-datatable-data tr.p-rowgroup-header td a,
.p-datatable .p-datatable-data tr.p-rowgroup-header td span.p-rowgroup-header-name {
  display: inline-block;
  vertical-align: middle;
}

.p-datatable-scrollable-theadclone {
  height: 0;
}

.p-datatable-scrollable-theadclone tr {
  height: 0;
}

.p-datatable-scrollable-theadclone th.p-state-default {
  height: 0;
  border-bottom-width: 0;
  border-top-width: 0;
  padding-top: 0;
  padding-bottom: 0;
  outline: 0 none;
}

.p-datatable-scrollable-theadclone th span.p-column-title {
  display: block;
  height: 0;
}

.p-datatable .p-paginator {
  padding: .125em;
}

.p-datatable .p-paginator-top {
  border-bottom-width: 0;
}

.p-datatable .p-paginator-bottom {
  border-top-width: 0;
}

.p-datatable-rtl {
  direction: rtl;
}

.p-datatable-rtl.p-datatable thead th,
.p-datatable-rtl.p-datatable tfoot td {
  text-align: right;
}

/* Row Toggler */
.p-row-toggler {
  cursor: pointer;
}

/* Resizable */
.p-datatable .p-column-resizer {
  display: block;
  position: absolute !important;
  top: 0;
  right: 0;
  margin: 0;
  width: .5em;
  height: 100%;
  padding: 0px;
  cursor: col-resize;
  border: 1px solid transparent;
}

.p-datatable .p-column-resizer-helper {
  width: 1px;
  position: absolute;
  z-index: 10;
  display: none;
}

.p-datatable-resizable {
  padding-bottom: 1px; /*fix for webkit overlow*/
  overflow: auto;
}

.p-datatable-resizable thead th,
.p-datatable-resizable tbody td,
.p-datatable-resizable tfoot td {
  white-space: nowrap;
}

.p-datatable-resizable th.p-resizable-column {
  background-clip: padding-box;
  position: relative;
}

/** Reflow **/
.p-datatable-reflow .p-datatable-data td .p-column-title {
  display: none;
}

/* Filter */
.p-datatable .p-column-filter {
  display: block;
  width: 100%;
  box-sizing: border-box;
  margin-top: .25em;
}

/* Editing */
.p-datatable .p-editable-column input {
  width: 100%;
  outline: 0;
}

.p-datatable .p-datatable-data > tr > td.p-editable-column {
  padding: .5em;
}

.p-datatable .p-editable-column > .p-cell-editor {
  display: none;
}

.p-datatable .p-datatable-data > tr > td.p-editable-column.p-cell-editing {
  padding: 1px;
}

.p-datatable .p-editable-column.p-cell-editing > .p-cell-editor {
  display: block;
}

.p-datatable .p-editable-column.p-cell-editing > .p-cell-data {
  display: none;
}

.p-datatable-stacked thead th,
.p-datatable-stacked tfoot td {
  display: none !important;
}

.p-datatable.p-datatable-stacked .p-datatable-data > tr > td {
  text-align: left;
  display: block;
  border: 0 none;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  float: left;
  clear: left;
}

.p-datatable.p-datatable-stacked .p-datatable-data.p-widget-content {
  border: 0 none;
}

.p-datatable-stacked .p-datatable-data tr.p-widget-content {
  border-left: 0 none;
  border-right: 0 none;
}

.p-datatable-stacked .p-datatable-data td .p-column-title {
  padding: 0.4em;
  min-width: 30%;
  display: inline-block;
  margin: -0.4em 1em -0.4em -0.4em;
  font-weight: bold;
}

.p-datatable .p-selection-column .p-checkbox,
.p-datatable .p-selection-column .p-radiobutton {
  margin: 0;
  display: block;
}

.p-datatable .p-selection-column .p-checkbox-box,
.p-datatable .p-selection-column .p-radiobutton-box {
  display: block;
  box-sizing: border-box;
  margin: 0;
}

.p-datatable-scrollable-wrapper {
  position: relative;
}

.p-datatable-frozen-view .p-datatable-scrollable-body {
  overflow: hidden;
}

.p-datatable-unfrozen-view {
  position: absolute;
  top: 0px;
}

.p-datatable .p-datatable-load-status {
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}

.p-datatable .p-datatable-virtual-table {
  position: absolute;
  top: 0px;
  left: 0px;
}

.p-datatable .p-datatable-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=10)';
  opacity: 0.1;
  z-index: 1;
}

.p-datatable .p-datatable-loading-content {
  position: absolute;
  left: 50%;
  top: 25%;
  z-index: 2;
}

@media (max-width: 35em) {
  .p-datatable-reflow thead th,
  .p-datatable-reflow tfoot td {
    display: none !important;
  }

  .p-datatable-reflow .p-datatable-data > tr > td {
    text-align: left;
    display: block;
    border: 0 none;
    width: 100% !important;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    float: left;
    clear: left;
  }

  .p-datatable-reflow .p-datatable-data.p-widget-content {
    border: 0 none;
  }

  .p-datatable-reflow .p-datatable-data tr.p-widget-content {
    border-left: 0 none;
    border-right: 0 none;
  }

  .p-datatable-reflow .p-datatable-data td .p-column-title {
    padding: 0.4em;
    min-width: 30%;
    display: inline-block;
    margin: -0.4em 1em -0.4em -0.4em;
    font-weight: bold;
  }

  .p-datatable-reflow.p-datatable-scrollable .p-datatable-scrollable-body colgroup {
    display: block;
  }
}
/* stylelint-enable */
