.collapsable-text {
  .collapsable-text-content {
    position: relative;
    margin-bottom: 0;
    word-wrap: break-word;
    overflow: hidden;

    &[aria-expanded="true"] {
      &:after {
        display: none;
      }
    }

    &:after {
      position: absolute;
      width: 100%;
      height: 6rem;
      bottom: 0;
      left: 0;
      content: '';
      background: linear-gradient(hsla(0,0%,100%,0), var(--collapsed-gradient));
    }
  }

  .cap-button {
    &.primary.link,
    &.primary.link:hover,
    &.primary.link:focus {
      background-color: transparent;
      border: 0;
      box-shadow: none;
      color: var(--cap-primary-color);
      padding: 0;
      font-weight: 400;
    }
  }
}
