.fab-button {
  width: $cap-fab-button-width;
  height: $cap-fab-button-size;
  background-color: $cap-fab-button-background-color;
  border-radius: $cap-fab-button-border-radius;
  display: $cap-fab-button-display;
  justify-content: $cap-fab-button-justify-content;
  align-items: $cap-fab-button-align-items;
  border: $cap-fab-button-border;
  position: $cap-fab-button-position;
  bottom: $cap-fab-button-bottom;
  z-index: $cap-fab-button-z-index;

  .fab-button-icon {
    width: $cap-fab-button-icon-width;
    height: $cap-fab-button-icon-height;
    font-size: toRem($cap-fab-button-icon-size);
    color: $cap-fab-button-icon-color;
    text-align: $cap-fab-button-text-align;
  }
}

.top-right {
  right: $cap-fab-button-top-right-right;
  top: $cap-fab-button-top-right-top;
}

.bottom-left {
  left: $cap-fab-button-bottom-left-left;
  bottom: $cap-fab-button-bottom-left-bottom;
}

.top-left {
  left: $cap-fab-button-bottom-top-left-left;
  top: $cap-fab-button-bottom-top-left-top;
}

.bottom-right {
  right: $cap-fab-button-bottom-right-right;
  bottom: $cap-fab-button-bottom-right-bottom;
}
