.cap-table.p-datatable {
  .p-datatable-tbody > tr,
  .p-datatable-thead > tr {
    .table-actions-col {
      width: $cap-table-actions-col-width;
    }

    .table-expand-col {
      width: $cap-table-expand-col-width;
    }

    & > th,
    & > td {
      background-color: $cap-table-header-table-data-background-color;
      color: $cap-table-header-table-data-color;
      border: $cap-table-header-table-border;
      font-size: toRem($cap-table-header-table-font-size);
      border-bottom: 1px solid $table-border-color;
      padding: $cap-table-header-table-padding;
    }

    & > th {
      font-weight: $cap-table-header-font-weight;
      color: $cap-table-header-color;
      padding: $cap-table-header-padding;

      &:hover {
        background-color: $cap-table-header-hover-background-color;
      }
    }

    td.expand {
      padding: 0.5em 0 0;

      i {
        font-size: 1.5rem;
        line-height: 1rem;
      }
    }
  }

  .p-datatable-caption {
    background-color: $cap-table-caption-background-color;
    border: $cap-table-caption-border;
  }

  .p-inputgroup-addon {
    border-right: $cap-table-inputgroup-addon-border-right;
    color: $cap-table-inputgroup-addon-color;
  }

  .actions-wrapper {
    max-width: $cap-table-actions-wrapper-max-width;
  }

  .actions {
    i {
      color: $cap-table-actions-icon-color;
      margin-right: $cap-table-actions-margin-right;
      cursor: $cap-table-actions-cursor;
    }
  }

  .actions-right {
    padding-left: $cap-table-actions-right-padding-left;
  }

  .p-sortable-column-icon {
    color: $cap-table-sortable-column-icon-color;
  }

  .p-paginator {
    margin-top: $cap-table-paginator-margin-top;
    background-color: $cap-table-paginator-background-color;
    border: $cap-table-paginator-border;
    text-align: $cap-table-paginator-text-align;

    p-dropdown {
      display: $cap-table-dropdown-display;
    }

    .p-paginator-bottom {
      text-align: $cap-table-paginator-bottom-text-align;
    }

    .p-paginator-page {
      color: $cap-table-paginator-page-color;
      font-weight: $cap-table-paginator-font-weight;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      &.p-highlight {
        background: var(--cap-primary-color);
        color: #fff;
      }
    }

    .p-paginator-element {
      background: $cap-table-paginator-element-background;
      color: $cap-table-paginator-element-color;
      border: 1px solid $table-border-color;
      border-left: none;
      font-weight: normal;
    }

    .p-paginator-last,
    .p-paginator-first {
      display: none;
    }

    .p-paginator-prev,
    .p-paginator-next {
      width: $cap-table-paginator-prev-next-width;
      line-height: $cap-table-paginator-prev-next-line-height;
      border: 1px solid $table-border-color;
      cursor: pointer;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      &.p-disabled {
        color: $cap-table-text-color;
      }

      .pi {
        display: none;
      }
    }

    .p-paginator-prev {
      margin-right: $cap-table-paginator-prev-margin-right;
      border-top-left-radius: $cap-table-paginator-prev-border-tl-radius;
      border-bottom-left-radius: $cap-table-paginator-prev-border-left-radius;

      &:before {
        position: absolute;
        top: 1px;
        left: 10px;
        content: '\f053';
        font-weight: 900;
        font-family: 'Font Awesome 5 Pro';
      }
    }

    .p-paginator-next {
      margin-left: $cap-table-paginator-next-margin-left;
      border-top-right-radius: $cap-table-paginator-next-border-tr-radius;
      border-bottom-right-radius: $cap-table-paginator-next-border-br-radius;

      &:before {
        content: '\f054';
        font-weight: 900;
        font-family: 'Font Awesome 5 Pro';
      }
    }
  }

  cap-dropdown {
    .form-element {
      display: inline-block;
    }
  }

  td div.divider cap-dropdown {
    .form-element {
      display: block;
    }
  }

  cap-dropdown .p-dropdown:not(.num-rows-dropdown) {
    width: 100% !important; /* stylelint-disable-line declaration-no-important */
  }
}

.filter-toggle {
  font-size: toRem($cap-table-filter-toggle-font-size);
  cursor: $cap-table-filter-toggle-cursor;
}

.filter-icon {
  position: $cap-table-filter-icon-position;
  top: $cap-table-filter-icon-top;
  left: $cap-table-filter-icon-left;
  height: $cap-table-filter-icon-height;
  display: $cap-table-filter-icon-display;
  flex-direction: $cap-table-filter-icon-flex-direction;
  justify-content: $cap-table-filter-icon-flex-justify-content;

  i {
    color: $cap-table-filter-icon-color;
    opacity: $cap-table-filter-icon-opacity;
    font-size: toRem($cap-table-filter-font-size);
    line-height: $cap-table-filter-line-height;
  }
}

.reset-filter {
  position: $cap-table-reset-filter-position;
  top: $cap-table-reset-filter-top;
  right: $cap-table-reset-filter-right;
  height: $cap-table-reset-filter-height;
  display: $cap-table-reset-filter-display;
  flex-direction: $cap-table-reset-filter-flex-direction;
  justify-content: $cap-table-reset-filter-justify-content;
  cursor: $cap-table-reset-filter-cursor;

  i {
    background: $cap-table-reset-filter-background;
    color: $cap-table-reset-filter-color;
    opacity: $cap-table-reset-filter-opacity;
    font-size: toRem($cap-table-reset-filter-font-size);
    line-height: $cap-table-reset-filter-line-height;
  }
}

cap-input.has-icon .p-inputgroup input.p-inputtext,
cap-input.has-icon .p-inputgroup input.p-inputtext:enabled:focus:not(.p-state-error),
cap-input.has-icon .p-inputgroup input.p-inputtext:enabled:hover:not(.p-state-error),
cap-multi-select.has-icon .p-multiselect .p-multiselect-label {
  padding-left: $cap-table-multiselect-label-padding-left;
}

div.cap-table-filter cap-input.has-icon .form-element .p-inputgroup input.p-inputtext {
  padding-left: $cap-table-multiselect-label-input-text-padding-left;
  height: auto;
  min-height: toRem(38px);
}

.table-filters {
  display: $cap-table-filters-display;

  .fa-stack {
    color: var(--cap-primary-color);
    font-size: toRem($cap-table-filters-font-size);
    cursor: $cap-table-filters-cursor;
  }

  .filters-label {
    display: $cap-table-filters-label-display;
    flex-grow: $cap-table-filters-label-flex-grow;
    margin-left: $cap-table-filters-label-margin-left;

    i {
      color: $cap-table-filters-label-color;
    }
  }
}

.table-filters-dialog {
  width: $cap-table-filters-dialog-width;

  .table-filters-settings {
    padding: $cap-table-filters-settings-padding;

    header {
      display: $cap-table-filters-header-display;
      justify-content: $cap-table-filters-header-justify-content;
      position: $cap-table-filters-header-position;
      margin-bottom: $cap-table-filters-header-margin-bottom;

      .close-filters {
        position: $cap-table-filters-header-close-filters-position;
        right: $cap-table-filters-header-close-filters-right;
      }
    }

    .items {
      min-height: $cap-table-filters-items-min-height;
      max-height: $cap-table-filters-items-max-height;
      overflow-y: $cap-table-filters-items-overflow-y;
      @include setBottomGutters(10px);

      .item {
        width: $cap-table-filters-item-width;
        border: $cap-table-filters-item-border;
        padding: $cap-table-filters-item-padding;
        border-radius: $cap-table-filters-item-border-radius;

        &.selected {
          border-color: $cap-table-filters-item-selected-border-color;
        }

        .p-checkbox .p-checkbox-box {
          border-color: $cap-table-filters-item-checkbox-border-color;
        }

        .p-checkbox .p-checkbox-box.p-highlight,
        .p-checkbox .p-checkbox-box.p-highlight.p-focus,
        .p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover,
        .p-checkbox .p-checkbox-box.p-highlight.p-focus:not(.p-disabled):hover {
          border-color: var(--success-color);

          .p-checkbox-icon:before {
            color: var(--success-color);
          }
        }
      }
    }
  }
}

.item {
  cursor: $cap-table-item-cursor;

  .form-element {
    height: $cap-table-item-form-element-height;

    .item-label {
      font-weight: $cap-table-item-label-font-weight;
      cursor: $cap-table-item-label-cursor;
    }
  }
}

@include media-breakpoint-down(xs) {
  .table-filters-dialog {
    width: 100%;

    .table-filters-settings {
      padding: 5px;

      header {
        justify-content: space-between;

        .close-filters {
          position: relative;
        }
      }
    }
  }
}

.table-list {
  .columns-list {
    width: $cap-table-columns-list-width;
    text-align: $cap-table-columns-list-text-align;

    i {
      cursor: $cap-table-columns-list-icon-cursor;
      color: $cap-table-columns-list-color;
    }
  }
}

.cap-table:not(.rowsWithTheSameHeight) {
  .p-datatable-frozen-view,
  .p-datatable-unfrozen-view {
    td,
    th {
      .divider {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.p-datatable-frozen-view {
  .empty-message span {
    display: none;
  }

  .p-datatable-thead tr th:last-child {
    box-shadow: $cap-table-frozen-column-shadow;
  }
}

.p-datatable-scrollable-header {
  position: relative;

  th.columns-list {
    i {
      position: absolute;
      left: calc(100% - 30px);
      top: 10px;
    }
  }
}

.p-datatable-scrollable-body {
  .empty-manageable-column {
    display: none;
  }
}

@media screen and (max-width: 40em) {
  .p-datatable-frozen-view {
    display: none;
    width: 0 !important; /* stylelint-disable-line declaration-no-important */
  }

  .p-datatable-unfrozen-view {
    position: relative !important; /* stylelint-disable-line declaration-no-important */
    width: 100% !important; /* stylelint-disable-line declaration-no-important */
    left: auto !important; /* stylelint-disable-line declaration-no-important */

    .p-datatable-scrollable-body {
      max-height: none !important; /* stylelint-disable-line declaration-no-important */
    }

    .list-page-table-column {
      width: 100% !important; /* stylelint-disable-line declaration-no-important */
    }
  }
}

.p-datatable-unfrozen-view {
  .frozen-cell {
    display: none;
  }

  @media screen and (max-width: 40em) {
    .frozen-cell {
      display: block;
    }

    .empty-message span {
      margin-left: auto !important; /* stylelint-disable-line declaration-no-important */
    }
  }
}
