.p-datepicker .p-timepicker {
  display: none;
}

.p-datepicker .p-datepicker-header {
  border-bottom: $cap-calendar-datepicker-border-bottom;

  .p-datepicker-title {
    font-family: $cap-calendar-datepicker-title-font-family;
    font-size: toRem($cap-calendar-datepicker-title-font-size);
    font-weight: $cap-calendar-datepicker-title-font-weight;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: $cap-calendar-datepicker-title-text-align;
    color: $cap-calendar-datepicker-title-color;

    .p-datepicker-month {
      margin-right: 0.5rem;
    }
  }

  .p-datepicker-prev,
  .p-datepicker-next {
    color: $cap-calendar-datepicker-prev-next-color;
  }
}

.p-fluid .p-calendar.p-calendar-w-btn input.p-inputtext {
  width: $cap-calendar-input-text-width;
  outline: none;
  height: $cap-calendar-input-text-height;
}

.p-calendar.p-calendar-w-btn {
  width: 100%;

  > .p-inputtext {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: $cap-calendar-input-border;
    border-right: none;
    height: $cap-calendar-input-height;
    width: $cap-calendar-input-width;
  }

  > .p-inputtext:enabled:hover:not(.p-state-error),
  > .p-inputtext:enabled:focus:not(.p-state-error) {
    border: $cap-calendar-input-error-border;
    border-right: $cap-calendar-input-error-border-right;
  }

  .p-datepicker-trigger.p-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border: $cap-calendar-datepicker-trigger-button-border;
    border-left: none;
    box-shadow: none;
    background: $cap-calendar-datepicker-trigger-background;
    color: $cap-calendar-datepicker-trigger-color;
    width: $cap-calendar-datepicker-trigger-width;
    height: $cap-calendar-datepicker-trigger-height;

    span.p-button-icon-left {
      font-size: toRem($cap-calendar-datepicker-trigger-icon-left);
    }
  }

  div.p-datepicker.p-component.p-widget-content {
    td {
      text-align: $cap-calendar-datepicker-widget-content-text-align;
      padding: 0;

      .p-state-default {
        width: $cap-calendar-datepicker-widget-content-state-default-width;
        display: inline-block;
        border-radius: $cap-calendar-datepicker-widget-content-state-default-border-radius;

        &:not(.p-highlight) {
          background: initial;
          box-shadow: none;
        }
      }

      > a.p-highlight {
        background-color: $cap-calendar-datepicker-widget-content-state-active-background-color;
        color: $cap-calendar-datepicker-widget-content-state-active-color;
      }

      a:not(.p-highlight):hover,
      a:not(.p-highlight):focus {
        background-color: $cap-calendar-datepicker-widget-content-state-not-active-background-color;
        color: $cap-calendar-datepicker-widget-content-state-not-active-color;
      }
    }
  }
}

.cap-calendar.p-calendar.cap-rounded:hover,
.cap-calendar.p-calendar.cap-rounded {
  input, input:enabled:hover:not(.p-state-error) {
    border-color: $cap-calendar-input-hover-border-color;
    border-top-left-radius: $cap-calendar-input-hover-border-tl-radius;
    border-bottom-left-radius: $cap-calendar-input-hover-border-bl-radius;
    height: $cap-calendar-input-hover-border-height;
  }

  .p-datepicker-trigger.p-button {
    border-color: $cap-calendar-input-hover-trigger-border-color;
    border-top-right-radius: $cap-calendar-input-hover-trigger-border-tr-radius;
    border-bottom-right-radius: $cap-calendar-input-hover-trigger-border-br-radius;
    height: $cap-calendar-input-hover-trigger-height;
    font-size: toRem($cap-calendar-input-hover-trigger-font-size);
    padding-top: $cap-calendar-input-hover-trigger-padding-top;

    span.p-button-icon-left {
      font-size: toRem($cap-calendar-input-hover-trigger-button-icon-left-font-size);
    }
  }
}

.double-date {
  .cap-calendar.p-calendar.cap-rounded:hover,
  .cap-calendar.p-calendar.cap-rounded {
    width: $cap-calendar-double-date-width;
  }
}

.cap-calendar.cap-rounded.p-calendar.p-calendar-w-btn,
.cap-calendar.cap-rounded.p-calendar.p-calendar-w-btn:hover {
  border: $cap-calendar-w-btn-hover-border;
  border-radius: $cap-calendar-w-btn-hover-border-radius;
  background-color: $cap-calendar-w-btn-hover-background-color;
  height: $cap-calendar-w-btn-hover-height;

  .p-datepicker-trigger.p-button {
    position: absolute;
    background-color: $cap-calendar-w-btn-hover-datepicker-background-color;
    color: $cap-calendar-w-btn-hover-datepicker-color;
    left: $cap-calendar-w-btn-hover-datepicker-left;
    border: none;
    border-radius: $cap-calendar-w-btn-hover-datepicker-border-radius;
    opacity: $cap-calendar-w-btn-hover-datepicker-opacity;
    height: 100%;
    width: $cap-calendar-w-btn-hover-datepicker-width;
  }

  input:enabled:hover:not(.p-state-error),
  input:enabled:focus:not(.p-state-error),
  input {
    position: absolute;
    left: $cap-calendar-w-btn-hover-input-left;
    width: $cap-calendar-w-btn-hover-input-width;
    border: none;
    border-radius: $cap-calendar-w-btn-hover-input-border-radius;
    height: 100%;
  }
}

.p-datepicker table td > span.p-highlight {
  background: var(--cap-primary-color);
}

.p-float-label {
  &.calendar-float-label {
    .p-calendar.p-calendar-w-btn > .p-inputtext {
      border: $floating-input-border;
      border-radius: 3px;
      color: $floating-placeholder-text-color;
      font-family: $cap-primary-font;

      &:focus {
        outline: none;
      }
    }

    /* Styles for label when static */
    label {
      &.cpb-float-label {
        color: #b7b7b7;
        font-size: 1rem;
        font-weight: 400;
        font-family: $cap-primary-font;
      }
    }

    /* Styles for label when floating */
    .p-inputwrapper-focus + label,
    .populated + label {
      font-size: 0.81rem;

      &.cpb-float-label {
        position: absolute;
        background-color: $white;
        padding-right: 6px;
        padding-left: 6px;
        transform: translateY(11px);
      }
    }
  }
}

cap-calendar.ng-invalid.ng-dirty {
  .p-calendar.p-calendar-w-btn > .p-inputtext,
  .p-calendar.p-calendar-w-btn > .p-inputtext:enabled:focus:not(.p-state-error),
  .p-calendar.p-calendar-w-btn > .p-inputtext:enabled:hover {
    border: 1px solid $cap-danger;
  }

  .p-inputwrapper-focus + label {
    &.cpb-float-label {
      color: $cap-danger;
    }
  }
}
