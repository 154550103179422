// TODO p-inputMask does not exists it should be p-inputmask selector type.
p-inputMask { /* stylelint-disable */
  width: $cap-input-mask-width;

  .p-inputtext {
    width: $cap-input-mask-text-width;
  }
}

body .p-inputgroup input.p-inputtext {
  width: auto;
}
