@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';

cap-editor quill-editor {
  .ql-editor {
    min-height: $cap-editor-min-height;
    max-height: $cap-editor-max-height;
  }

  .ql-container {
    background-color: $cap-white;
    border-color: $cap-editor-tox-border-color;
    border-radius: 0 0 $cap-editor-tox-border-radius $cap-editor-tox-border-radius;
  }

  .ql-toolbar.ql-snow, .ql-toolbar.ql-bubble {
    background-color: $cap-editor-tox-toolbar-background;
    border-radius: $cap-editor-tox-border-radius $cap-editor-tox-border-radius 0 0;
    border-color: $cap-editor-tox-border-color;

    .ql-stroke {
      stroke: $cap-editor-tox-toolbar-color;
    }

    .ql-fill {
      fill: $cap-editor-tox-toolbar-color;
    }

    .ql-picker {
      border-radius: $cap-editor-tox-border-radius;
      background-color: $cap-white;
    }

    .ql-picker-label {
      color: $cap-editor-tox-toolbar-color;
    }

    button:hover,
    button:focus,
    button.ql-active {
      background-color: var(--cap-primary-color);
      border-radius: $cap-editor-tox-border-radius;

      .ql-stroke {
        stroke: $cap-white;
      }

      .ql-fill {
        fill: $cap-white;
      }

    }

    .ql-picker-label:hover,
    .ql-picker-label.ql-active,
    .ql-picker-item:hover,
    .ql-picker-item.ql-selected {
      color: var(--cap-primary-color);

      .ql-stroke {
        stroke: var(--cap-primary-color);
      }

      .ql-fill {
        fill: var(--cap-primary-color);
      }
    }
  }
}
