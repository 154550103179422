body .cap-chips.p-chips {
  ul.p-inputtext,
  ul.p-inputtext:not(.p-disabled):hover,
  ul.p-inputtext:not(.p-disabled).p-focus {
    width: $cap-chips-width-hover;
    border-color: $cap-chips-border-color-hover;
    padding: $cap-chips-border-padding-hover;
  }

  ul.p-inputtext {
    &.p-chips-multiple-container {
      border-radius: 3px;
    }

    .p-chips-token {
      border-radius: 16px;
      border: 1px solid $cap-chips-token-border-color;
      padding: $cap-chips-token-padding;
      background-color: $cap-chips-token-background-color;
      color: $cap-chips-token-color;

      .p-chips-token-icon {
        font-family: 'Font Awesome 5 Pro';
        font-size: 1rem;
        margin-left: 0.5rem;

        &::before {
          content: '\f00d';
          font-weight: 300;
        }
      }
    }
  }
}
