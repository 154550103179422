body .cap-select-button.p-selectbutton {
  border-color: $cap-select-button-border-color;

  .p-button:not(.p-disabled):not(.p-highlight):hover,
  .p-button {
    background-color: $cap-select-button-background-color;
    border-color: $cap-select-button-border-color;
    color: $cap-select-button-color;
    font-weight: $cap-select-button-font-weight;
    padding: $cap-select-button-padding;
    font-size: toRem($cap-select-button-font-size);

    &:not(.p-disabled):not(.p-highlight):hover {
      text-decoration: $cap-select-button-hover-text-decoration;
    }

    &.p-highlight {
      background-color: $cap-select-button-active-background-color;
      color: $cap-select-button-active-color;
    }
  }
}
