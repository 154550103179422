.prime-file-upload {
  padding: $cap-file-upload-padding;
  width: $cap-file-upload-width;
  height: $cap-file-upload-height;
  background-color: $cap-file-upload-background-color;
  box-shadow: $cap-file-upload-box-shadow;
  display: $cap-file-upload-display;
  flex-direction: $cap-file-upload-flex-direction;
  justify-content: $cap-file-upload-justify-content;
  color: $cap-file-upload-color;
  cursor: pointer;
  font-weight: $cap-file-upload-font-weight;

  &.dragover {
    border: $cap-file-upload-dragover-border;
  }

  i {
    opacity: $cap-file-upload-icon-opacity;
    font-size: toRem($cap-file-upload-icon-font-size);
    display: $cap-file-upload-icon-display;
    margin-bottom: $cap-file-upload-icon-margin-bottom;
  }
}
