.navigation-button {
  display: inline-block;

  &__content {
    background: $cap-white;
    border-radius: 3px;
    border: 1px solid var(--cap-primary-color);
    box-shadow: 0 2px 4px 0 rgba(var(--cap-primary-color), 0.25);
    display: flex;
    align-items: center;
    height: 40px;
  }

  &__back,
  &__next {
    z-index: 901;
    cursor: pointer;
    width: 44px;
    height: 100%;
    color: var(--cap-primary-color);
    @include flexCentering();
  }

  &__back {
    border-right: 1px solid $cap-gray;
  }

  &__next {
    border-left: 1px solid $cap-gray;
  }

  &__title {
    color: var(--cap-primary-color);
    font-size: toRem(16px);
    font-weight: bold;
    padding: 0 25px;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
  }
}
