label {
  font-weight: $cap-range-filters-label-font-weight;
  font-size: toRem($cap-range-filters-label-font-size);
  color: $cap-range-filters-label-color;
}

.range-filter-panel.p-overlaypanel {
  z-index: $cap-range-filters-overlay-panel-z-index;
  box-shadow: $cap-range-filters-overlay-panel-box-shadow;
  background-color: $cap-range-filters-overlay-panel-background-color;
  border-radius: $cap-range-filters-overlay-panel-border-radius;
  min-width: $cap-range-filters-overlay-panel-min-width;

  &:before,
  &:after {
    border: none;
    margin: 0;
    content: none;
  }
}

cap-input.border-left-0 {
  input.p-inputtext {
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.range-filter-button {
  background-color: $cap-range-filters-button-background-color;
  color: $cap-range-filters-button-color;
  border: $cap-range-filters-button-border;
  border-radius: $cap-range-filters-button-border-radius;
  height: $cap-input-height;
  font-size: toRem($cap-range-filters-button-font-size);
  cursor: $cap-range-filters-button-cursor;
  padding: $cap-range-filters-button-padding;
  display: $cap-range-filters-button-display;
  flex-direction: $cap-range-filters-button-flex-direction;
  justify-content: $cap-range-filters-button-justify-content;

  i.pre-value-icon {
    color: $cap-range-filters-button-pre-value-icon-color;
    opacity: $cap-range-filters-button-pre-value-icon-opacity;
    margin-right: $cap-range-filters-button-pre-value-icon-margin-right;
  }

  i[class*='chevron'] {
    color: $cap-range-filters-button-icon-chevron-color;
    opacity: $cap-range-filters-button-icon-chevron-opacity;
    font-size: toRem($cap-range-filters-button-icon-chevron-font-size);
    margin-right: $cap-range-filters-button-icon-chevron-margin-right;
    margin-top: $cap-range-filters-button-icon-chevron-margin-top;
  }
}

cap-range-filter.has-icon {
  .range-filter-button {
    padding-left: $cap-range-filters-button-padding-left;
  }
}
