cap-form-card {
  .form-card {
    border-radius: $cap-form-card-border-radius;
    box-shadow: $cap-form-card-box-shadow;
    border: $cap-form-card-border;
    background-color: $cap-form-card-background-color;
    padding: $cap-form-card-padding;

    .form-card__header {
      display: flex;
      align-items: center;
      border-bottom: $cap-form-card-header-border-bottom;
      padding-bottom: $cap-form-card-header-padding-bottom;

      &.header--empty {
        display: none;
      }

      .header__title {
        flex: 1;

        h2 {
          font-size: $cap-form-card-header-font-size;
          font-weight: $cap-form-card-header-font-weight;
          margin-bottom: 0;
        }
      }

      .header__cta {
        .cap-button {
          margin-left: .5rem;
        }

        .icon-only {
          .cap-button {
            padding: 0;
            border-radius: 50%;
            width: 36px;
            height: 36px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-size: 1rem;
            line-height: 1;
          }
        }
      }
    }

    .form-card__content {
      padding-top: $cap-form-card-content-padding-top;
    }
  }
}
