$dropdown-hover-color: #eaeaea;

body .p-autocomplete {
  width: 100%;

  button {
    background-color: $cap-autocomplete-button-background-color;
    color: $cap-autocomplete-button-color;
    height: 100%;
    vertical-align: baseline;
    border: $cap-autocomplete-button-border;
  }

  .p-autocomplete-input {
    height: $cap-autocomplete-input-height;
    width: 100%;
    border: $cap-autocomplete-input-border;
    padding: $cap-autocomplete-input-padding;
    padding-left: $cap-autocomplete-button-width;
  }

  .p-button.p-button-icon-only {
    width: $cap-autocomplete-button-width;
    height: 100%;
    position: absolute;
    right: 0;
  }

  #p-highlighted-option { /* stylelint-disable */
    background-color: $cap-autocomplete-highlighted-option-background-color;
    color: $cap-autocomplete-highlighted-option-color;
  }

  button:enabled:hover {
    background-color: $cap-autocomplete-button-hover-background-color;
    color: $cap-autocomplete-button-hover-color;
    border-color: $cap-autocomplete-button-border-color;
  }

  .prime-input-style:enabled:focus:not(.p-state-error),
  .prime-input-style:enabled:hover:not(.p-state-error) {
    border-color: $cap-autocomplete-input-error-border-color;
  }
}

.p-float-label {
  &.autocomplete-float-label {
    .p-autocomplete {
      .p-autocomplete-input {
        border: $floating-input-border;
        border-radius: 3px;
        color: $floating-placeholder-text-color;
        font-family: SourceSansPro-Regular, sans-serif;
        font-size: 1rem;

        &:focus {
          outline: none;
        }
      }

      .p-autocomplete-loader {
        margin-left: 6px;
      }

      .p-autocomplete-dropdown {
        border-left: none;
        border-right: none;
        margin-right: 10px;
        width: unset;
        padding: 0;

        .p-button-icon {
          color: $floating-placeholder-text-color;
          font-size: 0.88rem;
        }
      }
    }

    /* Styles for label when static */
    label {
      &.cpb-float-label {
        color: #b7b7b7;
        font-size: 1rem;
        font-weight: 400;
        font-family: SourceSansPro-Regular, sans-serif;
      }
    }

    /* Styles for label when floating */
    .p-inputwrapper-focus + label,
    .p-inputwrapper-filled + label {
      font-size: 0.81rem;

      &.cpb-float-label {
        position: absolute;
        background-color: $white;
        padding-right: 6px;
        padding-left: 6px;
        transform: translateY(11px);
      }
    }
  }
}

/* Styles for required label when floating */
cap-autocomplete.ng-invalid {
  .p-float-label {
    p-autocomplete.ng-invalid.ng-touched {
      .p-autocomplete .p-autocomplete-input {
        border: 1px solid $cap-danger;
      }

      .p-autocomplete-dropdown {
        border-bottom-color: $cap-danger;
        border-top-color: $cap-danger;
      }
    }

    &.autocomplete-float-label .p-inputwrapper-focus.ng-invalid.ng-touched + label {
      &.cpb-float-label {
        color: $cap-danger;
      }
    }
  }
}
