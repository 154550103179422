/* stylelint-disable */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: url(getFontPath('SourceSansPro-Light.ttf'));
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url(getFontPath('SourceSansPro-Regular.ttf'));
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: url(getFontPath('SourceSansPro-SemiBold.ttf'));
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: url(getFontPath('SourceSansPro-Bold.ttf'));
}
/* stylelint-enable */
