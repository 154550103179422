body cap-progress-bar .p-progressbar {
  border: $cap-progress-bar-border;
  height: $cap-progress-bar-height;
  background-color: $cap-progress-bar-background-color;

  .p-progressbar-value {
    height: $cap-progress-bar-progressbar-value-height;
    position: $cap-progress-bar-progressbar-value-position;
    border: $cap-progress-bar-progressbar-value-border;
    margin: $cap-progress-bar-progressbar-value-margin;
    background: $cap-progress-bar-progressbar-value-background;
  }

  .p-progressbar-label {
    height: $cap-progress-bar-label-height;
    width: $cap-progress-bar-label-width;
    position: $cap-progress-bar-label-position;
    margin-top: $cap-progress-bar-label-margin-top;
    text-align: $cap-progress-bar-label-text-align;
    font-weight: $cap-progress-bar-label-font-weight;
    color: $cap-progress-bar-label-color;
    line-height: $cap-progress-bar-label-line-height;
  }
}
