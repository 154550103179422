.timeline__cards {
  &.timeline__divider {
    padding-left: $cap-timeline-cards-divider-padding-left;
    position: $cap-timeline-cards-divider-position;

    &:before {
      content: '';
      position: $cap-timeline-cards-divider-before-position;
      top: $cap-timeline-cards-divider-before-top;
      left: $cap-timeline-cards-divider-before-left;
      width: $cap-timeline-cards-divider-before-width;
      height: $cap-timeline-cards-divider-before-height;
      border-left: $cap-timeline-cards-divider-before-border-left;
    }
  }
}

.timeline__card {
  margin-bottom: $cap-timeline-card-margin-bottom;

  &:last-of-type {
    margin-bottom: $cap-timeline-card-last-of-type-margin-bottom;
  }
}

.timeline__content {
  padding: $cap-timeline-content-padding;
  border-radius: $cap-timeline-content-border-radius;
  box-shadow: $cap-timeline-content-box-shadow;
  background-color: $cap-timeline-content-background-color;
}

.timeline__created {
  color: $cap-timeline-created-color;
  font-size: toRem($cap-timeline-created-font-size);
}

.timeline__title {
  font-weight: $cap-timeline-title-font-weight;
  color: $cap-timeline-title-color;
  font-size: toRem($cap-timeline-title-font-size);
}

.timeline__comment {
  span {
    font-size: toRem($cap-timeline-comment-font-size);
    color: $cap-timeline-comment-color;
  }

  a {
    color: $cap-timeline-comment-link-color;
  }
}
