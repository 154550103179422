.colorpicker-input {
  display: $cap-colorpicker-input-display;

  .p-inputtext.colorpicker-input-border {
    border-left: $cap-colorpicker-input-border-left;
  }
}

.colorpicker-with-input {
  border-right: $cap-colorpicker-with-input-border-right;
}

.p-colorpicker-preview {
  width: $cap-colorpicker-preview-width;
  height: $cap-colorpicker-preview-height;
}
