@import '../abstracts/variables';

.cap-image-card-image-container {
  background-size: cover;
  background-position: center;
  height: 150px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.cap-image-card-footer-container {
  background-color: var(--cap-primary-color);
  padding: 10px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.cap-image-card-content-container {
  padding: 10px;
}

.cap-image-card {
  border-radius: 5px;
  box-shadow: $cap-card-box-shadow;
}
