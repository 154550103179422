@mixin btnStyle($background, $color, $boxShadowColor, $borderColor: '') {
  &:not(:disabled) {
    background-color: $background;
    color: $color;
    line-height: 1;
    box-shadow: 0 2px 4px 0 $boxShadowColor;
    border: 2px solid $borderColor;

    &:focus {
      outline: none;
    }
  }
}

@mixin btnHoverStyle($hoverBackground, $hoverColor, $boxShadowColorHover, $borderColorHover: '') {
  &:not(:disabled):hover,
  &:enabled:hover {
    background-color: $hoverBackground;
    color: $hoverColor;
    box-shadow: 0 2px 4px 0 $boxShadowColorHover;
    border-color: $borderColorHover;

    span:not(.p-button-icon) {
      text-decoration: underline;
    }
  }

  &:disabled {
    border: 2px solid transparent;
    background: $cap-button-disabled-background;
    box-shadow: 0 2px 4px 0 $cap-button-disabled-background;
    color: $cap-button-disabled-text-color;
    cursor: default;
    line-height: 1;
  }
}

.p-button.cap-button {
  border-radius: 3px;
  font-weight: bold;
  padding: 0.2rem 20px;
  font-size: 1em;
}

.cap-button.primary {
  @include btnStyle(var(--cap-primary-color), $cap-button-label-color, var(--cap-primary-color-shadow), var(--cap-primary-color));
  @include btnHoverStyle(var(--cap-primary-color), $cap-button-label-color, var(--cap-primary-color-shadow), var(--cap-primary-color));
}

.cap-button.secondary {
  @include btnStyle($cap-button-secondary-background, var(--cap-primary-color), var(--cap-primary-color-shadow), var(--cap-primary-color));
  @include btnHoverStyle($cap-button-secondary-background, var(--cap-primary-color), var(--cap-primary-color-shadow), var(--cap-primary-color));
}

.cap-button.success {
  @include btnStyle($cap-button-success-background, $cap-button-success-text-color, var(--success-color-shadow), $cap-button-success-background);
  @include btnHoverStyle($cap-button-success-background, $cap-button-success-text-color, var(--success-color-shadow), $cap-button-success-background);
}

.cap-button.error {
  @include btnStyle($cap-button-error-background, $cap-button-error-text-color, var(--error-color-shadow), $cap-button-error-background);
  @include btnHoverStyle($cap-button-error-background, $cap-button-error-text-color, var(--error-color-shadow), $cap-button-error-background);
}

.cap-button.warning {
  @include btnStyle($cap-button-warning-background, $cap-button-warning-text-color, var(--warning-color-shadow), $cap-button-warning-background);
  @include btnHoverStyle($cap-button-warning-background, $cap-button-warning-text-color, var(--warning-color-shadow), $cap-button-warning-background);
}

.cap-button.tertiary {
  @include btnStyle($cap-button-tertiary-background, $cap-button-tertiary-text-color, var(--tertiary-color-shadow), $cap-button-tertiary-background);
  @include btnHoverStyle($cap-button-tertiary-background, $cap-button-tertiary-text-color, var(--tertiary-color-shadow), $cap-button-tertiary-background);
}

.cap-button.default {
  background-color: $cap-light-gray;
  border: none;
  color: var(--cap-primary-color);
  outline: none;

  span.p-button-label.p-clickable {
    padding: 0.25em 1em 0.25em 0;
  }
}

.cap-button.round {
  border-radius: 16px;
}

.cap-button.cap-button--loading {
  opacity: 0.8;
  cursor: wait;
  text-decoration: none;
}

body .p-button.p-button-label-icon-left .p-button-label,
body .p-button.p-button-label-icon-right .p-button-label,
.p-button .p-button-label {
  padding-top: 0.429em; /* stylelint-disable-line number-max-precision */
  padding-bottom: 0.429em; /* stylelint-disable-line number-max-precision */
}

.p-button-icon-left {
  margin-right: 1em;
}

.p-button-icon-right {
  margin-left: 1em;
}

button.cap-button, [type='button'], [type='reset'], [type='submit'] {
  -webkit-appearance: unset; /* stylelint-disable-line */
}
