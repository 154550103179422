body {
  .p-checkbox .p-checkbox-box {
    border: 1px solid $cap-checkbox-border-color;
    background: $cap-white;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;

    &.p-disabled {
      cursor: not-allowed !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  .p-checkbox .p-checkbox-box:not(.p-disabled):hover {
    border-color: $cap-checkbox-border-color-hover;
  }

  .p-checkbox .p-checkbox-box.p-highlight,
  .p-checkbox .p-checkbox-box.p-highlight.p-focus,
  .p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover,
  .p-checkbox .p-checkbox-box.p-highlight.p-focus:not(.p-disabled):hover {
    background: $cap-checkbox-state-active-background;
    border-color: $cap-checkbox-state-active-border-color;

    // From Primeng 16, the styling of p-multiselect checkboxes looks different from usual p-checbkox
    checkicon {
      width: toRem(14px);
      height: toRem(14px);
      background-color: var(--cap-primary-color);
      border-radius: 2px;

      svg {
        display: none;
      }
    }

    .p-checkbox-icon {
      font-family: 'Font Awesome 5 Pro';
      font-weight: $cap-checkbox-state-active-icon-font-weight;
      font-size: toRem($cap-checkbox-state-active-icon-font-size);

      &:before {
        content: '\f0c8';
        color: var(--cap-primary-color);
      }
    }
  }
}
