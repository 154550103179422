.cap-dropdown.p-dropdown,
.cap-multi-select.p-multiselect {
  .p-inputtext {
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &,
  &.p-dropdown:not(.p-disabled):hover,
  &.p-dropdown:not(.p-disabled).p-focus,
  &.p-multiselect:not(.p-disabled):hover,
  &.p-multiselect:not(.p-disabled).p-focus {
    background: $cap-dropdown-background;
    border: $cap-dropdown-border;
    transition: $cap-dropdown-transition;
    width: 100%;
    height: $cap-dropdown-height;

    .p-multiselect-label-container,
    .p-dropdown-label-container {
      height: $cap-dropdown-label-container-height;
    }

    .p-dropdown-label,
    .p-multiselect-label {
      padding-right: $cap-dropdown-multiselect-label-padding-right;
      height: $cap-dropdown-multiselect-label-height;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include setRightGutters(5px);

      .p-multiselect-token {
        background: var(--cap-primary-color);
        color: $cap-white;
        padding: 2px 5px;
        border-radius: 25px;

        .p-multiselect-token-label {
          margin-right: 5px;
        }
      }

      .selected-img {
        width: $cap-dropdown-multiselect-selected-img-width;
        vertical-align: $cap-dropdown-multiselect-selected-img-vertical-align;
      }

      .selected-icon {
        font-size: $cap-dropdown-multiselect-selected-icon-font-size;
        color: var(--cap-primary-color);
        vertical-align: $cap-dropdown-multiselect-selected-img-vertical-align;
      }

      .selected-label-icon {
        vertical-align: $cap-dropdown-multiselect-selected-label-vertical-align;
        margin-left: $cap-dropdown-multiselect-selected-label-margin-left;
        line-height: $cap-dropdown-multiselect-selected-line-height;
      }

      .selected-label {
        vertical-align: $cap-dropdown-multiselect-selected-label-vertical-align;
        margin-left: $cap-dropdown-multiselect-selected-label-margin-left;
      }
    }

    .p-dropdown-trigger,
    .p-multiselect-trigger {
      background-color: $cap-dropdown-multiselect-trigger-background-color;
      width: $cap-dropdown-multiselect-trigger-width;
      line-height: $cap-dropdown-multiselect-trigger-line-height;
      text-align: $cap-dropdown-multiselect-trigger-text-align;
      padding: 0;
      color: $cap-dropdown-multiselect-trigger-color;
      border-left: $cap-dropdown-multiselect-trigger-border-left;
    }

    .p-dropdown-clear-icon,
    .p-multiselect-clear-icon {
      color: $cap-dropdown-multiselect-clear-icon;
    }

    .p-dropdown-clearable .p-dropdown-label {
      padding-right: $cap-dropdown-multiselect-cap-dropdown-label-padding-right;
    }
  }

  .p-multiselect-filter-container {
    border-bottom: none;
  }

  .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
    padding: $cap-dropdown-multiselect-input-padding;
    border: none;
  }

  .p-multiselected-icon {
    color: $cap-dropdown-multiselect-icon-color;
    margin-right: $cap-dropdown-multiselect-icon-margin-right;
  }
}

body .p-dropdown-panel {
  .p-dropdown-items-wrapper {
    .p-dropdown-items {
      .p-dropdown-item {
        .cap-dropdown-item-with-icon {
          .item-icon {
            margin-right: .5rem;
            color: var(--cap-primary-color);
          }
        }
      }
    }
  }
}

body .p-multiselect .p-checkbox .p-checkbox-box,
body .p-multiselect .p-checkbox .p-checkbox-box:hover {
  border: $cap-dropdown-multiselect-checkbox-border;
  background-color: $cap-dropdown-multiselect-checkbox-background-color;
  width: $cap-dropdown-multiselect-checkbox-width;
  height: $cap-dropdown-multiselect-checkbox-height;
  text-align: $cap-dropdown-multiselect-checkbox-text-align;
  border-radius: $cap-dropdown-multiselect-checkbox-border-radius;
  transition: $cap-dropdown-multiselect-checkbox-border-transition;

  .selectable-wrapper {
    overflow: hidden;
  }
}

body {
  .cap-dropdown-panel.p-dropdown-panel,
  .cap-multi-select-panel.p-multiselect-panel {
    padding: $cap-dropdown-multiselect-panel-padding;
    border: $cap-dropdown-multiselect-panel-border;
    background-color: $cap-dropdown-multiselect-panel-background-color;
    box-shadow: $cap-dropdown-multiselect-panel-box-shadow;

    .p-dropdown-items {
      padding: $cap-dropdown-multiselect-panel-items-padding;

      .p-dropdown-item,
      .p-multiselect-item,
      .p-dropdown-item-group {
        margin: $cap-dropdown-multiselect-panel-item-group-margin;
        padding: $cap-dropdown-multiselect-panel-item-group-padding;
        border: $cap-dropdown-multiselect-panel-item-group-border;
        color: $cap-dropdown-multiselect-panel-item-group-color;
        background-color: $cap-dropdown-multiselect-panel-item-group-background-color;
        border-radius: $cap-dropdown-multiselect-panel-item-group-border-radius;
        border-top: $cap-dropdown-multiselect-panel-item-group-border-top;

        &.p-highlight {
          color: $cap-dropdown-multiselect-panel-item-highlight-color;
          background-color: $cap-dropdown-multiselect-panel-item-highlight-background-color;
        }

        &:not(.p-highlight):not(.p-disabled):hover {
          color: var(--primary-text);
          background-color: $input-border-color;
        }
      }

      .custom-item {
        position: relative;
        height: $cap-dropdown-multiselect-panel-custom-item-height;

        .custom-img {
          width: $cap-dropdown-multiselect-panel-custom-img-width;
          position: absolute;
          top: $cap-dropdown-multiselect-panel-custom-img-top;
          left: $cap-dropdown-multiselect-panel-custom-img-left;
          transform: $cap-dropdown-multiselect-panel-custom-img-transform;
        }

        .custom-label {
          font-size: toRem($cap-dropdown-multiselect-panel-custom-label-font-size);
          top: $cap-dropdown-multiselect-panel-custom-label-top;
          left: $cap-dropdown-multiselect-panel-custom-label-left;
          position: $cap-dropdown-multiselect-panel-custom-label-position;
          transform: $cap-dropdown-multiselect-panel-custom-label-transform;
        }
      }
    }

    .p-dropdown-filter-container,
    .p-multiselect-filter-container {
      border-bottom: $cap-dropdown-multiselect-panel-filter-container-border-bottom;
      color: $cap-dropdown-multiselect-panel-filter-container-color;
      background-color: $cap-dropdown-multiselect-panel-filter-container-background-color;
      margin: $cap-dropdown-multiselect-panel-filter-container-margin;
      padding: $cap-dropdown-multiselect-panel-filter-container-padding;

      .p-multiselect-filter,
      .p-dropdown-filter,
      input.p-inputtext {
        padding: $cap-dropdown-multiselect-panel-filter-container-input-padding;
        width: $cap-dropdown-multiselect-panel-filter-container-input-width;
        border: $cap-dropdown-multiselect-panel-filter-container-input-border;
      }

      .p-icon-wrapper {
        display: inline;
      }

      .p-dropdown-filter-icon,
      .p-multiselect-filter-icon {
        position: $cap-dropdown-multiselect-panel-filter-container-icon-position;
        left: $cap-dropdown-multiselect-panel-filter-container-icon-left;
        top: $cap-dropdown-multiselect-panel-filter-container-icon-top;
        pointer-events: none;
        color: $cap-dropdown-multiselect-panel-filter-container-icon-color;
        transform: none;
      }
    }

    div.p-multiselect-filter-container {
      border-bottom: none;
    }
  }
}

.cap-rounded.cap-multi-select.p-multiselect,
.cap-rounded.cap-dropdown.p-dropdown,
.cap-rounded.cap-dropdown.p-dropdown:not(.p-disabled).p-focus,
.cap-rounded.cap-multi-select.p-multiselect:not(.p-disabled):hover,
.cap-rounded.cap-dropdown.p-dropdown:not(.p-disabled):hover {
  border-color: var(--cap-primary-color);
  border-radius: 20px;
  height: $cap-input-height;
  font-size: 1em;

  .p-dropdown-label,
  .p-multiselect-label {
    padding-right: 2em;
    height: calc(#{$cap-input-height} - 2px);
    border-radius: 20px;
    font-size: 1em;
  }

  .p-dropdown-trigger,
  .p-multiselect-trigger {
    border-radius: 20px;
    border: none;
    height: calc(#{$cap-input-height} - 6px);
    margin-top: 2px;
    color: #5f6062;
    font-size: 0.9em;
    margin-right: 2px;
  }
}

/* stylelint-disable selector-type-case */
p-dropdown.readonly .cap-dropdown.p-dropdown,
p-dropdown.readonly .cap-dropdown.p-dropdown.p-focus,
p-dropdown.readonly .cap-dropdown.p-dropdown:not(.p-disabled):hover,
p-multiSelect.readonly .cap-multi-select.p-multiselect,
p-multiSelect.readonly .cap-multi-select.p-multiselect.p-focus,
p-multiSelect.readonly .cap-multi-select.p-multiselect:not(.p-disabled):hover {
  border: none;
  outline: none;
  background: transparent;

  .p-dropdown-label,
  .p-multiselect-label {
    background: transparent;
    outline: none;
  }

  .p-dropdown-trigger,
  .p-multiselect-trigger {
    display: none;
  }
}

.p-float-label {
  &.dropdown-float-label,
  &.multi-select-float-label {
    .cap-dropdown.p-dropdown,
    .cap-multi-select.p-multiselect {
      border-radius: 3px;
      border: $floating-input-border;

      &,
      &.p-dropdown:not(.p-disabled):hover,
      &.p-dropdown:not(.p-disabled).p-focus,
      &.p-multiselect:not(.p-disabled):hover,
      &.p-multiselect:not(.p-disabled).p-focus {
        .p-dropdown-trigger,
        .p-multiselect-trigger {
          width: unset;
        }
      }
    }

    .p-dropdown-label,
    .p-multiselect-label-container > div {
      color: $floating-placeholder-text-color;
      font-family: $cap-primary-font;
      margin-left: 13px;
      padding-left: 0;

      &:focus {
        outline: none;
      }
    }

    .p-dropdown-trigger,
    .p-multiselect-trigger {
      margin-right: 10px;

      .p-dropdown-trigger-icon,
      .p-multiselect-trigger-icon {
        color: $floating-placeholder-text-color;
        font-size: 0.88rem;
      }
    }

    /* Styles for label when static */
    label {
      &.cpb-float-label {
        color: #b7b7b7;
        font-size: 1rem;
        font-weight: 400;
        font-family: $cap-primary-font;
      }
    }

    /* Styles for label when floating */
    .p-inputwrapper-focus + label,
    .p-inputwrapper-filled + label {
      font-size: 0.81rem;

      &.cpb-float-label {
        position: absolute;
        transform: translateY(11px);
        transition: all 0.3s;
        background-color: $white;
        padding-right: 6px;
        padding-left: 6px;
      }
    }
  }
}

/* Styles for required label when floating */
cap-dropdown.ng-invalid.ng-touched,
cap-multi-select.ng-invalid.ng-dirty {
  .p-float-label {
    .cap-dropdown.p-dropdown,
    .cap-multi-select.p-multiselect {
      border: 1px solid $cap-danger;
    }
  }

  .p-inputwrapper-focus + label {
    &.cpb-float-label {
      color: $cap-danger;
    }
  }
}
