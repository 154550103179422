.cap-textarea {
  width: $cap-textarea-width;
  resize: $cap-textarea-resize;
  border-color: $cap-textarea-border-color;
  outline: none;
  border-radius: $cap-textarea-border-radius;
  height: $cap-textarea-border-height;
  padding: $cap-textarea-padding;
  font-size: toRem($cap-textarea-font-size);
  color: $cap-textarea-font-color;

  &[readonly] {
    background: transparent;
    border: none;
    resize: none;
  }
}

.p-float-label {
  &.textarea-float-label {
    .cap-textarea {
      border: $floating-input-border;
      border-radius: 3px;
      color: $floating-placeholder-text-color;
      font-family: $cap-primary-font;

      &:focus {
        outline: none;
      }
    }

    /* Styles for label when static */
    label {
      &.cpb-float-label {
        color: #b7b7b7;
        font-size: 1rem;
        font-weight: 400;
        font-family: $cap-primary-font;
      }
    }

    /* Styles for label when floating */
    .cap-textarea:enabled:focus:not(.p-state-error) + label,
    .populated + label {
      font-size: 0.81rem;

      &.cpb-float-label {
        position: absolute;
        background-color: $white;
        padding-right: 6px;
        padding-left: 6px;
      }
    }

    .cap-textarea:enabled:focus:not(.p-state-error) + label {
      &.cpb-float-label {
        transform: translateY(11px);
      }
    }

    .populated + label {
      &.cpb-float-label {
        transform: translateY(-17px);
      }
    }
  }
}

/* Styles for required label when floating */
cap-textarea.ng-invalid.ng-touched {
  .p-float-label {
    textarea {
      border: 1px solid $cap-danger;
    }

    &.textarea-float-label .cap-textarea:enabled:focus + label {
      &.cpb-float-label {
        color: $cap-danger;
      }
    }
  }
}
