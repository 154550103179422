@mixin angle($side1, $side2, $size, $top: null, $right: null, $bottom: null, $left: null ) {
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  border-radius: 3px;
  width: $size;
  height: $size;
  color: var(--cap-primary-color);
  border-#{$side1}: solid 4px var(--cap-primary-color);
  border-#{$side2}: solid 4px var(--cap-primary-color);
}

/* barcode-scanner component */
@media screen and (min-width: 768px) {
  $margin-y: 15%;

  .angle-top-right {
    @include angle('top', 'right', $cap-scanner-angle-size, $margin-y, $cap-scanner-margin-x);
  }

  .angle-top-left {
    @include angle('top', 'left', $cap-scanner-angle-size, $margin-y, null, null, $cap-scanner-margin-x);
  }

  .angle-bottom-right {
    @include angle('bottom', 'right', $cap-scanner-angle-size, null, $cap-scanner-margin-x, $margin-y, null);
  }

  .angle-bottom-left {
    @include angle('bottom', 'left', $cap-scanner-angle-size, null, null, $margin-y, $cap-scanner-margin-x);
  }
}

@media screen and (max-width: 768px) {
  $margin-y: 30%;

  .angle-top-right {
    @include angle('top', 'right', $cap-scanner-angle-size, $margin-y, $cap-scanner-margin-x);
  }

  .angle-top-left {
    @include angle('top', 'left', $cap-scanner-angle-size, $margin-y, null, null, $cap-scanner-margin-x);
  }

  .angle-bottom-right {
    @include angle('bottom', 'right', $cap-scanner-angle-size, null, $cap-scanner-margin-x, $margin-y, null);
  }

  .angle-bottom-left {
    @include angle('bottom', 'left', $cap-scanner-angle-size, null, null, $margin-y, $cap-scanner-margin-x);
  }
}

.cap-barcode-scanner {
  .scan-container {
    width: $cap-scanner-scan-container-width;
    height: $cap-scanner-scan-container-height;
    position: $cap-scanner-scan-container-position;
    top: $cap-scanner-scan-container-top;
    left: $cap-scanner-scan-container-left;
    transform: $cap-scanner-scan-container-transform;
    background-color: $cap-scanner-scan-background-color;
  }

  .scan-header {
    color: $cap-scanner-scan-header-color;
    font-size: toRem($cap-scanner-scan-header-font-size);
    height: $cap-scanner-scan-header-height;
    box-shadow: $cap-scanner-scan-header-box-shadow;
    background-color: $cap-scanner-scan-header-background-color;

    i {
      cursor: $cap-scanner-scan-header-cursor;

      &:hover {
        font-size: toRem($cap-scanner-scan-header-hover-font-size);
        filter: $cap-scanner-scan-header-hover-filter;
      }
    }
  }

  .scan-message {
    @include media-breakpoint-up(md) {
      max-width: 28rem;
    }
    position: $cap-scanner-scan-message-position;
    right: $cap-scanner-scan-message-right;
    left: $cap-scanner-scan-message-left;
    z-index: $cap-scanner-scan-message-z-index;
    height: $cap-scanner-scan-message-height;
    max-width: $cap-scanner-scan-message-max-width;
    border-radius: $cap-scanner-scan-message-border-radius;
    box-shadow: $cap-scanner-scan-message-box-shadow;
    background-color: $cap-scanner-scan-message-background-color;

    p {
      overflow: $cap-scanner-scan-message-text-overflow;
      font-size: toRem($cap-scanner-scan-message-font-size);
    }

    i {
      color: var(--success-color);
      font-size: 1.5rem;
    }
  }

  .darken video {
    filter: brightness(30%);
  }

  zxing-scanner {
    position: $cap-scanner-zxing-position;
    top: $cap-scanner-zxing-top;
    height: $cap-scanner-zxing-height;
    width: $cap-scanner-zxing-width;
    z-index: $cap-scanner-zxing-z-index;
  }

  zxing-scanner video {
    width: $cap-scanner-zxing-video-width;
    height: $cap-scanner-zxing-video-height;
  }

  .error-message {
    position: $cap-scanner-error-message-position;
    z-index: $cap-scanner-error-message-z-index;
    top: $cap-scanner-error-message-top;
  }
}

/* scan-list component */
.scan-list {
  .strikethrough {
    text-decoration: $cap-scanner-scan-list-strikethrough-text-decoration;
  }

  i {
    color: $cap-scanner-scan-list-text-color;
    cursor: $cap-scanner-scan-list-text-cursor;
    font-size: toRem($cap-scanner-scan-list-text-font-size);

    &:hover {
      color: $cap-scanner-scan-list-text-hover-color;
      font-size: toRem($cap-scanner-scan-list-text-hover-font-size);
    }
  }
}
