@mixin image() {
  width: 100%;
  height: 100%;
}

@mixin proportions($size) {
  width: $size;
  height: $size;
}

@mixin notification-oval($width, $height, $bgColor: null, $color: initial, $border: none) {
  height: $height;
  width: $width;
  border: $border;
  background-color: $bgColor;
  border-radius: 50%;
  color: $color;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@mixin no-scrollbar() {
  // Microsoft Edge (non Chromium version)
  -ms-overflow-style: none;

  // Mozilla Firefox
  scrollbar-width: none;

  // Webkit based browsers (e.g. Chromium based or Safari)
  &::-webkit-scrollbar {
    width: 0;
  }
}

@mixin setObjectFit($type) {
  width: 100%;
  height: 100%;
  object-fit: $type;
}

@mixin setAbsoluteCenter() {
  $size: 50%;
  position: absolute;
  top: $size;
  left: $size;
  transform: translate(-$size, -$size);
}

@mixin setAbsoluteVerticalCenter() {
  $size: 50%;
  position: absolute;
  top: $size;
  transform: translateY(-$size);
}

@mixin setAbsoluteHorizontalCenter() {
  $size: 50%;
  position: absolute;
  left: $size;
  transform: translateX(-$size);
}

@mixin setBottomGutters($value, $lastGutter: 0, $start: 1n, $iteration: 1) {
  & > *:nth-child(#{$start} + #{$iteration}) {
    margin-bottom: $value;

    &:last-child {
      margin-bottom: $lastGutter;
    }
  }
}

@mixin setTopGutters($value, $lastGutter: 0, $start: 1n, $iteration: 1) {
  & > *:nth-child(#{$start} + #{$iteration}) {
    margin-top: $value;

    &:last-child {
      margin-top: $lastGutter;
    }
  }
}

@mixin setBottomMargin($value) {
  margin-bottom: $value;

  &:last-child {
    margin-bottom: 0;
  }
}

@mixin setLeftGutters($value) {
  & > * {
    margin-left: $value;

    &:first-child {
      margin-left: 0;
    }
  }
}

@mixin setRightGutters($value) {
  & > * {
    margin-right: $value;

    &:last-child {
      margin-right: 0;
    }
  }
}

@mixin flexCentering() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin truncateAndDots($maxWidth) {
  max-width: $maxWidth;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin cap-media-breakpoint($name, $breakpoints: $cap-media-breakpoints) {
  $exist: breakpoint-exist($name, $breakpoints);
  @if $exist {
    @media #{$exist} {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin setFontAwesomeProIcon($code, $weight: 900) {
  font-family: 'Font Awesome 5 Pro';
  font-weight: $weight;
  content: $code;
}
