.form-element {
  label {
    outline: 0;
    font-weight: $cap-form-element-label-font-weight;
    color: $cap-form-element-label-color;
    display: flex;
    align-items: center;
  }
}

::ng-deep { /* stylelint-disable-line selector-pseudo-element-no-unknown */
  @include media-breakpoint-down(sm) {
    .form-element {
      line-height: $cap-form-element-line-height;
      display: $cap-form-element-table-display;
      width: $cap-form-element-table-width;

      > :first-child {
        width: $cap-form-element-table-first-child-width;
        display: $cap-form-element-table-first-child-display;
        vertical-align: $cap-form-element-table-first-child-vertical-align;
      }

      label {
        font-size: toRem($cap-form-element-table-label);
      }

      input, .p-inputgroup-addon {
        height: $cap-form-element-table-input-height;
      }
    }
  }

  @include media-breakpoint-between(sm, lg) {
    .form-element {
      label {
        font-size: toRem(13px);
      }

      input, .p-inputgroup-addon {
        height: 30px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .form-element {
      label {
        font-size: toRem(16px);
      }

      input, .p-inputgroup-addon {
        height: $cap-input-height;
        font-size: toRem(14px);
      }
    }
  }

  /* Firefox, Chrome, Opera */
  ::placeholder {
    color: $cap-forms-placeholder-text-color;
    font-size: toRem(14px);
  }

  /* Internet Explorer 10-11 */
  :-ms-input-placeholder { /* stylelint-disable-line */
    color: $cap-forms-placeholder-text-color;
    font-size: toRem(14px);
  }

  /* Microsoft Edge */
  ::-ms-input-placeholder { /* stylelint-disable-line */
    color: $cap-forms-placeholder-text-color;
    font-size: toRem(14px);
  }
}
