.segmented-select {
  display: flex;
  border-radius: 20px;
  background-color: $cap-gray;
  position: relative;
  width: max-content;
  height: 40px;

  &__segment {
    cursor: pointer;
    z-index: 10;
    transition: all 0.5s;
    padding: 0 15px;

    span {
      @include flexCentering();
      color: rgba(51, 51, 51, 0.5);
      font-size: toRem(16px);
      font-weight: bold;
      position: relative;
      z-index: 10;
      width: 100%;
      height: 100%;
      transition: all 0.5s;
    }
  }

  &__animation {
    background-color: $primary;
    border-radius: 20px;
    box-shadow: 0 5px 13px 0 rgba($primary, 0.3);
    transition: all 0.5s;
    transform: scaleX(1.2);
    position: absolute;
    width: 100px;
    height: 100%;
  }

  .active span {
    color: $white;
  }
}
