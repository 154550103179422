$padding-top: 10px;

.collapsable-card {
  padding: $cap-collapsable-card-padding;
}

.collapsable-card-title {
  font-size: toRem($cap-collapsable-card-title-font-size);
  font-weight: $cap-collapsable-card-title-font-weight;
}

.collapsable-card-header {
  display: flex;
  justify-content: $cap-collapsable-card-header-justify-content;
  border-bottom: $cap-collapsable-card-header-border-bottom;
}

.collapseable-card-link {
  color: $cap-collapsable-card-link-color;
  cursor: pointer;
  user-select: none;
  font-weight: $cap-collapsable-card-link-font-weight;

  &:hover {
    text-decoration: underline;
  }
}

.collapsable-card-content {
  padding-top: $cap-collapsable-card-content-padding-top;
}

.collapsable-card-divider {
  border-bottom: 1px solid $cap-collapsable-card-nav-divider-color;
}
