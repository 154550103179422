body .p-inputgroup {
  outline: none;

  input.p-inputtext,
  input.p-inputtext:hover,
  .p-inputtext:enabled:hover:not(.p-state-error),
  .p-inputtext:enabled:focus:not(.p-state-error) {
    border-radius: $cap-input-text-border-radius;
    padding-left: $cap-input-text-padding-left;
    font-size: toRem($cap-input-text-font-size);
    box-sizing: border-box;
    font-family: $cap-input-text-font-family;
    color: $cap-input-text-color;
    border: $cap-input-text-border;
    height: $cap-input-text-height;
    display: $cap-input-text-display;
    flex-grow: $cap-input-text-flex-grow;

    &[readonly],
    &.readonly {
      border: none;
      background: transparent;
    }

    &[disabled],
    &.p-disabled,
    &:disabled {
      opacity: 0.5;
    }

    &.content-left {
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &.content-right {
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &.cap-rounded {
      border-radius: $cap-input-text-rounded-border-radius;
      border-color: $cap-input-text-rounded-border-color;
      height: $cap-input-height;
      width: $cap-input-text-rounded-width;
    }
  }

  .p-inputgroup-addon {
    border: 1px solid $cap-input-group-addon-border-color;
    max-height: $cap-input-group-addon-max-height;
    height: $cap-input-group-addon-height;
    white-space: $cap-input-group-addon-white-space;
    background-color: $cap-input-group-addon-background-color;
    color: $cap-input-group-addon-color;
  }
}

.p-float-label {
  &.input-float-label {
    .cap-input.p-inputtext {
      border: $floating-input-border;
      border-radius: 3px;
      color: $floating-placeholder-text-color;
      font-family: $cap-primary-font;

      &:focus {
        outline: none;
      }
    }

    /* Styles for label when static */
    label {
      &.cpb-float-label {
        color: #b7b7b7;
        font-size: 1rem;
        font-weight: 400;
        font-family: $cap-primary-font;
        top: 2px;
      }
    }

    /* Styles for label when floating */
    .p-inputtext:enabled:focus:not(.p-state-error) + label,
    .populated + label {
      font-size: 0.81rem;

      &.cpb-float-label {
        position: absolute;
        background-color: $white;
        padding-right: 6px;
        padding-left: 6px;
      }
    }

    .p-inputtext:enabled:focus:not(.p-state-error) + label {
      &.cpb-float-label {
        transform: translateY(0px);
      }
    }

    .populated + label {
      &.cpb-float-label {
        transform: translateY(-20px);
      }
    }
  }
}

cap-input.ng-invalid.ng-touched {
  .cap-input.p-inputtext,
  .p-inputtext:enabled:focus,
  .p-inputtext:enabled:hover {
    border: 1px solid $cap-danger;
  }

  .p-inputtext:enabled:focus + label {
    &.cpb-float-label,
    .p-float-label.input-float-label label.cpb-float-label {
      color: $cap-danger;
    }
  }

  .populated + label.cpb-float-label {
    color: $cap-danger;
  }
}
