body .p-tabview {
  .p-tabview-panels {
    border: none;
    background: transparent;
  }

  .p-tabview-nav {
    border-bottom: 1px solid $cap-tab-view-bottom-border-color;

    li {
      padding: 10px 25px;
      font-weight: 600;
      font-size: toRem(14px);

      a.p-tabview-nav-link {
        color: $cap-tab-view-text-color;
      }

      &.p-highlight {
        background: $cap-tab-view-highlight-tab-background;
        margin-bottom: -1px;
        border: 1px solid $cap-tab-view-highlight-tab-border-color;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        border-bottom: none;
        position: relative;

        a.p-tabview-nav-link {
          color: var(--cap-primary-color);
        }
      }
    }
  }
}
