.cap-image-gallery {
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.cap-image-gallery-container {
  display: grid;
  grid-template-columns: repeat(4,minmax(0,1fr));
  gap: .25rem;
}

.cap-image-gallery-item {
  cursor: pointer;

  &:first-of-type {
    grid-row: span 2/span 2;
    grid-column: span 2/span 2;
  }
}

.cap-image-gallery-more {
  background-color: rgba($cap-white, 0.6);
  display: inline-flex;
  aspect-ratio: 1;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 1rem;
  color: var(--cap-primary-color);
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1;
  cursor: pointer;
}
