.mat-stepper-horizontal {
  background-color: $cap-desktop-stepper-horizontal-background-color;
}

.mat-horizontal-stepper-header-container {
  white-space: $cap-desktop-stepper-horizontal-header-container-white-space;
  display: flex;
  align-items: $cap-desktop-stepper-horizontal-header-container-align-items;
  justify-content: $cap-desktop-stepper-horizontal-header-container-justify-content;

  .mat-step-header {
    overflow: hidden;
    outline: 0;
    cursor: pointer;
    position: relative;
    box-sizing: content-box;

    .horizontal-stepper-header {
      display: flex;
      height: $cap-desktop-stepper-horizontal-header-height;
      overflow: hidden;
      align-items: $cap-desktop-stepper-horizontal-align-items;

      .step-header-ripple {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
      }

      .step-icon-content {
        align-self: $cap-desktop-stepper-icon-content-align-self;
        i {
          line-height: $cap-desktop-stepper-icon-content-line-height;
        }
      }

      .mat-step-icon {
        border-radius: $cap-desktop-stepper-icon-border-radius;
        border: $cap-desktop-stepper-icon-border;
        height: $cap-desktop-stepper-icon-size;
        width: $cap-desktop-stepper-icon-width;
        flex-shrink: 0;
        position: relative;
      }

      .step-icon {
        background-color: $cap-desktop-stepper-step-icon-background-color;
        color: $cap-desktop-stepper-step-icon-color;
        display: flex;
        justify-content: $cap-desktop-stepper-step-icon-justify-content;
      }

      .completed {
        background-color: $cap-desktop-stepper-step-icon-completed-background-color;
        color: $cap-desktop-stepper-step-icon-color;
      }
    }
  }

  .stepper-horizontal-line:not(:last-child) {
    flex: auto;
    height: 0;
    min-width: $cap-desktop-stepper-horizontal-line-min-width;
    opacity: $cap-desktop-stepper-horizontal-line-opacity;
    border-top: $cap-desktop-stepper-horizontal-line-border-top;
    color: $cap-desktop-stepper-horizontal-line-color;
  }

  .step-label {
    font-size: toRem($cap-desktop-stepper-step-label-font-size);
  }

  .step-label-active {
    color: $cap-desktop-stepper-step-label-active-color;
  }

  .step-label-inactive {
    color: $cap-desktop-stepper-step-label-inactive-color;
  }
}
