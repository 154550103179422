.cap-mobile-tab-bar {
  display: $cap-mobile-tab-bar-display;
  justify-content: $cap-mobile-tab-bar-justify-content;
  margin-top: $cap-mobile-tab-bar-margin-top;
  box-shadow: $cap-mobile-tab-bar-box-shadow;
  padding: $cap-mobile-tab-bar-padding;
  background-color: $cap-mobile-tab-bar-background-color;

  .shape {
    font-size: toRem($cap-mobile-tab-bar-shape-font-size);
  }

  .menu-item {
    margin-bottom: $cap-mobile-tab-bar-menu-item-margin-bottom;

    font: {
      size: $cap-mobile-tab-bar-menu-item-font-size;
      weight: $cap-mobile-tab-bar-menu-item-weight;
    };
  }

  .active i,
  .active p {
    color: $cap-mobile-tab-bar-active-text-color;
  }

  .tab-rectangle {
    text-align: $cap-mobile-tab-bar-rectangle-text-align;
    word-break: $cap-mobile-tab-bar-word-break;
    max-width: $cap-mobile-tab-bar-max-width;
    cursor: pointer;
  }

  .link:focus {
    outline: 0;
  }
}
