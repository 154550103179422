
@mixin selectorPosition($side) {
  #{$side}: $cap-image-viewer-navigator-desktop-spacing;

  @include media-breakpoint-down(lg) {
    #{$side}: $cap-image-viewer-navigator-table-spacing;
  }

  @include media-breakpoint-down(sm) {
    #{$side}: $cap-image-navigator-mobile-spacing;
  }
}

@mixin viewer-action-button {
  padding: 0 .5rem;
  border-width: 1px;
  width: 30px;
  height: 30px;
}

.viewer {
  position: $cap-image-viewer-position;
  width: $cap-image-viewer-width;
  height: $cap-image-viewer-height;
  left: $cap-image-viewer-left;
  top: 0;
  z-index: $cap-image-viewer-z-index;
  background: $cap-image-viewer-background;
  backdrop-filter: blur(3px);

  .p-galleria .p-galleria-thumbnail-container {
    background: rgba($cap-black, 0.9);
    padding: 10px 0.25rem;

    .p-galleria-thumbnail-prev,
    .p-galleria-thumbnail-next {
      color: $cap-white;
      margin: .5rem;
    }
  }

  &.fullscreen {
    .viewer__body-container {
      background: $cap-black;
      max-width: 100vw;
      height: 100vh;
      border-radius: 0;
      display: flex;
      flex-direction: column;
    }

    .viewer__preview {
      flex: 1;
    }

    .p-galleria {
      height: 100%;

      .p-galleria-content {
        height: 100%;
      }

      .p-galleria-item-wrapper {
        height: 100%;
        flex: 1;
      }
    }

    .viewer__body-actions {
      background-color: $cap-white;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 28px;
    }

    .viewer__preview-selector {
      i {
        color: $cap-white;
      }
    }
  }
}

.viewer__header {
  background: $cap-white;
  padding: $cap-image-viewer-header-padding;
  display: $cap-image-viewer-header-display;
  justify-content: $cap-image-viewer-header-justify-content;
}

.viewer__title {
  font-size: toRem(24px);
  font-weight: 700;

  p {
    margin: 0;
  }
}

.viewer__cta {
  display: flex;
}

.viewer__header-actions {
  @include setRightGutters(15px);
  display: flex;
  align-items: center;

  .cap-button {
    &.primary {
      cursor: pointer;
      color: var(--cap-primary-color);
      background-color: $cap-image-viewer-actions-button-background-color;
      border-color: $cap-image-viewer-actions-button-background-color;
      padding: .2rem 11px;
      font-size: 1rem;
    }
  }
}

.viewer__header-closer {
  margin-left: $cap-image-viewer-header-closer-margin-left;
  cursor: pointer;
  color: $cap-gray;
  font-size: 21px;
}

.viewer__body {
  @include setAbsoluteCenter();
  width: 100%;
}

.viewer .viewer__body-container {
  background: $cap-image-viewer-body-container-background;
  padding: 0;
  border-radius: $cap-image-viewer-body-wrapper-border-radius;
  overflow: hidden;
}

.viewer__preview-wrapper {
  width: $cap-image-viewer-header-preview-wrapper-width;
  height: $cap-image-viewer-header-preview-wrapper-height;
  overflow: $cap-image-viewer-header-preview-wrapper-overflow;

  img {
    @include setObjectFit(contain);
    transform-origin: center center;
    transition: transform 1s, filter 0.5s ease-out;
  }

  .p-galleria {
    height: 100%;
    .p-galleria-item-wrapper {
      height: 100%;

      .p-galleria-item-container {
        height: 100%;
        background-color: rgba($cap-black, 0.9);
      }
    }

    .p-galleria-content {
      height: 100%;

      p-galleriaitem {
        flex: 1;
        overflow: hidden;
      }
    }
  }

}

.viewer__body-actions {
  margin: 28px auto;
  position: relative;

  @supports not (width: max-content) {
    width: 350px;
  }

  @supports (width: max-content) {
    width: max-content;
  }
}

.viewer__body-wrapper {
  display: $cap-image-viewer-body-wrapper-display;
  padding: $cap-image-viewer-body-wrapper-padding;
  color: $cap-image-viewer-body-wrapper-color;
  align-items: $cap-image-viewer-body-wrapper-align-items;
}

.viewer__body-pagination {
  @include setRightGutters(10px);
  display: flex;
  align-items: center;

  @include media-breakpoint-between(xs, sm) {
    display: none;
  }

  p {
    margin-bottom: 0;
  }

  &-text {
    border-radius: 3px;
    border: solid 1px $cap-gray;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    padding: 0 .5rem;
    font-size: .8rem;
  }

  .cap-button,
  .cap-button.secondary:not(:disabled) {
    @include viewer-action-button;
  }
}

.viewer__body-zoom {
  display: flex;
  align-items: center;
  padding-right: 1rem;
  font-size: 1rem;

  @include media-breakpoint-up(md) {
    padding-right: 3rem;
  }

  .text {
    padding: 0 0.5rem;
  }

  i {
    cursor: pointer;
  }

  .cap-button,
  .cap-button.primary:not(:disabled) {
    @include viewer-action-button;
  }
}

.viewer__preview {
  position: relative;
  height: 67vh;
  padding-left: 64px;
  padding-right: 64px;
}

.viewer__preview-selector {
  @include setAbsoluteVerticalCenter();
  @include flexCentering();
  $size: 18px;
  width: $size;
  height: $size;
  cursor: pointer;
  transition: 0.5s all;
  z-index: 2;

  i {
    color: $cap-image-viewer-selector-color;
    font-size: 18px;
  }

  &.viewer__preview-selector--left {
    @include selectorPosition(left);
  }

  &.viewer__preview-selector--right {
    @include selectorPosition(right);
  }
}

.viewer__preview-thumbnail-item {
  width: 80px;
  height: 60px;

  img {
    object-fit: cover;
  }
}

.viewer__body-fullscreen {
  padding-left: 1rem;

  @include media-breakpoint-up(md) {
    padding-left: 3rem;
  }

  .cap-button,
  .cap-button.primary:not(:disabled) {
    @include viewer-action-button;
  }
}
