.week-switcher {
  border: $cap-week-switcher-border;
  border-radius: $cap-week-switcher-border-radius;
  display: $cap-week-switcher-display;
}

.week-switcher-content {
  display: $cap-week-switcher-content-display;
  color: $cap-week-switcher-content-color;
  font-size: toRem($cap-week-switcher-content-font-size);
  font-weight: $cap-week-switcher-content-font-weight;
}

.week-switcher-button-divider {
  padding: $cap-week-switcher-button-divider-padding;
  width: $cap-week-switcher-button-divider-width;
  text-align: $cap-week-switcher-button-divider-text-align;
  border-right: $cap-week-switcher-button-divider-border-right;
  border-left: $cap-week-switcher-button-divider-border-left;
}

.week-switcher-button.cap-button.secondary:not(:disabled) {
  border: none;
  box-shadow: none;
  padding: $cap-week-switcher-button-not-disabled-padding;
}
