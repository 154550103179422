$padding: 15px 17px;

.header {
  background: $cap-white;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.07);
}

.header-inner {
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 1rem 2rem;
  background: $white;

  .back-button {
    color: var(--cap-primary-color);
    font-weight: 600;
    cursor: pointer;
    padding-right: 1rem;

    &.not-visible {
      visibility: hidden;
    }

    &:hover {
      text-decoration: underline;
    }

    .back-to-overview {
      display: none;

      @include media-breakpoint-up(lg) {
        display: inline;
      }
    }
  }
}

body .header-menu {
  padding: 0 2rem 0 1rem;
  border-top: solid 1px $border-color;
  display: flex;
  align-items: center;

  .header-menu-left {
    flex: 1;
    align-self: flex-end;
    padding-top: 1rem;
    padding-right: 1rem;
  }

  .header-menu-right {
    padding: 1rem 0;
    display: flex;

    cap-button {
      .p-button.cap-button {
        font-size: 1.13rem;
        font-weight: 500;
      }
    }
  }

  .p-tabmenu {
    .p-tabmenu-nav-content {
      .p-tabmenu-nav {
        border: 0;
        margin-bottom: 0;

        .p-tabmenuitem {
          background-color: transparent;
          border: 0;
          color: $cap-dark-gray-secondary;
          padding: 0 1rem;

          &:not(.p-highlight):not(.p-disabled):hover {
            border: 0;
            background-color: transparent;
            color: var(--primary-text);
          }

          &.p-highlight {
            background-color: transparent;
            color: var(--primary-text);
            border: 0;

            .p-menuitem-link {
              border-bottom: solid 5px var(--cap-primary-color);
              border-radius: 4px;

              .p-menuitem-text {
                color: var(--primary-text);
              }
            }
          }

          .p-menuitem-link {
            font-size: 1rem;
            padding: 1rem 0;

            .p-menuitem-text {
              color: $cap-dark-gray-secondary;
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}

.title-container {
  flex-grow: 1;
  font-size: 1.5rem;
  font-weight: bold;
}

.title {
  font-size: 1.5rem;
}

.header-inner-container {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}


// User menu
@mixin commonListStyle() {
  cursor: pointer;
  padding: $padding;
  font-size: 16px;
  min-height: 16px;
  font-weight: 600;
  border-bottom: 1px solid $cap-gray;
}

.user-menu {
  min-width: 275px;

  &__items {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      @include commonListStyle();

      span {
        margin-left: 15px;
      }

      i {
        color: var(--cap-primary-color);
      }

      &:last-of-type {
        border-bottom: none;
      }

      &:hover {
        background-color: rgba(99, 49, 137, 0.05);
        color: var(--cap-primary-color);
      }
    }
  }
}

.user-info {
  text-align: right;
  margin-right: 1rem;

  .title {
    font-weight: bold;
    color: var(--cap-primary-color);
    font-size: 1rem;

    @include media-breakpoint-up(lg) {
      white-space: nowrap;
      font-size: 1.125rem;
    }
  }

  .subtitle {
    color: $cap-gray-secondary;
    font-size: 0.875rem;

    @include media-breakpoint-up(xl) {
      white-space: nowrap;
      font-size: 1rem;
    }
  }
}

.menu-cta {
  @include proportions(40px);
  flex: 0 0 40px;
  @include flexCentering();
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid var(--cap-primary-color);
  background-color: var(--cap-primary-color);
  color: $white;

  i {
    margin: 0;
    color: $white;
    font-size: 0.813rem;
  }
}

.cap-overlay-panel.header-menu-bar {
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
  border: none;

  .p-overlaypanel-content {
    padding: 0;
  }
}
