@use 'sass:math';
@import '../../libs/ui/styles/blueprint-v2.theme';
/* You can add global styles to this file, and also import other style files */
:root {
  --cap-primary-color: #d50f42;
}

@import 'main';

html {
  font-size: 14px;
}
