.amount-input {
  &--disabled {
    .amount-input__action {
      cursor: not-allowed;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    border: $cap-amount-input-border;
    border-radius: $cap-amount-input-border-radius;
    color: $cap-amount-input-text-color;
  }

  &__action {
    width: 38px;
    height: 38px;
    @include flexCentering();
    flex: 0 0 38px;
    cursor: pointer;
    transition: 0.25s all;
    border: 0;

    &:hover:not(.disabled) {
      background-color: $cap-platinum;
      color: var(--cap-primary-color);
    }
  }

  input {
    border-radius: 0;
    outline: none;
    border: 0;
    border-left: 1px solid $cap-gray;
    border-right: 1px solid $cap-gray;
    text-align: center;
    padding-left: 1rem;
    padding-right: 1rem;
    flex: 1;
    align-self: stretch;
    color: $cap-amount-input-text-color;
    min-width: 0;
  }
}
