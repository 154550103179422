$cap-dynamic-filter-border-radius: 3px;
$cap-dynamic-filters-hide-filters-on-mobile: true;
$cap-dynamic-filters-filter-flex: 1 1 0;

cap-dynamic-filters cap-checkbox-filter {
  background-color: transparent;
  border: none;

  .cap-checkbox-filter-label {
    margin-left: 10px;
  }

  cap-dynamic-filter-layout {
    width: 100%;

    .cap-dynamic-filter-layout-content {
      display: flex;
      justify-content: flex-start;
    }
  }
}
