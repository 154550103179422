.app-horizontal-nav-bar {
  width: $cap-horizontal-navbar-width;
}

nav.navbar {
  z-index: $cap-horizontal-navbar-z-index;
  box-shadow: $box-shadow;
  padding: $cap-horizontal-navbar-padding;
  background-color: $cap-horizontal-navbar-background-color;

  .navbar-nav {
    margin: $cap-horizontal-navbar-margin;
  }

  .navbar-collapse {
    align-items: initial;
    justify-content: unset;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    @include media-breakpoint-up(lg) {
      justify-content: center;
      overflow-x: hidden;
      padding: 0 2rem !important; /* stylelint-disable-line declaration-no-important */
      margin: 0;
    }
  }

  .ng-star-inserted a.nav-item {
    padding: 15px 0;
  }

  a.nav-item {
    font-size: toRem($cap-horizontal-navbar-nav-item-font-size);
    font-weight: $cap-horizontal-navbar-nav-item-font-weight;
    border-bottom: $cap-horizontal-navbar-nav-item-border-bottom;
    color: $cap-horizontal-navbar-nav-item-color;
    width: $cap-horizontal-navbar-nav-item-width;
    position: $cap-horizontal-navbar-nav-item-position;

    @include media-breakpoint-up(lg) {
      width: 175px;
    }

    &:hover {
      .dropdown-menu {
        display: block;
      }
    }

    .dropdown-menu {
      text-align: $cap-horizontal-navbar-nav-item-dropdown-menu-text-align;
      width: $cap-horizontal-navbar-nav-item-dropdown-menu-width;
      padding: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border: 0;
      box-shadow: $box-shadow;

      a {
        font-size: toRem($cap-horizontal-navbar-nav-item-dropdown-menu-text-font-size);
        font-weight: $cap-horizontal-navbar-nav-item-dropdown-menu-text-font-weight;
        padding: $cap-horizontal-navbar-nav-item-dropdown-menu-text-padding;
        border-bottom: $cap-horizontal-navbar-nav-item-dropdown-menu-text-border-bottom;

        &:last-child {
          border-bottom: 0;
        }

        &:hover {
          cursor: pointer;
          background-color: $cap-horizontal-navbar-nav-item-dropdown-menu-text-hover-background-color;
          color: $cap-horizontal-navbar-nav-item-dropdown-menu-text-hover-color;
        }

        i {
          display: initial;
          float: $cap-horizontal-navbar-nav-item-dropdown-menu-icon-float;
          color: inherit;
          margin-top: $cap-horizontal-navbar-nav-item-dropdown-menu-icon-margin-top;
        }
      }
    }

    div {
      text-align: center;
      border-right: 0.5px solid $border-color;
      border-left: 0.5px solid $border-color;
    }

    &.active,
    &.active-item {
      color: var(--cap-primary-color);
      border-bottom-color: var(--cap-primary-color);
      background-color: (var(--cap-primary-color), 0.03);

      .nav-item-overlay {
        display: block;
      }

      i {
        color: var(--cap-primary-color);
      }
    }
  }

  i {
    color: $cap-horizontal-navbar-icon-color;
    display: block;
    margin: 0 auto 5px;
    font-size: 1.3em;
  }

  .nav-item-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: $cap-horizontal-navbar-nav-item-overlay-background;
    z-index: $cap-horizontal-navbar-nav-item-overlay-z-index;
    opacity: $cap-horizontal-navbar-nav-item-overlay-opacity;
    display: none;
  }
}

.nav-bar-gradient {
  position: absolute;
  right: 0;
  width: $cap-horizontal-navbar-nav-bar-gradient-width;
  top: 0;
  pointer-events: none;
  z-index: 1;
  bottom: 0;
  background: $cap-horizontal-navbar-nav-item-overlay-background;

  @include media-breakpoint-up(md) {
    width: 150px;
  }
}

.nav-bar-gradient-left {
  @extend .nav-bar-gradient;
  left: 0;
  right: unset;
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.75) 50%, rgba(255, 255, 255, 0) 100%);
}
