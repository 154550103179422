cap-accordion-card {
  .accordion-card {
    .ac-header__left,
    .ac-header__right {
      width: $cap-accordion-card-header-width;
    }

    .ac-header__left {
      display: $cap-accordion-card-header-left-display;
    }

    .ac-header__right {
      text-align: $cap-accordion-card-header-left-text-align;
    }

    &__title {
      margin: 0;
      font-weight: $cap-accordion-card-title-font-weight;
      font-size: toRem($cap-accordion-card-title-font-size);
      min-height: $cap-accordion-card-title-min-height;
      @include truncateAndDots(100%);

      i.ac-icon {
        color: $cap-accordion-card-title-icon-color;
        margin-right: $cap-accordion-card-title-icon-margin-right;
      }
    }

    &__actions {
      margin-left: $cap-accordion-card-actions-margin-left;
      @include setRightGutters(10px);

      i {
        cursor: pointer;
        transition: $cap-accordion-card-actions-transition;

        &:hover {
          transform: $cap-accordion-card-actions-hover;
        }
      }
    }

    &__toggler {
      margin: 0;
      font-size: toRem($cap-accordion-card-toggler-font-size);
      text-decoration: underline;
      cursor: pointer;
      color: var(--cap-primary-color);
      display: inline-block;

      i {
        font-size: toRem($cap-accordion-card-toggler-font-size);
      }
    }

    &__header {
      display: $cap-accordion-card-header-display;
      justify-content: $cap-accordion-card-header-justify-content;
      padding: $cap-accordion-card-header-padding;
      border-radius: $cap-accordion-card-header-border-radius;
      background-color: $cap-accordion-card-header-background-color;
    }

    &__content {
      border-bottom-left-radius: $cap-accordion-card-content-border-bl-radius;
      border-bottom-right-radius: $cap-accordion-card-content-border-br-radius;
      height: 0;
      overflow: hidden;
      visibility: hidden;
      transition: $cap-accordion-card-content-transition;
      background-color: $cap-accordion-card-content-background-color;

      &--open {
        padding: $cap-accordion-card-content-open-padding;
        height: auto;
        overflow: visible;
        visibility: visible;
      }
    }

    &__delimiter {
      margin-bottom: $cap-accordion-card-delimiter-margin-bottom;
      width: 100%;
      height: 1px;
      background: $cap-accordion-card-delimiter-background;
    }
  }
}
