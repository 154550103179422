.row-action {
  position: unset;
  text-align: $cap-row-action-text-align;

  &__items {
    padding: $cap-row-action-items-padding;
    list-style: $cap-row-action-items-list-style;
    box-shadow: $cap-row-action-items-box-shadow;
    background-color: $cap-row-action-items-background-color;
    position: $cap-row-action-items-position;
    text-align: $cap-row-action-items-text-align;
    right: $cap-row-action-items-right;
    margin: $cap-row-action-items-margin;
    z-index: $cap-row-action-items-z-index;
    border-radius: $cap-row-action-items-border-radius;
    visibility: $cap-row-action-items-visibility;
    height: $cap-row-action-items-height;
    overflow: $cap-row-action-items-overflow;
    cursor: $cap-row-action-items-cursor;

    @supports not (width: max-content) {
      width: 270px;
    }

    @supports (width: max-content) {
      width: max-content;
    }

    &:before {
      content: '';
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 15px solid $cap-white;
      position: absolute;
      right: 7px;
      top: -10px;
    }

    &--open {
      visibility: $cap-row-action-items-open-visibility;
      height: $cap-row-action-items-open-height;
      overflow: $cap-row-action-items-open-overflow;
    }

    li {
      display: $cap-row-action-item-display;
      cursor: $cap-row-action-item-cursor;
      padding: $cap-row-action-item-padding;
      border-bottom: $cap-row-action-item-border-bottom;

      &:hover {
        background: $cap-row-action-item-hover-background-color;
      }

      &:last-child {
        border: $cap-row-action-item-last-child-border;
      }
    }
  }

  &__icon {
    font-size: toRem($cap-row-action-items-icon-font-size);
    margin: $cap-row-action-items-icon-margin;
    color: $cap-row-action-items-icon-color;

    i {
      margin: $cap-row-action-items-icon-margin;
    }
  }

  &__info {
    margin: $cap-row-action-items-info-margin;

    p {
      margin: $cap-row-action-items-info-text-margin;
    }

    .title {
      font-size: toRem($cap-row-action-items-info-title-font-size);
      font-weight: $cap-row-action-items-info-title-font-weight;
    }

    .description {
      font-size: toRem($cap-row-action-items-info-title-font-size);
    }
  }

  & > i {
    padding: 15px;
  }
}
