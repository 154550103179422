cap-accordion .p-accordion {
  .tab {
    .p-accordion-header {
      margin-bottom: 0;

      &:not(.p-disabled):not(.p-highlight):hover a {
        background-color: $cap-accordion-header-not-active-background-color;
        border: none;
        color: $cap-accordion-header-not-active-color;

        .p-accordion-toggle-icon {
          color: $cap-accordion-header-not-active-toggle-icon-color;
        }
      }

      &:not(.p-disabled).p-highlight:hover a,
      &:not(.p-disabled).p-highlight a {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        box-shadow: $cap-accordion-header-not-active-hover-bow-shadow;
        border: none;
        background-color: $cap-accordion-header-not-active-background-color;
        color: $cap-accordion-header-not-active-color;

        .p-accordion-toggle-icon {
          color: $cap-accordion-header-not-active-color-toggle-icon;
        }
      }

      a {
        color: $cap-accordion-header-color;
        font-size: toRem($cap-accordion-header-font-size);
        font-weight: $cap-accordion-header-font-weight;
        text-decoration: none;
        height: $cap-accordion-header-height;
        border-radius: $cap-accordion-header-border-radius;
        box-shadow: $cap-accordion-header-bow-shadow;
        background-color: $cap-accordion-header-background-color;
        border: none;
        padding: $cap-accordion-header-padding;
        line-height: $cap-accordion-header-line-height;
        transition: $cap-accordion-header-transition;
        outline: none;

        br {
          display: none;
        }

        .p-accordion-toggle-icon {
          color: $cap-accordion-icon-color;
        }
      }
    }

    .p-accordion-content {
      border-bottom-right-radius: $cap-accordion-content-border-br-radius;
      border-bottom-left-radius: $cap-accordion-content-border-bl-radius;
      padding: $cap-accordion-content-padding;
      border: none;
      background-color: $cap-accordion-content-background-color;
      color: $cap-accordion-content-color;

      &-wrapper {
        border-bottom-right-radius: $cap-accordion-content-wrapper-border-br-radius;
        border-bottom-left-radius: $cap-accordion-content-wrapper-border-bl-radius;
        box-shadow: $cap-accordion-content-wrapper-box-shadow;
      }
    }
  }
}

p-header {
  .accordion-tab-header {
    font-size: toRem($cap-accordion-tab-header-font-size);
  }

  span {
    font-size: toRem($cap-accordion-header-title-font-size);
  }
}

.warning-color {
  color: var(--warning-color);
}

.confirm-color {
  color: var(--confirm-color);
}

.error-color {
  color: var(--error-color);
}

.accordion-tab-header-block {
  display: inline-block;
  width: $cap-accordion-tab-header-block-width;
}

@include media-breakpoint-down(xs) {
  .accordion-tab-header-block-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@include media-breakpoint-down(sm) {
  .accordion-tab-header-block {
    vertical-align: middle;
  }

  .accordion-tab-header-block-inner {
    line-height: normal;
  }

  p-header span {
    display: block;
    font-size: toRem($cap-accordion-header-font-size);

    &.status {
      font-size: toRem($cap-accordion-header-status-font-size);
    }
  }

  .p-accordion .p-accordion-header a .p-accordion-toggle-icon {
    float: $cap-accordion-toggle-icon-float;
    transform: $cap-accordion-toggle-icon-transform;
  }
}
