$sidebar-padding: 1.2rem;
$sidebar-header-height: 55px;
$sidebar-footer-height: 100px;

.cap-sidebar-header {
  background: var(--cap-primary-color);
  padding: 20px;
  font-weight: bold;
  font-size: 1.2em;
  color: $white;
  display: flex;
  justify-content: space-between;
  height: $sidebar-header-height;

  i {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

cap-sidebar {
  .p-sidebar-close-icon {
    display: none;
  }

  .cap-sidebar-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: $sidebar-footer-height;
    padding: $sidebar-padding;
    background: $cap-white;
  }

  .cap-sidebar-content {
    padding: $sidebar-padding;
    height: calc(100% - #{$sidebar-header-height});
    overflow: auto;

    &.has-footer {
      height: calc(100% - #{$sidebar-header-height} - #{$sidebar-footer-height});
    }
  }
}

cap-sidebar p-sidebar {
  .p-sidebar {
    width: 350px;
    padding: 0;
    height: 100%;

    .p-sidebar-content {
      overflow: auto;
      height: 100vh;
    }
  }

  .p-sidebar-header {
    display: none;
  }
}
