label {
  vertical-align: top;
  margin: 0 0 0 0.5em;
  line-height: 1.75em;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background: $cap-white;
  transform: translateX(1.25rem);
}

.p-inputswitch {
  width: 3rem;
  height: 1.75rem;
}

.p-inputswitch .p-inputswitch-slider {
  background: $cap-white;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 30px;
}

.p-inputswitch .p-inputswitch-slider:before {
  background: #fff;
  width: 1.25rem;
  height: 1.25rem;
  left: 0.25rem;
  margin-top: -0.5rem; /* stylelint-disable-line number-max-precision */
  border-radius: 50%;
  transition-duration: 0.2s;
}
