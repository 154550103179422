.prime-notification-bar {
  background-color: $cap-notification-bar-background-color;
  box-shadow: $box-shadow;
  height: $cap-notification-bar-height;
  padding: $cap-notification-bar-padding;

  @include media-breakpoint-down(xs) {
    padding: 0;
    width: 100vw;
  }

  .head {
    display: $cap-notification-bar-head-display;
    justify-content: $cap-notification-bar-head-justify-content;
    align-items: $cap-notification-bar-head-align-items;
    margin: $cap-notification-bar-head-margin;

    @include media-breakpoint-down(xs) {
      margin: 0;
      box-shadow: $box-shadow;
    }

    .fas.fa-angle-left {
      color: $cap-notification-bar-icon-angle-left-color;
      font-size: toRem($cap-notification-bar-icon-angle-font-size);
    }

    .times {
      color: $cap-notification-bar-times-color;
      cursor: pointer;
      text-align: $cap-notification-bar-times-text-align;
      font-size: toRem($cap-notification-bar-times-font-size);
    }
  }

  .bell {
    width: $cap-notification-bar-bell-width;
    height: $cap-notification-bar-bell-height;
    border-radius: $cap-notification-bar-bell-border-radius;
    box-shadow: $cap-notification-bar-bell-box-shadow;
    background-color: $cap-notification-bar-bell-background-color;
    text-align: $cap-notification-bar-bell-center;
    position: $cap-notification-bar-bell-position;
    cursor: pointer;

    i {
      color: $cap-notification-bar-bell-color;
      padding-top: $cap-notification-bar-bell-padding-top;
    }
  }

  .mobile-icon-group {
    @include media-breakpoint-down(xs) {
      display: flex;
      width: 100%;
      margin: 2rem 0 1rem 2rem;
    }
  }

  .bottom-bar {
    z-index: $cap-notification-bottom-bar-z-index;
    box-shadow: $cap-notification-bottom-bar-box-shadow;
  }

  .notification-circle {
    @include notification-oval($cap-notification-bar-circle-size, $cap-notification-bar-circle-size, white, var(--cap-primary-color), 1px solid var(--cap-primary-color));
    text-align: $cap-notification-circle-text-align;
    border-radius: $cap-notification-circle-border-radius;
    line-height: $cap-notification-circle-line-height;
    position: $cap-notification-bar-circle-position;

    // Center circle in the top right corner of the element with the bell class independent of the size of the bell and circle.
    top: - math.div($cap-notification-bar-circle-size, 2);
    left: $cap-notification-bar-square-size - math.div($cap-notification-bar-square-size, 2);
    bottom: 0;
    right: 0;

    font: {
      size: $cap-notification-circle-font-size;
      weight: $cap-notification-circle-font-weight;
    }
  }

  .notification-bar-text {
    font-weight: $cap-notification-bar-text-font-weight;
    margin-bottom: $cap-notification-bar-text-margin-bottom;
  }

  ul {
    padding: 0;

    @include media-breakpoint-down(xs) {
      padding: 0 1.5rem;
    }
  }

  strong {
    color: $cap-notification-bar-text-strong-color;
  }

  .scroll {
    overflow-y: $cap-notification-bar-text-scroll;
  }

  .notification-item {
    list-style: $cap-notification-item-list-style;
    margin: $cap-notification-item-margin;
  }

  .trash-icon {
    width: $cap-notification-trash-icon-width;
    height: $cap-notification-trash-icon-height;
    line-height: $cap-notification-trash-icon-line-height;
    cursor: $cap-notification-trash-icon-cursor;
    color: $cap-notification-trash-icon-color;
    border: $cap-notification-trash-border;
    text-align: $cap-notification-trash-text-align;
    border-radius: $cap-notification-trash-border-radius;
    margin: $cap-notification-trash-margin;
  }

  .notification-item-card {
    display: $cap-notification-bar-item-card-display;
    align-items: $cap-notification-bar-item-card-align-items;
    padding: $cap-notification-bar-item-card-padding;
    margin-top: $cap-notification-bar-item-card-margin-top;
    background-color: $cap-notification-bar-item-card-background-color;
    border-radius: $cap-notification-bar-item-card-border-radius;

    .status-icon {
      text-align: $cap-notification-bar-item-card-status-icon-text-align;
      font-size: toRem($cap-notification-bar-item-card-status-icon-font-size);
      margin-right: $cap-notification-bar-item-card-status-icon-margin-right;
      line-height: $cap-notification-bar-item-card-status-icon-line-height;

      i {
        color: $cap-notification-bar-item-card-status-icon-color;
      }

      .notification-oval {
        @include notification-oval($cap-notification-bar-icon-size, $cap-notification-bar-icon-size, lightgray, null, double 5px white);
      }
    }

    .notification {
      word-break: $cap-notification-bar-item-card-word-break;
      line-height: $cap-notification-bar-item-card-line-height;
    }

    .title {
      font-weight: $cap-notification-bar-item-card-title-font-weight;
      font-size: toRem($cap-notification-bar-item-card-title-font-size);
      margin-bottom: $cap-notification-bar-item-card-title-margin-bottom;
      color: $cap-notification-bar-item-card-title-color;
    }

    .sub-title {
      font-weight: $cap-notification-bar-item-card-subtitle-font-weight;
      font-size: toRem($cap-notification-bar-item-card-subtitle-font-size);
      margin-bottom: $cap-notification-bar-item-card-subtitle-margin-bottom;
      margin-top: $cap-notification-bar-item-card-subtitle-margin-top;
      color: $cap-notification-bar-item-card-subtitle-color;
    }

    .toggle-notification-body {
      cursor: pointer;
    }

    .body {
      margin-bottom: $cap-notification-bar-item-card-body-margin-bottom;
      padding: $cap-notification-bar-item-card-body-padding;
      line-height: $cap-notification-bar-item-card-body-line-height;
      font-size: toRem($cap-notification-bar-item-card-body-font-size);
      color: $cap-notification-bar-item-card-body-color;
    }
  }

  .notification-unread {
    border: $cap-notification-unread-border;
  }

  .notification-read {
    border: solid 1px #ddd;
  }
}

.notification-tabs {
  padding-left: $cap-notification-tabs-padding-left;
  padding-right: $cap-notification-tabs-padding-right;

  > .col {
    padding-left: $cap-notification-tabs-col-padding-left;
    padding-right: $cap-notification-tabs-col-padding-right;
  }

  .notification-tabs__tab {
    border: $cap-notification-tabs-tab-border;
    padding: $cap-notification-tabs-tab-padding;
    background-color: $cap-notification-tabs-background-color;
    border-radius: $cap-notification-tabs-border-radius;
    text-align: $cap-notification-tabs-text-align;
    font-size: toRem($cap-notification-tabs-font-size);
    font-weight: $cap-notification-tabs-font-weight;
    cursor: pointer;
    transition: $cap-notification-tabs-transition;
    white-space: $cap-notification-tabs-white-space;
    margin-bottom: $cap-notification-tabs-margin-bottom;

    &:hover, &.notification-tabs__tab-selected {
      background-color: $cap-notification-tabs-tab-selected-background-color;
      color: $cap-notification-tabs-tab-selected-color;
    }
  }
}
