.cap-file-preview-list {
  display: flex;
  flex-wrap: wrap;
  @media only screen and (min-width: 600px) {
    @include setRightGutters(15px);
  }

  &__item {
    background: $cap-file-preview-item-background-color;
    display: flex;
    border-radius: 7px;
    padding: 10px 50px 10px 10px;
    margin-bottom: 15px;
    font-size: 0.9em;
    width: 250px;
    position: relative;

    .loading-overlay {
      position: absolute;
      background: rgba(#000, 0.3);
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 5px;

      .p-progressbar {
        height: 12px;
        border-radius: 12px;
        width: 90%;
        margin: 0 auto;

        .p-progressbar-determinate .p-progressbar-value-animate {
          transition: width 1s ease-in-out;
        }

        .p-progressbar-label {
          display: none;
        }
      }
    }

    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  }

  &__preview-image {
    height: 50px;
    min-width: 50px;
    margin-right: 10px;
    background-size: cover;
    background-position: center;
  }

  &__preview-icon {
    font-size: toRem(35px);
    min-width: 50px;
    color: var(--cap-primary-color);
    margin-right: 10px;
  }

  .file-actions {
    position: absolute;
    right: 10px;
    top: 10px;
    color: var(--cap-primary-color);
    cursor: pointer;

    .file-delete {
      i {
        color: $cap-danger;
      }
    }
  }
}

body .cap-file-preview-action-panel {
  border: none;

  .p-overlaypanel-content {
    padding: 0;
    border-radius: 3px;
    border: none;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);

    .cap-file-action-item {
      padding: 5px 10px;
      cursor: pointer;
      font-size: 1rem;
      font-weight: 600;
      border-bottom: 1px solid $input-border-color;

      &:hover {
        text-decoration: underline;
      }

      i {
        color: var(--cap-primary-color);
        margin-right: 10px;
      }

      &.file-delete i {
        color: $cap-danger;
      }
    }
  }
}

