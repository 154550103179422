.pdf {
  background-color: $cap-pdf-viewer-background-color;
  box-shadow: $box-shadow;
  width: $cap-pdf-viewer-width;
  position: $cap-pdf-viewer-position;
}

.pdf-toolbar {
  padding: $cap-pdf-viewer-toolbar-padding;
  border-bottom: $border-width solid $border-color;

  cap-button button.cap-button {
    height: $cap-pdf-viewer-button-size;
    width: $cap-pdf-viewer-button-size;
    box-shadow: none;

    .p-clickable {
      margin-left: -0.5em;
    }
  }
}

pdf-viewer {
  max-height: $cap-pdf-viewer-max-height;
  display: $cap-pdf-viewer-display;
}

.info-row {
  margin: $cap-pdf-viewer-info-row-margin;
  width: $cap-pdf-viewer-info-row-width;
  text-align: $cap-pdf-viewer-info-row-text-align;
  display: $cap-pdf-viewer-info-row-display;
  flex-direction: $cap-pdf-viewer-info-row-flex-direction;
  justify-content: $cap-pdf-viewer-info-row-flex-justify-content;
}

.pdf:hover .page-controls {
  visibility: $cap-pdf-viewer-page-controls-hover-visibility;
}

.page-controls {
  display: $cap-pdf-viewer-page-controls-display;
  visibility: $cap-pdf-viewer-page-controls-visibility;
  width: $cap-pdf-viewer-page-controls-width;
  justify-content: $cap-pdf-viewer-page-controls-justify-content;
  opacity: $cap-pdf-viewer-page-controls-opacity;
  position: $cap-pdf-viewer-page-controls-position;
  top: $cap-pdf-viewer-page-controls-top;

  .page-nav {
    width: $cap-pdf-viewer-page-controls-nav-width;
    height: $cap-pdf-viewer-page-controls-nav-height;
    background-color: $cap-pdf-viewer-page-controls-nav-background-color;
    cursor: pointer;
    transition: $cap-pdf-viewer-page-controls-nav-transition;

    .fas {
      position: $cap-pdf-viewer-page-controls-nav-icon-position;
      top: $cap-pdf-viewer-page-controls-nav-icon-top;
      transform: translateY(-50%);
      color: $cap-pdf-viewer-page-controls-nav-icon-color;
    }
  }

  @mixin PreviousNextPage($Value) {
    border-radius: 100% / 50%;
    border-bottom-#{$Value}-radius: 0;
    border-top-#{$Value}-radius: 0;
    #{$Value}: $cap-pdf-viewer-toolbar-icon-right;

    .fas {
      #{$Value}: $cap-pdf-viewer-toolbar-icon-right;
    }
  }

  .previous-page {
    @include PreviousNextPage(left);
  }

  .next-page {
    @include PreviousNextPage(right);
  }
}

.pdf-toolbar.row {
  margin: $cap-pdf-viewer-toolbar-margin;

  .info-column {
    float: $cap-pdf-viewer-toolbar-info-column-float;
    padding: $cap-pdf-viewer-toolbar-info-column-padding;
    height: $cap-pdf-viewer-toolbar-info-column-height;
    justify-content: $cap-pdf-viewer-toolbar-info-column-justify-content;
    display: $cap-pdf-viewer-toolbar-info-column-display;
    flex-direction: $cap-pdf-viewer-toolbar-info-column-flex-direction;
    border-radius: $cap-pdf-viewer-toolbar-info-column-border-radius;
  }

  .cap-button {
    padding: 0;
  }
}

ngx-extended-pdf-viewer {
  #findbar {
    top: 62px !important; /* stylelint-disable-line declaration-no-important */
    left: unset !important; /* stylelint-disable-line declaration-no-important */
    right: 0;
    max-width: 250px;
    margin: 1.5rem 2.5rem;
    padding: 1rem;
    border: 1px solid $border-color;

    &:before,
    &:after {
      display: none;
    }
  }
}
