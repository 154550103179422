$background-hover-color: rgba(104, 36, 59, 0.2);

.cap-sidebar {
  background-color: $cap-white;
  height: 100%;
  min-height: 100vh;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.5);
  color: rgba(51, 51, 51, 0.8);
  position: relative;
  border-right: 1px solid $cap-sidebar-menu-delimiter-color;
  width: $cap-sidebar-menu-initial-width;
  transition: 0.25s all;

  &--collapsed {
    width: $cap-sidebar-menu-collapsed-width;

    li {
      text-align: center;

      i {
        margin: 0;
      }
    }
  }

  &__brand {
    text-align: center;
    padding: 40px 0;

    .brand-wrapper {
      cursor: pointer;
      height: 55px;
      outline: none;

      img {
        @include setObjectFit(contain);
      }
    }
  }

  &__title {
    color: #333;
    font-size: toRem(20px);
    font-weight: bold;
    margin-top: 10px;
  }

  &__menus {
    padding: 0;
    list-style: none;

    li {
      padding: 18px 18px 18px 22px;
      color: rgba(51, 51, 51, 0.8);
      font-size: toRem(20px);
      font-weight: 600;
      cursor: pointer;
      outline: none;
      border-left: 2px solid transparent;

      i {
        margin-right: 10px;
        width: 30px;
        text-align: center;
        color: var(--cap-primary-color);
        opacity: 0.5;
      }

      &:hover,
      &.active {
        background-color: $background-hover-color;
        border-color: var(--cap-primary-color);
        color: var(--cap-primary-color);

        i {
          color: var(--cap-primary-color);
          opacity: 1;
        }
      }

      &.active {
        cursor: default;
      }
    }
  }

  &__button {
    visibility: hidden;
    width: $cap-sidebar-menu-button-proportions;
    height: $cap-sidebar-menu-button-proportions;
    background-color: var(--cap-primary-color);
    color: $cap-white;
    @include flexCentering;
    @include setAbsoluteVerticalCenter;
    right: -(math.div($cap-sidebar-menu-button-proportions, 2));
    border-radius: 50%;
    cursor: pointer;
    transition: 0.25s all;

    i {
      color: rgba($cap-white, 0.8);
    }

    &:hover {
      i {
        color: $cap-white;
      }
    }
  }

  &:hover {
    .cap-sidebar__button {
      visibility: visible;
    }
  }

  @include media-breakpoint-up(sm) {
    &--dark {
      color: $cap-white;
      background-color: var(--cap-primary-color);

      .cap-sidebar__brand {
        text-align: left;
        padding: 40px 25px;

        .brand-wrapper {
          height: 35px;

          img {
            object-position: left;
          }
        }
      }

      .cap-sidebar__title {
        color: $cap-white;
      }
      
      .cap-sidebar__button {
        filter: brightness(0.95);
        width: 25px;
      }

      .cap-sidebar__menus {
        li {
          color: $cap-white;
    
          i {
            color: $cap-white;
            opacity: 1;
          }
    
          &:hover,
          &.active {
            cursor: pointer;
            background-color: $background-hover-color;
            border-color: $cap-white;
            color: $cap-white;
            border-width: 4px;
    
            i {
              color: $cap-white;
            }
          }
        }
      }
    }

    &--dark.cap-sidebar--collapsed {
      .cap-sidebar__button {
        background-color: $cap-light-gray;
        border-radius: 0 4px 4px 0;
        margin-right: -9px;

        i {
          color: var(--cap-primary-color);
        }
      }
    }

    &--dark:not(.cap-sidebar--collapsed) {
      .cap-sidebar__button {
        border-radius: 4px 0 0 4px;
        margin-right: 17px;
      }
    }
  }
}
