@mixin applyToastStyles($class, $color, $background: $toast-background-color) {
  &.p-toast-message- { /* stylelint-disable */
    &#{$class} {
      border-left: 4px solid $color;
      background: $background;

      .toast-icon {
        color: $color;
      }
    }
  }
}

body .p-toast.cap-toast .p-toast-message {
  background: $toast-background-color;
  border-radius: $toast-border-radius;
  color: $cap-toast-text-color;
  font-family: $cap-primary-font;

  .p-toast-message-content {
    padding: $cap-toast-message-content-padding;
  }

  .p-toast-icon-close {
    outline: none;
    color: $cap-toast-close-icon-color;
    top: $cap-toast-close-icon-top;
    bottom: $cap-toast-close-icon-bottom;
    right: $cap-toast-close-icon-right;
    justify-content: $cap-toast-close-icon-justify-content;
    display: $cap-toast-close-icon-display;
    flex-direction: $cap-toast-close-icon-flex-direction;
    font-size: toRem($cap-toast-close-icon-flex-font-size);
    position: absolute;

    .p-toast-icon-close-icon {
      display: none;
    }

    &:before {
      font-family: 'Font Awesome 5 Pro';
      content: '\f00d';
    }
  }

  @include applyToastStyles('success', $cap-success, $cap-toast-background-color-success);
  @include applyToastStyles('error', $cap-danger, $cap-toast-background-color-error);
  @include applyToastStyles('warn', $cap-warning, $cap-toast-background-color-warning);
  @include applyToastStyles('info', var(--cap-primary-color), $cap-toast-background-color-info);

  .toast-icon {
    font-size: toRem($cap-toast-icon-font-size);
    margin-right: $cap-toast-icon-margin-right;
  }

  .toast-summary {
    font-size: toRem($cap-toast-summary-font-size);
    font-weight: $cap-toast-summary-font-weight;
    font-family: $cap-toast-summary-font-family;
    color: $cap-toast-summary-color;
  }

  .toast-detail {
    color: $cap-toast-detail-text-color;
    font-family: $cap-primary-font;
    font-size: toRem($cap-toast-detail-font-size);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  &.p-toast-message-error {
    background: $cap-toast-message-error-background;
    border-left: $cap-toast-message-error-border-left;
  }

  &.p-toast-message-info {
    background: $cap-toast-background-color-error;
    border-left: $cap-toast-message-info-border-left;
  }
}
