.cap-loader-image {
  width: $cap-loader-image-width;
  margin: $cap-loader-image-margin;
}

.cap-loader {
  position: $cap-loader-position;
  top: $cap-loader-top;
  left: $cap-loader-left;
  bottom: $cap-loader-bottom;
  width: $cap-loader-width;
  background: $cap-loader-background;
  z-index: $cap-loader-z-index;
  text-align: $cap-loader-text-align;
  display: $cap-loader-display;
  flex-direction: $cap-loader-flex-direction;
  justify-content: $cap-loader-justify-content;

  .p-progress-spinner-circle {
    animation: p-progress-spinner-dash 1.5s ease-in-out infinite, cap-progress-spinner-color 6s ease-in-out infinite; /* stylelint-disable */
  }

  span.cap-loader-text {
    color: $cap-loader-text-color;
  }
}

@keyframes cap-progress-spinner-color {
  100%,
  0%,
  40%,
  66%,
  80%,
  90% {
    stroke: $cap-loader-progress-spinner-color-stroke;
  }
}

.cap-loader-text:after {
  content: ' .';
  animation: $cap-loader-text-animation;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: var(--cap-primary-color);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow: 0.25em 0 0 var(--cap-primary-color), 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow: 0.25em 0 0 var(--cap-primary-color), 0.5em 0 0 var(--cap-primary-color);
  }
}
