@import '../abstracts/mixins';

.data-cell {
  vertical-align: middle;
  padding-left: $cap-info-table-cell-padding;
  padding-right: $cap-info-table-cell-padding;
  border: $cap-info-table-cell-border;
  background-color: $cap-info-table-cell-bg;
}

tr.cap-info-table__row.cdk-drag-preview {
  display: none;
}

body cap-info-table p-table .cap-info-table tbody {
  cap-info-table-row tr.cdk-drag-placeholder {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;

    td:not(:first-child),
    td:not(:last-child) {
      border-right: none !important;
      border-left: none !important;
    }

    td:first-child {
      border-right: none !important;
      border-left: 1px solid $cap-gray !important;
    }

    td:last-child {
      border-left: none !important;
      border-right: 1px solid $cap-gray !important;
    }

    td {
      border: 1px solid $cap-gray !important;
      border-radius: 0 !important;
    }
  }

  cap-info-table-row.totals-row {
    display: contents;
    font-weight: bold;
    font-size: 1.2rem;
  }
}

.cap-info-table {
  &__column-header {
    max-width: 100%;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .th_column-header--editable-rows {
    width: 3.5rem;
  }

  &.p-datatable {
    table {
      border-collapse: separate;
      border-spacing: $cap-info-table-border-spacing;
    }

    .p-checkbox .p-checkbox-box.p-highlight,
    .p-checkbox .p-checkbox-box.p-highlight.p-focus,
    .p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover,
    .p-checkbox .p-checkbox-box.p-highlight.p-focus:not(.p-disabled):hover {
      border-color: $cap-success;

      .p-checkbox-icon:before {
        color: $cap-success;
      }
    }

    .p-paginator {
      margin-top: $cap-table-paginator-margin-top;
      background-color: $cap-table-paginator-background-color;
      border: $cap-table-paginator-border;
      text-align: $cap-table-paginator-text-align;

      cap-validator {
        display: none;
      }

      .p-paginator-left-content {
        float: left;
        margin-right: auto;
      }

      .paginator-left {
        &__page-text {
          margin-left: 1rem;
          margin-right: 1rem;
        }

        &__total-text {
          margin-left: 1.5rem;
        }
      }

      .form-element {
        display: inline;
      }

      p-dropdown {
        display: $cap-table-dropdown-display;
      }

      .p-paginator-bottom {
        text-align: $cap-table-paginator-bottom-text-align;
      }

      .p-paginator-page {
        color: $cap-table-paginator-page-color;
        font-weight: $cap-table-paginator-font-weight;

        &:focus {
          outline: none;
          box-shadow: none;
        }

        &.p-highlight {
          background: var(--cap-primary-color);
          border: 0;
          color: #fff;
        }
      }

      .p-paginator-element {
        background: $cap-table-paginator-element-background;
        color: $cap-table-paginator-element-color;
        border: 1px solid $table-border-color;
        border-left: none;
        font-weight: normal;
      }

      .p-paginator-prev,
      .p-paginator-next,
      .p-paginator-last,
      .p-paginator-first {
        width: $cap-table-paginator-prev-next-width;
        line-height: $cap-table-paginator-prev-next-line-height;
        border: 1px solid $table-border-color;
        cursor: pointer;

        &:focus {
          outline: none;
          box-shadow: none;
        }

        &.p-disabled {
          color: $cap-table-text-color;
        }

        .pi,
        anglerighticon,
        anglelefticon,
        angledoublerighticon,
        angledoublelefticon {
          display: none;
        }
      }

      .p-paginator-prev {
        margin-right: $cap-table-paginator-prev-margin-right;
        border-top-left-radius: $cap-table-paginator-prev-border-tl-radius;
        border-bottom-left-radius: $cap-table-paginator-prev-border-left-radius;
      }

      .p-paginator-next {
        margin-left: $cap-table-paginator-next-margin-left;
        border-top-right-radius: $cap-table-paginator-next-border-tr-radius;
        border-bottom-right-radius: $cap-table-paginator-next-border-br-radius;
      }

      .p-paginator-first {
        margin-right: $cap-table-paginator-first-margin-right;
        border-top-left-radius: $cap-table-paginator-first-border-tl-radius;
        border-bottom-left-radius: $cap-table-paginator-first-border-bl-radius;
      }

      .p-paginator-last {
        margin-left: $cap-table-paginator-last-margin-left;
        border-top-right-radius: $cap-table-paginator-last-border-tr-radius;
        border-bottom-right-radius: $cap-table-paginator-last-border-br-radius;
      }

      .p-paginator-prev:before {
        @include setFontAwesomeProIcon('\f053');
      }

      .p-paginator-first:before {
        @include setFontAwesomeProIcon('\f323');
      }

      .p-paginator-next:before {
        @include setFontAwesomeProIcon('\f054');
      }

      .p-paginator-last:before {
        @include setFontAwesomeProIcon('\f324');
      }
    }

    .p-datatable-thead {
      position: relative;

      @media screen and (max-width: $cap-info-table-card-breakpoint) {
        display: none;
      }

      th {
        text-align: left;
        height: $cap-info-table-header-height;
        background-color: $cap-info-table-header-bg;
        border-bottom: 1px solid $cap-info-table-delimiter;
        white-space: nowrap;
        @extend .data-cell;

        &.p-frozen-column {
          position: sticky;
        }

        &.selectable-table-checkbox-column {
          width: 3rem;
        }

        &.reorderable-rows-header {
          width: 3rem;
        }

        span {
          background-color: $cap-info-table-header-bg;
          color: $cap-info-table-header-color;
          font-weight: bold;
          font-size: toRem(14px);
        }
      }
    }

    .p-datatable-tbody {
      tr {
        background-color: $cap-info-table-row-bg;

        @media screen and (max-width: $cap-info-table-card-breakpoint) {
          &.card-view {
            display: flex;
            flex-direction: column;
            margin-bottom: $cap-info-table-card-gutter;
            box-shadow: $cap-info-table-card-shadow;
          }
        }

        &.cap-table-row-selected {
          & > td:first-child {
            border-left: 1px solid $cap-success;
          }

          & > td:last-child {
            border-right: 1px solid $cap-success;
          }

          & > td {
            border-top: 1px solid $cap-success;
            border-bottom: 1px solid $cap-success;
          }
        }
      }

      td:not(.selectable-column):not(.reorderable-row) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      td {
        height: $cap-info-table-cell-height;
        text-align: left;
        @extend .data-cell;

        &.cap-info-table__column--clickable {
          cursor: pointer;
        }

        &.cap-info-table__column {
          .cap-info-table__mobile-column {
            display: none;

            @media screen and (max-width: $cap-info-table-card-breakpoint) {
              display: flex;
              font-size: toRem(14px);
              height: 100%;
              align-items: center;
            }
          }

          .cap-info-table__desktop-column {
            display: block;

            @media screen and (max-width: $cap-info-table-card-breakpoint) {
              display: none;
            }
          }

          .cap-info-table__mobile-header {
            text-align: left;
            font-weight: bold;
            color: $cap-info-table-header-color;
            margin-right: 10px;
          }
        }

        .cap-info-table__actions-column {
          &--flexed {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 100%;
            border-bottom: 1px solid $cap-info-table-delimiter;
          }

          &--single {
            justify-content: flex-end;
          }

          .cap-info-table__column-mob-actions {
            display: none;

            @media screen and (max-width: $cap-info-table-card-breakpoint) {
              display: table-cell;
            }
          }
        }

        &.cap-info-table__column-actions {
          display: table-cell;

          &.cards-view {
            @media screen and (max-width: $cap-info-table-card-breakpoint) {
              display: none;
            }
          }
        }

        &:first-child {
          border-bottom-left-radius: $cap-info-table-border-radius;
          border-top-left-radius: $cap-info-table-border-radius;

          @media screen and (max-width: $cap-info-table-card-breakpoint) {
            border-bottom-left-radius: 0;
            border-top-left-radius: $cap-info-table-border-radius;
            border-top-right-radius: $cap-info-table-border-radius;
          }
        }

        &:last-child {
          border-bottom-right-radius: $cap-info-table-border-radius;
          border-top-right-radius: $cap-info-table-border-radius;

          @media screen and (max-width: $cap-info-table-card-breakpoint) {
            border-top-right-radius: 0;
            border-bottom-right-radius: $cap-info-table-border-radius;
            border-bottom-left-radius: $cap-info-table-border-radius;
          }
        }

        &.p-frozen-column {
          position: sticky;
        }

        &.reorderable-row {
          text-align: center;
          cursor: move;

          span {
            font-size: toRem(16px);
            padding: 5px 10px;
          }
        }
      }
    }
  }

  &__skeleton {
    .cap-info-table__skeleton-header {
      border-bottom: 1px solid $cap-info-table-delimiter;
      margin-bottom: 15px;
    }
  }

  &__head--sortable {
    cursor: pointer;
  }

  &__visibility-toggler {
    color: var(--cap-primary-color, $primary);
    font-size: 1.1rem;
    position: absolute;
    top: 0.5rem;
    right: 0;

    &:hover {
      cursor: pointer;
      opacity: 0.75;
    }
  }

  &__visibility-panel.p-overlaypanel {
    background: rgb(255, 255, 255);
    box-shadow: 0 5px 13px 0 rgba($black, 0.15);
    border-radius: 5px;
    border: 0;

    .p-overlaypanel-content {
      padding: 0;
    }

    .column {
      padding: 0.75rem;

      &:not(:last-child) {
        border-bottom: 1px solid $border-color;
      }
    }
  }

  &--hidden {
    width: 0;
    height: 0;
    overflow: hidden;
    display: block;
  }

  &--auto-layout {
    table {
      table-layout: initial;
    }
  }

  &__card-header {
    font-weight: 600;
  }

  &__column-selectable {
    display: flex;
  }

  &__row-select {
    margin-right: 15px;
  }

  .editable-rows-buttons {
    .editable-rows-button {
      display: flex;
      padding: 0;
      align-items: center;
      justify-content: center;
      line-height: 1;
      font-size: 1rem;
      cursor: pointer;

      &:last-of-type {
        margin-top: 0.5rem;
      }

      &:first-of-type {
        margin-top: 0;
      }

      &.edit {
        i {
          color: var(--cap-primary-color);
        }
      }

      &.save {
        i {
          color: $cap-success;
        }
      }

      &.cancel {
        i {
          color: $cap-danger;
        }
      }

      .label {
        padding-left: 0.5rem;
      }
    }
  }
}

.cap-cell-tooltip-container {
  display: flex;

  span {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
  }
}

body .cap-info-table-cell-tooltip {
  &.p-tooltip {
    overflow-wrap: break-word;
  }
}
