.slide-up-panel.animate {
  transition: $cap-slide-up-panel-transition;
}

.slide-up-panel {
  border-radius: $cap-slide-up-panel-border-radius;
  box-shadow: $cap-slide-up-panel-box-shadow;
  position: $cap-slide-up-panel-position;
  right: $cap-slide-up-panel-right;
  bottom: $cap-slide-up-panel-bottom;
  left: $cap-slide-up-panel-left;
  z-index: $cap-slide-up-panel-z-index;
  background-color: $cap-slide-up-panel-background-color;

  .panel-content {
    @include no-scrollbar;
    overflow: $cap-slide-up-panel-overflow;
  }

  .drag-handle-bar {
    cursor: $cap-slide-up-panel-drag-handle-bar-cursor;
  }

  .drag-handle-bar:active {
    cursor: $cap-slide-up-panel-drag-handle-bar-active-cursor;
  }

  .handle-bar {
    height: $cap-slide-up-panel-handle-bar-height;
    width: $cap-slide-up-panel-handle-bar-width;
    border-radius: $cap-slide-up-panel-handle-bar-border-radius;
    background-color: $cap-slide-up-panel-handle-bar-background-color;
  }
}
