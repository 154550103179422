$padding: 15px 17px;

@mixin commonListStyle() {
  cursor: pointer;
  padding: $padding;
  font-size: toRem(16px);
  min-height: 16px;
  font-weight: 600;
  border-bottom: 1px solid $cap-gray;
}

.cap-user-header {
  display: flex;
  align-items: center;
}

.user-menu {
  min-width: 275px;

  &__items {
    li {
      @include commonListStyle();

      span {
        margin-left: 15px;
      }

      i {
        color: var(--cap-primary-color);
      }

      &:last-of-type {
        border-bottom: none;
      }

      &:hover {
        background-color: $cap-user-menu-hover-color;
        color: var(--cap-primary-color);
      }
    }
  }
}

.user-info {
  display: inline-block;
  text-align: right;
  margin-right: 10px;

  .user-name {
    font-weight: bold;
    color: var(--cap-primary-color);
  }

  .user-email {
    margin-top: 3px;
    color: $cap-user-menu-subtext-color;
    font-size: toRem(10px);
  }
}

.menu-cta {
  width: $cap-user-menu-proportions;
  height: $cap-user-menu-proportions;
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid var(--cap-primary-color);
  box-shadow: 0 2px 4px 0 rgba($cap-black, 0.25);
  @include flexCentering();

  i {
    margin: 0;
    color: var(--cap-primary-color);
  }
}
