cap-card {
  .card {
    border-radius: $cap-card-border-radius;
    box-shadow: $cap-card-box-shadow;
    border: $cap-card-border;
    background-color: $cap-card-background-color;
    padding: $cap-card-padding;

    .card__header {
      border-bottom: $cap-card-header-border-bottom;
      padding-bottom: $cap-card-header-padding-bottom;

      &.header--empty {
        display: none;
      }

      .header__title {
        margin: auto;

        h2 {
          font-size: toRem($cap-card-header-font-size);
          font-weight: $cap-card-header-font-weight;
          margin-bottom: 0;
        }
      }

      .header__cta {
        text-align: $cap-card-header-title-rs-text-align;

        i.settings {
          margin-left: $cap-card-header-settings-margin-left;
          color: $cap-card-header-settings-color;
          width: $cap-card-header-settings-width;
          height: $cap-card-header-settings-height;
          line-height: $cap-card-header-settings-line-height;
          text-align: $cap-card-header-settings-text-align;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .card__content {
      &.content--gutter {
        margin-top: $cap-card-content-gutter-margin-top;
      }
    }
  }
}
