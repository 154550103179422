.menu-action {
  position: relative;
  text-align: right;
  color: var(--cap-primary-color);
  display: inline-block;

  &__button-icon {
    background-color: var(--cap-primary-color);
    display: flex !important; /* stylelint-disable-line declaration-no-important */
    color: $white;
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgba(0, 170, 226, 0.25);
    border: solid 1px var(--cap-primary-color);
    text-align: center;
    cursor: pointer;
    @include proportions(40px);
    @include flexCentering();
  }

  &__items {
    padding: 0;
    list-style: none;
    box-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.15);
    background-color: $white;
    position: absolute;
    text-align: left;
    margin: 15px 0 0;
    z-index: 1;
    border-radius: 3px;
    visibility: hidden;
    height: 0;
    overflow: hidden;
    cursor: pointer;

    @supports not (width: max-content) {
      width: 270px;
    }

    @supports (width: max-content) {
      width: max-content;
    }

    &:before {
      content: '';
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 15px solid $white;
      position: absolute;
      right: 10px;
      top: -10px;
      z-index: -1;
    }

    &--open {
      visibility: visible;
      height: auto;
      overflow: visible;
    }

    &.left-bottom {
      right: 0;

      &:before {
        right: 10px;
      }
    }

    &.right-bottom {
      right: auto;
      left: 0;

      &:before {
        right: auto;
        left: 10px;
      }
    }

    &.left-top {
      bottom: 60px;
      right: 0;

      &:before {
        bottom: -10px;
        left: auto;
        right: 10px;
        top: auto;
        border-bottom: none;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 15px solid $cap-white;
      }
    }

    &.right-top {
      bottom: 60px;
      left: 0;

      &:before {
        bottom: -10px;
        left: 10px;
        top: auto;
        border-bottom: none;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 15px solid $cap-white;
      }
    }

    li {
      display: flex;
      cursor: pointer;
      padding: 14px 16px;
      border-bottom: 1px solid $cap-gray;

      &.disabled {
        cursor: not-allowed;

        p.title,
        i {
          color: $cap-action-menu-disabled-text-color;
        }
      }

      &:hover {
        background-color: $cap-platinum;
      }

      &:last-child {
        border: none;
      }
    }
  }

  &__icon {
    font-size: toRem(16px);
    margin: auto 16px auto 0;
    width: 20px;

    i {
      margin: 0;
    }
  }

  &__info {
    margin: auto 0;

    p {
      margin: 0;
    }

    .title {
      color: $primary;
      font-size: toRem(16px);
      font-weight: bold;
    }
  }
}
