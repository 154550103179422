@import '../abstracts/_mixins.scss';

body .p-paginator {
  display: block;
  border: none;
  float: right;
  background-color: inherit;
  text-align: right;
  width: 100%;
  padding: 15px 0;
  position: relative;

  @include media-breakpoint-down(xs) {
    text-align: center;
  }

  .p-paginator-element {
    padding: 0 7px;
    align-items: center;
    font-size: $cap-table-paginator-prev-next-font-size;
    line-height: $cap-table-paginator-prev-next-line-height;
    font-weight: normal;

    &:hover {
      text-decoration: none;
      cursor: pointer;
    }
  }

  .p-paginator-next,
  .p-paginator-prev,
  .p-paginator-first,
  .p-paginator-last {
    background: $cap-white;
    border: 1px solid $cap-gray;

    &:not(.p-disabled) {
      color: var(--cap-primary-color);
    }

    anglerighticon,
    anglelefticon,
    angledoublerighticon,
    angledoublelefticon {
      display: none;
    }
  }

  .p-paginator-next {
    border-left: none;

    .p-paginator-icon:before {
      @include setFontAwesomeProIcon('\f054');
    }
  }

  .p-paginator-prev {
    border-right: none;

    .p-paginator-icon:before {
      @include setFontAwesomeProIcon('\f053');
    }
  }

  .p-paginator-first {
    border-right: none;

    .p-paginator-icon:before {
      @include setFontAwesomeProIcon('\f323');
    }
  }

  .p-paginator-last {
    border-left: none;

    .p-paginator-icon:before {
      @include setFontAwesomeProIcon('\f324');
    }
  }

  .p-paginator-pages {
    .p-paginator-element {
      color: var(--cap-primary-color);
      border: 1px solid $cap-gray;
      border-right: none;
      background-color: $cap-white;

      &:last-child {
        border-right: 1px solid $cap-gray;
      }

      &.p-state-active {
        background: var(--cap-primary-color);
        color: $cap-white;
      }
    }
  }
}

body .p-disabled,
body .p-component:disabled {
  box-shadow: none;
}
