.cap-progress-circle {
  position: $cap-progress-circle-position;
  display: $cap-progress-circle-display;

  .cap-pc-circle-custom {
    text-align: $cap-progress-circle-custom;
    position: $cap-progress-circle-position;

    &.cap-pc-content {
      top: $cap-progress-circle-content-top;
    }

    &.cap-pc-subtitle {
      top: $cap-progress-circle-subtitle-top;
    }
  }
}
