@mixin dynamicFilterFontStyles {
  font-size: $cap-dynamic-filter-label-font-size;
  font-weight: $cap-dynamic-filter-label-font-weight;
  font-family: $cap-dynamic-filter-label-font-family;
  color: $cap-dynamic-filter-label-font-color;
}

.table-settings-button {
  button {
    height: $cap-dynamic-filter-height;
  }

  @include cap-media-breakpoint(handset) {
    .p-button.cap-button {
      padding: 0.2rem 5px;
    }
  }
}

.cap-dynamic-filter-layout {
  cap-validator {
    display: none;
  }
}

cap-sidebar p-sidebar .p-sidebar.cap-dynamic-filters-sidebar {
  width: 480px;
}

@include cap-media-breakpoint(handset) {
  cap-sidebar p-sidebar .p-sidebar.cap-dynamic-filters-sidebar {
    width: 100%;
  }
}

.cap-dynamic-checkbox-filter {
  .cap-dynamic-filter-layout-content {
    height: $cap-checkbox-filter-height;
    border: $cap-checkbox-filter-border;
    border-radius: $cap-dynamic-filter-border-radius;
    border-width: $cap-dynamic-filter-border-width;
    justify-content: $cap-checkbox-filter-justify-content;
    background-color: $cap-checkbox-filter-background-color;
    width: 100%;
    min-width: 100%;
    display: flex;

    .filter-icon {
      left: $cap-checkbox-filter-icon-left-padding;
    }

    .form-element {
      display: inline;
    }

    cap-checkbox {
      align-self: center;
      margin-right: 10px;
    }

    .cap-checkbox-filter-label {
      padding-left: $cap-dynamic-filter-label-padding-left;
      align-self: center;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @include dynamicFilterFontStyles();
    }

    cap-validator {
      display: none;
    }
  }
}

.cap-dynamic-input-filter {
  .p-inputgroup {
    input.p-inputtext,
    input.p-inputtext:hover,
    input.p-inputtext:enabled:focus:not(.p-state-error),
    input.p-inputtext:enabled:hover:not(.p-state-error) {
      padding-right: 40px;
      padding-left: $cap-dynamic-filter-label-padding-left;
      border-radius: $cap-dynamic-filter-border-radius;
      border-width: $cap-dynamic-filter-border-width;
      height: $cap-dynamic-filter-height;
      @include dynamicFilterFontStyles();

      &::placeholder {
        color: $cap-dynamic-filter-placeholder-font-color;
        font-weight: $cap-dynamic-filter-placeholder-font-weight;
      }

      &:placeholder-shown {
        padding-right: 0;
      }
    }
  }
}

.dynamic-dropdown-filter {
  cap-dropdown {
    p-dropdown,
    p-dropdown:hover:focus,
    p-dropdown:hover,
    p-dropdown.p-inputwrapper-focus {
      .cap-dropdown.p-dropdown,
      .cap-dropdown.p-dropdown.p-dropdown-open,
      .cap-dropdown.p-dropdown.p-focus,
      .cap-dropdown.p-dropdown.p-dropdown-open:hover,
      .cap-dropdown.p-dropdown.p-focus:hover,
      .cap-dropdown.p-dropdown:not(.p-disabled),
      .cap-dropdown.p-dropdown:not(.p-disabled):hover,
      .cap-dropdown.p-dropdown:hover {
        height: $cap-dynamic-filter-height;
        border-radius: $cap-dynamic-filter-border-radius;
        border-width: $cap-dynamic-filter-border-width;
        font-size: $cap-dynamic-filter-label-font-size;

        .p-dropdown-trigger {
          border-top-right-radius: $cap-dynamic-filter-border-radius;
          border-bottom-right-radius: $cap-dynamic-filter-border-radius;
          border-left: none;
          padding-right: 13px;

          .pi {
            font-size: $cap-dynamic-filter-label-font-size;
          }
        }

        .p-dropdown-label {
          padding-left: $cap-dynamic-filter-label-padding-left;
          padding-right: 1.2em;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow-x: hidden;
          display: block;
          height: 100%;
          border-radius: $cap-dynamic-filter-border-radius;
          // Center text
          padding-top: calc(($cap-dynamic-filter-height - $cap-dynamic-filter-label-font-size) / 2 - var(--cap-dynamic-filter-border-width));
          padding-bottom: calc(($cap-dynamic-filter-height - $cap-dynamic-filter-label-font-size) / 2 - var(--cap-dynamic-filter-border-width));
          @include dynamicFilterFontStyles();

          &.p-placeholder {
            padding-right: 0;
            color: $cap-dynamic-filter-placeholder-font-color;
            font-weight: $cap-dynamic-filter-placeholder-font-weight;
          }
        }
      }
    }
  }

  .cap-dynamic-filter-layout .delete-filter {
    right: 35px;
  }
}

.dynamic-multiselect-filter {
  cap-multi-select {
    .p-multiselect,
    .p-multiselect-label {
      font-size: $cap-dynamic-filter-label-font-size;
      border-radius: $cap-dynamic-filter-border-radius;
      border-width: $cap-dynamic-filter-border-width;
    }

    p-multiselect,
    p-multiselect:hover:focus,
    p-multiselect:hover,
    p-multiselect.p-inputwrapper-focus {
      .cap-multi-select.p-multiselect,
      .cap-multi-select.p-multiselect.p-multiselect-open,
      .cap-multi-select.p-multiselect.p-focus,
      .cap-multi-select.p-multiselect.p-multiselect-open:hover,
      .cap-multi-select.p-multiselect.p-focus:hover,
      .cap-multi-select.p-multiselect:not(.p-disabled),
      .cap-multi-select.p-multiselect:not(.p-disabled):hover,
      .cap-multi-select.p-multiselect:hover {
        font-size: $cap-dynamic-filter-label-font-size;
        height: $cap-dynamic-filter-height;
        border-radius: $cap-dynamic-filter-border-radius;
        border-width: $cap-dynamic-filter-border-width;

        .p-multiselect-label-container {
          height: 100%;
        }

        .p-multiselect-trigger {
          border-top-right-radius: $cap-dynamic-filter-border-radius;
          border-bottom-right-radius: $cap-dynamic-filter-border-radius;
          border-left: none;
          padding-right: 13px;
        }

        .p-multiselect-label {
          padding-left: $cap-dynamic-filter-label-padding-left;
          padding-right: 1.2em;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow-x: hidden;
          display: block;
          width: 100%;
          height: 100%;
          border-radius: $cap-dynamic-filter-border-radius;
          border-width: $cap-dynamic-filter-border-width;
          // Center text
          padding-top: calc(($cap-dynamic-filter-height - $cap-dynamic-filter-label-font-size) / 2 - var(--cap-dynamic-filter-border-width));
          padding-bottom: calc(($cap-dynamic-filter-height - $cap-dynamic-filter-label-font-size) / 2 - var(--cap-dynamic-filter-border-width));
          @include dynamicFilterFontStyles();

          &.p-placeholder {
            padding-right: 0;
            color: $cap-dynamic-filter-placeholder-font-color;
            font-weight: $cap-dynamic-filter-placeholder-font-weight;
          }
        }

        .pi {
          font-size: $cap-dynamic-filter-label-font-size;
        }
      }
    }
  }

  .cap-dynamic-filter-layout .delete-filter {
    right: 35px;
  }
}

.dynamic-calendar-filter {
  .p-calendar {
    input[type='text'].p-inputtext.p-component {
      padding-left: 34px;
      padding-right: 13px;
      left: 0;
      width: 100%;
      border-top-left-radius: $cap-dynamic-filter-border-radius;
      border-bottom-left-radius: $cap-dynamic-filter-border-radius;
      height: $cap-dynamic-filter-height;
      @include dynamicFilterFontStyles();

      &::placeholder {
        color: $cap-dynamic-filter-placeholder-font-color;
        font-weight: $cap-dynamic-filter-placeholder-font-weight;
      }
    }

    &.p-calendar-w-btn .p-datepicker-trigger.p-button {
      height: $cap-dynamic-filter-height;
      padding-right: 13px;
      width: 2.5rem;


      .p-button-icon {
        font-size: $cap-dynamic-filter-label-font-size;
      }
    }

    &.p-input-icon-right {
      .p-inputtext {
        border-color: $border-color;
        border-radius: $cap-dynamic-filter-border-radius;
      }

      .p-icon-wrapper.p-datepicker-icon {
        font-family: 'primeicons';
        font-size: 1rem;
        margin-top: 0;
        transform: translateY(-50%);
        right: 13px;

        &::before {
          content: '\e902';
          -moz-osx-font-smoothing: grayscale;
          -webkit-font-smoothing: antialiased;
          display: var(--fa-display, inline-block);
          font-style: normal;
          font-variant: normal;
          line-height: 1;
          text-rendering: auto;
        }

        .p-icon {
          display: none;
          width: 0;
          height: 0;
          visibility: hidden;
          opacity: 0;
        }
      }
    }
  }

  .cap-dynamic-filter-layout .delete-filter {
    right: 35px;
  }

  .p-calendar.p-calendar-w-btn .p-datepicker-trigger.p-button {
    background: $cap-white;
    color: var(--primary-text);

    border-top-right-radius: $cap-dynamic-filter-border-radius;
    border-bottom-right-radius: $cap-dynamic-filter-border-radius;
  }
}

cap-dynamic-filters {
  width: 100%;

  .cap-filters {
    display: flex;

    .sidebar-filter-button {
      display: none;
    }

    &.sidebar-filtering {
      .sidebar-filter-button:not(.hide-filter-button) {
        display: flex;
      }
    }

    .filter-list {
      display: flex;
      margin: -5px 0 0 0;
      flex-wrap: $cap-dynamic-filters-flex-wrap;
    }

    @if $cap-dynamic-filters-hide-filters-on-mobile {
      @media only screen and (max-width: 839.98px) {
        .filter-list {
          flex-grow: 1;

          .filter {
            max-width: unset;
          }
        }

        .filter-list:not(.sidebar-filter-list) > .filter:not(:first-child) {
          display: none;
        }

        .sidebar-filter-button {
          display: flex;
        }

        .filter-button {
          width: 10%;
        }
      }
    }

    .filter {
      margin-top: 5px;
      align-self: center;
      max-width: $cap-dynamic-filter-width;
      padding-left: 0;
      min-width: $cap-dynamic-filter-width;
      padding-right: 10px;
      position: relative;
      flex: $cap-dynamic-filters-filter-flex;

      .p-inputgroup input.p-inputtext {
        width: inherit;
      }

      @include cap-media-breakpoint(handset) {
        padding-right: 0;
      }

      &.sidebar-filter {
        margin-bottom: 10px;
        width: 100%;
        max-width: unset;
      }
    }

    .filter-button {
      display: flex;
      flex-wrap: nowrap;

      &.hide-filter-button {
        display: none;
      }

      & > cap-button:first-child {
        margin-right: 10px;
      }

      .p-button {
        height: $cap-dynamic-filter-height;
        border-radius: $cap-dynamic-filter-border-radius;
      }

      @include cap-media-breakpoint(handset) {
        &.hide-filter-button {
          display: flex;
        }

        .reset-filter-button, .refresh-filter-button {
          display: none;
        }

        & > cap-button:first-child {
          margin-right: 0;
          margin-left: 5px;
        }

        .p-button.cap-button {
          padding: 0.2rem 5px;
        }
      }
    }
  }

  .p-chips > ul.p-inputtext.p-chips-multiple-container {
    background: transparent;
    border: none;
    padding-left: 0;

    .p-chips-token {
      background: #f0f0f0;
      border-radius: 25px;
      color: #5f6062;
      border: 1px solid #ddd;
      padding: 2px 10px;
      margin: 0 8px 0 0;
    }

    .pi-times-circle {
      margin-left: 0.75rem;
      font-family: 'Font Awesome 5 Pro';
      font-weight: 400;
      font-size: 0.9rem;

      &:before {
        content: '\f00d';
      }
    }
  }

  .shown-filters {
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
  }
}

.filters.handset {
  cap-dynamic-filters {
    .filter:not(.search) {
      display: none;
    }
  }
}
